const initialState = {
	isLoading: false,
	listpackages: [],
	totalsize: 0
};



function packageReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_PACKAGE':
			return {
				...state,
				isLoading: action?.payload
			};
		case 'ADMIN_LIST_PACKAGE':
			return {
				...state,
				listpackages: action?.payload?.listpackages || [],
				totalsize: action?.payload?.totalsize || 0
			};
		default:
		  return state;
    }
}

export default packageReducer;
