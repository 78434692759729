import { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { getListPackages, deletePackage } from "actions/packageActions";
import { formatterCreateAt, FormatterStatus } from "components/Function";
import { ReactTable } from "components/Table";
import { ModalAddPackage, ModalEditPackage } from "./modal";

function Listpackages(props) {
    const [isOpenModalAddPackage, setIsOpenModalAddPackage] = useState(false);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [packageSelect, setPackageSelect] = useState(null);
    const { t } = useTranslation();
    var { listpackages, totalsize } = props.package;
    var { getListPackages, deletePackage, formatterCreateAt, FormatterStatus } = props;

    useEffect(() => {
        getListPackages(page, sizePerPage, searchText);
    }, [getListPackages, page, sizePerPage, searchText]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    const onClickOpenModalAddPackage = () => {
        setIsOpenModalAddPackage(!isOpenModalAddPackage);
    };

    const onClickDeletePackage = (row = [], formatExtraData) => {
        if (typeof formatExtraData !== "undefined") {
            row = {
                ...row,
                page: formatExtraData.page,
                sizePerPage: formatExtraData.sizePerPage,
                searchText: formatExtraData.searchText
            }
        }
        deletePackage(row);
    };

    const columns = [{
        dataField: '_id',
        text: "",
        sort: true,
        formatter: (cell, row, rowIndex) => rowIndex + 1
    }, {
        dataField: 'name',
        text: t("Name"),
        sort: true,
    }, {
        dataField: 'description',
        text: t("Description"),
        sort: true,
    }, {
        dataField: 'time',
        text: t("Time"),
        sort: true,
    }, {
        dataField: 'price',
        text: t("Price"),
        sort: true,
    }, {
        dataField: 'type',
        text: t("Type"),
        sort: true,
    }, {
        dataField: 'limit_shop',
        text: t("Limit Shop"),
        sort: true,
    }, {
        dataField: 'limit_member',
        text: t("Limit Member"),
        sort: true,
    }, {
        dataField: 'limit_staff',
        text: t("Limit Staff"),
        sort: true,
    }, {
        dataField: 'limit_manage_staff',
        text: t("Limit Manage Staff"),
        sort: true,
    }, {
        dataField: 'manage_product',
        text: t("Manage Product"),
        sort: true,
    }, {
        dataField: 'manage_fulfillment',
        text: t("Manage Filfillment"),
        sort: true,
    }, {
        dataField: 'manage_promotion',
        text: t("Manage Promotion"),
        sort: true,
    }, {
        dataField: 'manage_refund_return',
        text: t("Manage Refund Return"),
        sort: true,
    }, {
        dataField: 'manage_finance',
        text: t("Manage Finance"),
        sort: true,
    }, {
        dataField: 'status',
        text: t("Status"),
        sort: true,
        formatter: (cell, row) => <FormatterStatus status={cell} />,
    }, {
        dataField: 'created_at',
        text: t("Created At"),
        sort: true,
        formatter: (cell, row) => formatterCreateAt(cell)
    }, {
        dataField: 'updated_at',
        text: t("Update At"),
        sort: true,
        formatter: (cell, row) => formatterCreateAt(cell)
    }, {
        dataField: '-',
        text: t("Actions"),
        formatter: (cell, row, rowIndex, formatExtraData) =>
            <Fragment>
                <button onClick={() => { setPackageSelect(row) }} className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2" data-tooltip-id="my-tooltip" data-tooltip-content={t("Edit")}>
                    <i className="ki-outline ki-notepad-edit fs-2"></i>
                </button>
                <button onClick={() => { onClickDeletePackage(row, formatExtraData) }} className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm" data-tooltip-id="my-tooltip" data-tooltip-content={t("Delete")}>
                    <i className="ki-outline ki-trash fs-2"></i>
                </button>
            </Fragment>

    }];

    const groupButton = () => {
        return (
            <div className="mb-5">
                <button onClick={() => { onClickOpenModalAddPackage() }} type="button" className="btn btn-primary btn-sm mr-1">
                    <i className="ki-outline ki-plus fs-2"></i> {t("Add Package")}
                </button>
            </div>
        );
    }

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setSearchText(searchText);
        } else {
            setPage(page||1);
            setSizePerPage(sizePerPage);
        }
    }

    const sizePerPageList = [
        {
            text: '10', value: 10
        },
        {
            text: '50', value: 50
        },
        {
            text: '100', value: 100
        },
        {
            text: '150', value: 150
        },
        {
            text: '200', value: 200
        },
        {
            text: 'All', value: totalsize||50
        }
    ];

    return (
        <Fragment>
            <div className="card mb-5 mb-xl-10">
                <div className="card-body p-9">
                    <div className="al-variants-table">
                        <ReactTable columns={columns} data={listpackages} groupButton={groupButton} handleTableChange={handleTableChange} page={page} sizePerPage={sizePerPage} totalSize={totalSize} alClassName="table-layout-inherit" sizePerPageList={sizePerPageList} />
                    </div>
                    {isOpenModalAddPackage ? <ModalAddPackage openModal={onClickOpenModalAddPackage} isOpenModalAddPackage={isOpenModalAddPackage} /> : ''}
                    <ModalEditPackage
                        data={packageSelect}
                        onClose={() => setPackageSelect(null)}
                        onSubmit={() => {
                            getListPackages(page, sizePerPage, searchText);
                        }}
                    />
                </div>
            </div>
        </Fragment>
    );
}

Listpackages.propTypes = {
    getListPackages: PropTypes.func.isRequired,
    formatterCreateAt: PropTypes.func.isRequired,
    FormatterStatus: PropTypes.func.isRequired,
    deletePackage:PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    package: state.package
});
const mapDispatchToProps = {
    getListPackages,
    formatterCreateAt,
    FormatterStatus,
    deletePackage,
}

export default connect(mapStateToProps, mapDispatchToProps)(Listpackages);