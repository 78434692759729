import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { saveAddUser } from "actions/userActions";
import Loading from "components/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";

function Modaladduser(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    var { isLoading } = props.user;
    var { isOpenModalAddUser, openModal, saveAddUser } = props;
	const { t } = useTranslation();

    const onClickSaveAddUser = () => {
        saveAddUser({ email, password }, openModal);
    };

    const onChange = (e) => {
        if (e.target.name === "email") {
            setEmail(e.target.value);
        }
        if (e.target.name === "password") {
            setPassword(e.target.value);
        }
    }

    const onOpenModal = () => {
        openModal();
    };

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => { onOpenModal() }} isOpen={isOpenModalAddUser}>
                <Loading isLoading={isLoading} />
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Add User")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onOpenModal() }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Email")}</span>
                        </label>
                        <input type="text" className="form-control" value={email} placeholder={t("Email")} name="email" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Password")}</span>
                        </label>
                        <input type="password" className="form-control" value={password} placeholder={t("Password")} name="password" onChange={(e) => { onChange(e) }} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="reset" id="kt_modal_users_search_reset" data-bs-dismiss="modal" className="btn btn-active-light me-3" onClick={() => { onOpenModal() }} >{t("Cancel")}</button>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveAddUser() }}  >{t("Save")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modaladduser.propTypes = {
    saveAddUser: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    saveAddUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Modaladduser);