import { Fragment } from "react";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { Route, Routes } from "react-router-dom";
import "./assets/css/an.css";
import "./assets/css/style.bundle.css";
import "./assets/plugins/global/plugins.bundle.css";
import Admin from "./layout/admin/Main";
import Shop from "./layout/shop";
import ShopAuthorize from "./layout/shop/authorize";
import Home from "./layout/home";
import Notification from "./layout/notification";
import Promotions from "./layout/promotions/Main";
import PromotionsLogs from "./layout/promotionslogs/Main";
import Products from "./layout/products/Main";
import EditProduct from "./layout/products/edit";
import ProductList from "./layout/products/listproducts";
import EditProductTemplate from "./layout/productTemplate/edit";
import ProductTemplateList from "./layout/productTemplate/listproducts";
import AccountSetting from "./layout/account/Main";
import ProductTools from "./layout/products/tools";
import Orders from "./layout/orders/Main";
import UserStaff from "./layout/userStaff";
import Finance from "./layout/finance/Main";
import Statement from "./layout/finance/statements";
import Packages from "./layout/packages";
import ManageDesign from "./layout/design/managedesign";
import ProductTikTok from "./layout/products/productTiktok";
import SpyProductEsty from "./layout/spyproduct/esty";
import SpyProductTikTokv1 from "./layout/spyproduct/tiktokv1";
import SpyProductSuggests from "./layout/spyproduct/suggest";
import CategoryProduct from "./layout/categoryProduct/ListCategoryProduct";
import ProductFreeze from "./layout/products/productFreeze";
import ReturnOrders from "./layout/returnsRefundCancel/return";
import ProductSchedule from "./layout/products/productSchedule";
import { permissionComponent } from "./Function";
// import { useLocation } from "react-router-dom";

function Body(props) {
    // var { pathname } = useLocation();

    return (
        <Fragment>
            {/* <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className=" container-fluid  d-flex flex-stack">
                        <div
                            data-kt-swapper="true"
                            data-kt-swapper-mode="prepend"
                            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                            className="page-title d-flex align-items-center me-3 flex-wrap lh-1"
                        >
                            <h1 className="d-flex align-items-center text-gray-900 fw-bold my-1 fs-3">{pathname}</h1>
                        </div>
                    </div>
                </div>
                <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div id="kt_content_container" className="container-xxl">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/manager" element={<Admin />} />
                            <Route path="/shops" element={<Shop />} />
                        </Routes>
                    </div>
                </div>
            </div> */}
            <div className="post d-flex flex-column-fluid" id="kt_post">
                <div id="kt_content_container" className="container-xxl mw-unset">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/manager" element={<Admin />} />
                        <Route path="/shops" element={<Shop />} />
                        <Route path="/shops/authorize/:name" element={<ShopAuthorize />} />
                        <Route path="/notification" element={<Notification />} />
                        <Route path="/promotions" element={<Promotions />} />
                        <Route path="/promotions-logs" element={<PromotionsLogs />} />
                        <Route
                            path="/products"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "GET_PRODUCT",
                                component: <ProductList />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/products/create"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "CREATE_PRODUCT",
                                component: <Products />,
                                type: "component",
                            })}
                        />                        
                        <Route
                            path="/products/tools"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "CREATE_PRODUCT",
                                component: <ProductTools />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/categories"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "CREATE_PRODUCT",
                                component: <CategoryProduct />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/product-freeze"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "CREATE_PRODUCT",
                                component: <ProductFreeze />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/product-tiktoks"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "UPDATE_PRODUCT",
                                component: <ProductTikTok />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/products/edit/:id"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "UPDATE_PRODUCT",
                                component: <EditProduct />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/product-template"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "GET_PRODUCT_TEMPLATE",
                                component: <ProductTemplateList />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/product-template/edit/:id"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "UPDATE_PRODUCT_TEMPLATE",
                                component: <EditProductTemplate />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/product-schedule"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "GET_PRODUCT_TEMPLATE",
                                component: <ProductSchedule />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/spy/etsy"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "CREATE_PRODUCT",
                                component: <SpyProductEsty />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/spy/suggests"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "CREATE_PRODUCT",
                                component: <SpyProductSuggests />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/spy/tiktokv1"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "CREATE_PRODUCT",
                                component: <SpyProductTikTokv1 />,
                                type: "component",
                            })}
                        />
                        <Route path="/accounts" element={<AccountSetting />} />
                        <Route path="/orders" element={<Orders />} />
                        <Route
                            path="/users-staff"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "USER_REAL",
                                component: <UserStaff />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/finances"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "USER_REAL",
                                component: <Finance />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/finances/statements"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "USER_REAL",
                                component: <Statement />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/packages"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "USER_REAL",
                                component: <Packages />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/design/manage"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "USER_REAL",
                                component: <ManageDesign />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/design/buy"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "USER_REAL",
                                component: "Please waiting update",
                                type: "component",
                            })}
                        />
                         <Route
                            path="/returns"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "USER_REAL",
                                component: <ReturnOrders />,
                                type: "component",
                            })}
                        />
                    </Routes>
                </div>
            </div>
        </Fragment>
    );
}

// Body.propTypes = {
// };
const mapStateToProps = (state) => ({
    user: state.user,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Body);
