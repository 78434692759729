const initialState = {
	isLoading: false,
	list: [],
	totalsize: 0,
	statement_for_export: [],
	list_shop: [],
};

function statementReducer(state = initialState, action) {
	switch (action.type) {
		case 'LOADING_STATEMENT':
			return {
				...state,
				isLoading: action?.payload
			};
		case 'ADMIN_LIST_STATEMENT':
			return {
				...state,
				list: action?.payload?.statements || [],
				totalsize: action?.payload?.totalsize || 0,
				list_shop: action?.payload?.list_shop || [],
			};
		case 'EXPORT_STATEMENT':
			return {
				...state,
				statement_for_export: action.payload.statement_for_export
			};
		default:
			return state;
	}
}

export default statementReducer;
