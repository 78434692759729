import { Fragment, useState } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { editUser } from "actions/userActions";

function Security(props) {
    const { t } = useTranslation();
    const { editUser } = props;

    const [password, setPassword] = useState("");
    const [new_password, setNewPassword] = useState("");
    const [confirm_new_password, setConfirmNewPassword] = useState("");

    const onClickSaveEdit = () => {
        editUser({ type: "change_password", password, new_password, confirm_new_password })
    };

    const onChange = (e) => {
        if (e.target.name === "password") {
            setPassword(e.target.value);
        }
        if (e.target.name === "new_password") {
            setNewPassword(e.target.value);
        }
        if (e.target.name === "confirm_new_password") {
            setConfirmNewPassword(e.target.value);
        }
    }

    return (
        <Fragment>
            <div className="card mb-5 mb-xl-10">
                <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                    <div className="card-title m-0">
                        <h3 className="fw-bold m-0">{t("Change Password")}</h3>
                    </div>
                </div>
                <div id="kt_account_settings_profile_details" className="collapse show">
                    <div className="card-body border-top p-9">
                        <div className="row mb-6">
                            <label className="col-lg-2 col-form-label fw-semibold fs-6">{t("Current Password")}</label>
                            <div className="col-lg-10 fv-row">
                                <input type="password" name="password" className="form-control form-control-lg form-control-solid" placeholder="******" value={password} onChange={(e) => { onChange(e) }} />
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-2 col-form-label fw-semibold fs-6">{t("New Password")}</label>
                            <div className="col-lg-10 fv-row">
                                <input type="password" name="new_password" className="form-control form-control-lg form-control-solid" placeholder="******" value={new_password} onChange={(e) => { onChange(e) }} />
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-2 col-form-label fw-semibold fs-6">{t("Confirm New Password")}</label>
                            <div className="col-lg-10 fv-row">
                                <input type="password" name="confirm_new_password" className="form-control form-control-lg form-control-solid" placeholder="******" value={confirm_new_password} onChange={(e) => { onChange(e) }} />
                            </div>
                        </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button type="submit" className="btn btn-primary" onClick={() => { onClickSaveEdit() }}  >{t("Save Changes")}</button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

Security.propTypes = {
    editUser: PropTypes.func.isRequired,

};
const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    editUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(Security);