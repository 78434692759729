import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { registerUser } from "actions/userActions";
import { Link } from "react-router-dom";

function Register(props) {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [repeatpassword, setRepeatPassword] = useState("");
    const { t } = useTranslation();
    const { handleTab, registerUser } = props;

    const handleRegister = () => {
        registerUser(email, password, repeatpassword, name);
    }

    const onChange = (e) => {
        if (e.target.name === "email") {
            setEmail(e.target.value);
        }
        if (e.target.name === "name") {
            setName(e.target.value);
        }
        if (e.target.name === "password") {
            setPassword(e.target.value);
        }
        if (e.target.name === "repeatpassword") {
            setRepeatPassword(e.target.value);
        }
    }

    const onKeyPress = (e) => {
        if (e.charCode === 13) {
            registerUser(email, password, repeatpassword, name);
        }
    }

    return (
        <Fragment>
            <div className="form w-100">
                <div className="text-center mb-11">
                    <h1 className="text-gray-900 fw-bolder mb-3">{t("Sign Up")}</h1>
                </div>
                <div className="row g-3">
                    <div className="fv-row mb-8">
                        <input type="text" placeholder={t("Email")} name="email" autoComplete="off" className="form-control bg-transparent" value={email} onChange={(e) => { onChange(e) }} onKeyPress={(e) => { onKeyPress(e) }} />
                    </div>
                    <div className="fv-row mb-8">
                        <input type="text" placeholder={t("Name")} name="name" autoComplete="off" className="form-control bg-transparent" value={name} onChange={(e) => { onChange(e) }} onKeyPress={(e) => { onKeyPress(e) }} />
                    </div>
                    <div className="fv-row mb-8">
                        <input type="password" placeholder={t("Password")} name="password" autoComplete="off" className="form-control bg-transparent" value={password} onChange={(e) => { onChange(e) }} onKeyPress={(e) => { onKeyPress(e) }} />
                    </div>
                    <div className="fv-row mb-8">
                        <input type="password" placeholder={t("Confirm password")} name="repeatpassword" autoComplete="off" className="form-control bg-transparent" value={repeatpassword} onChange={(e) => { onChange(e) }} onKeyPress={(e) => { onKeyPress(e) }} />
                    </div>
                    <div className="fv-row mb-8">
                        <label className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" name="toc" value="1" />
                            <span className="form-check-label fw-semibold text-gray-700 fs-base ms-1">I Accept the
                                <Link to="#" className="ms-1 link-primary">Terms</Link></span>
                        </label>
                    </div>
                    <div className="d-grid mb-10">
                        <button type="submit" id="kt_sign_in_submit" className="btn btn-danger" onClick={() => { handleRegister() }}>
                            <span className="indicator-label">{t("Sign Up")}</span>
                        </button>
                        <div className="text-gray-500 text-center fw-semibold fs-6 mt-5">{t("Already Have An Account?")}
                            <Link to="#" className="link-danger" onClick={() => { handleTab("signin") }}> {t("Sign In")}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

Register.propTypes = {
    registerUser: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    registerUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
