import { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {getEmail, saveEmail } from "actions/emailActions";
import { isEmptyObject } from "components/Function";

function Email(props) {
    const { t } = useTranslation();
    const { isEmptyObject, saveEmail, getEmail } = props;
    var { email } = props.email;

    const [email_, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [host, setHost] = useState("");
    const [port, setPort] = useState("");

    useEffect(() => {
        getEmail();
    }, [getEmail]);

    useEffect(() => {
        if (!isEmptyObject(email)) {
            setEmail(email?.email || '');
            setPassword(email?.password || '');
            setHost(email?.host || '');
            setPort(email?.port || '');
        }
    }, [isEmptyObject, email]);

    const onClickSaveEditEmail = () => {
        saveEmail({ _id: email._id, email_, password, host, port });
    };

    const onChange = (e) => {
        if (e.target.name === "email") {
            setEmail(e.target.value);
        }
        if (e.target.name === "password") {
            setPassword(e.target.value);
        }
        if (e.target.name === "host") {
            setHost(e.target.value);
        }
        if (e.target.name === "port") {
            setPort(e.target.value);
        }
    }


    return (
        <Fragment>
            <div className="card mb-5 mb-xl-10">
                <div className="card-body p-9">
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Email")}</span>
                        </label>
                        <input type="text" className="form-control" value={email_} placeholder={t("Email")} name="email" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Password")}</span>
                        </label>
                        <input type="text" className="form-control" value={password} placeholder={t("Password")} name="password" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Host")}</span>
                        </label>
                        <input type="text" className="form-control" value={host} placeholder={t("Host")} name="host" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Port")}</span>
                        </label>
                        <input type="text" className="form-control" value={port} placeholder={t("Port")} name="port" onChange={(e) => { onChange(e) }} />
                    </div>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveEditEmail() }}  >{t("Save")}</button>
                </div>
            </div>
        </Fragment>
    );
}

Email.propTypes = {
    isEmptyObject: PropTypes.func.isRequired,
    saveEmail: PropTypes.func.isRequired,
    getEmail: PropTypes.func.isRequired,
    
};
const mapStateToProps = state => ({
    email: state.email
});
const mapDispatchToProps = {
    saveEmail,
    isEmptyObject,
    getEmail,
}

export default connect(mapStateToProps, mapDispatchToProps)(Email);