import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { forgotPassword } from "actions/userActions";

function Forgot(props) {
    const [email, setEmail] = useState("");
    const { handleTab, forgotPassword } = props;
    const { t } = useTranslation();

    const handleForgotPassword = () => {
        forgotPassword(email);
    }

    const onChange = (e) => {
        if (e.target.name === "email") {
            setEmail(e.target.value);
        }
    }

    const onKeyPress = (e) => {
        if (e.charCode === 13) {
            forgotPassword(email);
        }
    }

    return (
        <Fragment>
            <div className="form w-100">
                <div className="text-center mb-11">
                    <h1 className="text-gray-900 fw-bolder mb-3">{t("Forgot Password")} ?</h1>
                    <div className="text-gray-500 fw-semibold fs-6">{t("Enter your email to reset your password.")}</div>
                </div>
                <div className="row g-3">
                    <div className="fv-row mb-8">
                        <input type="text" placeholder={t("Email")} name="email" autoComplete="off" className="form-control bg-transparent" value={email} onChange={(e) => { onChange(e) }} onKeyPress={(e) => { onKeyPress(e) }} />
                    </div>
                    <div className="d-grid mb-10">
                        <button type="submit" id="kt_sign_in_submit" className="btn btn-danger" onClick={() => { handleForgotPassword() }}>
                            <span className="indicator-label">{t("Submit")}</span>
                            <span className="indicator-progress">{t("Please wait...")}
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                        </button>
                        <button type="submit" id="kt_sign_in_submit" className="btn btn-link" onClick={() => { handleTab("signin") }}>
                            <span className="indicator-label">{t("Cancel")}</span>
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

Forgot.propTypes = {
    forgotPassword: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    forgotPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
