import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import Login from './Login';
import Register from './Register';
import Forgot from './Forgot';
import Loading from "components/Loading";
import Swal from "sweetalert2";

function Main(props) {
    const [tab, setTab] = useState("signin");
    const { isLoading } = props.user;
    var { pathname } = useLocation();
    var [searchParams] = useSearchParams();
    const { t } = useTranslation();

    useEffect(() => {
        if (searchParams.get("error")) {
            Swal.fire("Lỗi", searchParams.get("error"), 'error');
        }
        if (searchParams.get("success")) {
            Swal.fire("Success", searchParams.get("success"), 'success');
        }
        if (pathname.indexOf("/reset-password") > -1 && searchParams.get("token")) {
            setTab("resetpassword");
        }
    }, [searchParams, pathname]);

    const onClick = (data) => {
        setTab(data)
    }

    return (
        <Fragment>
            <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
                    <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                        <div className="w-lg-500px p-10">
                            <Loading isLoading={isLoading} />
                            {
                                tab === "signin" ?
                                    <Login handleTab={onClick} />
                                : tab === "forgotpassword" ?
                                    <Forgot handleTab={onClick} />
                                : <Register handleTab={onClick} />
                            }
                        </div>
                    </div>
                    <div className="w-lg-500px d-flex flex-stack px-10 mx-auto">
                        <div className="me-10">
                            <button className="btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" data-kt-menu-offset="0px, 0px">
                                <img data-kt-element="current-lang-flag" className="w-20px h-20px rounded me-3" src={require("components/assets/media/flags/united-states.svg").default} alt="" />
                                <span data-kt-element="current-lang-name" className="me-1">English</span>
                                <span className="d-flex flex-center rotate-180">
                                    <i className="ki-outline ki-down fs-5 text-muted m-0"></i>
                                </span>
                            </button>
                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7" data-kt-menu="true" id="kt_auth_lang_menu">
                                <div className="menu-item px-3">
                                    <Link href="#" className="menu-link d-flex px-5" data-kt-lang="English">
                                        <span className="symbol symbol-20px me-4">
                                            <img data-kt-element="lang-flag" className="rounded-1" src={require("components/assets/media/flags/united-states.svg").default} alt="" />
                                        </span>
                                        <span data-kt-element="lang-name">English</span>
                                    </Link>
                                </div>
                                <div className="menu-item px-3">
                                    <Link href="#" className="menu-link d-flex px-5" data-kt-lang="Spanish">
                                        <span className="symbol symbol-20px me-4">
                                            <img data-kt-element="lang-flag" className="rounded-1" src={require("components/assets/media/flags/spain.svg").default} alt="" />
                                        </span>
                                        <span data-kt-element="lang-name">Spanish</span>
                                    </Link>
                                </div>
                                <div className="menu-item px-3">
                                    <Link href="#" className="menu-link d-flex px-5" data-kt-lang="German">
                                        <span className="symbol symbol-20px me-4">
                                            <img data-kt-element="lang-flag" className="rounded-1" src={require("components/assets/media/flags/germany.svg").default} alt="" />
                                        </span>
                                        <span data-kt-element="lang-name">German</span>
                                    </Link>
                                </div>
                                <div className="menu-item px-3">
                                    <Link href="#" className="menu-link d-flex px-5" data-kt-lang="Japanese">
                                        <span className="symbol symbol-20px me-4">
                                            <img data-kt-element="lang-flag" className="rounded-1" src={require("components/assets/media/flags/japan.svg").default} alt="" />
                                        </span>
                                        <span data-kt-element="lang-name">Japanese</span>
                                    </Link>
                                </div>
                                <div className="menu-item px-3">
                                    <Link to="#" className="menu-link d-flex px-5" data-kt-lang="French">
                                        <span className="symbol symbol-20px me-4">
                                            <img data-kt-element="lang-flag" className="rounded-1" src={require("components/assets/media/flags/france.svg").default} alt="" />
                                        </span>
                                        <span data-kt-element="lang-name">French</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex fw-semibold text-primary fs-base gap-5">
                            <Link to="pages/team.html" target="_blank">Terms</Link>
                            <Link to="pages/pricing/column.html" target="_blank">Plans</Link>
                            <Link to="pages/contact.html" target="_blank">Contact Us</Link>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 bg-content-cus">
                    <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
                        <Link to="/" className="mb-0 mb-lg-12">
                            <img alt="Logo" src={require("components/assets/media/custom/logo_text.png")} className="h-80px h-lg-200px" />
                        </Link>
                        {
                            tab === "signin" ?
                                <>
                                    <img className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20" src={require("components/assets/media/custom/no-login.svg").default} alt="" />
                                    <h1 className="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">{t("Experience the benefits that support your store now.")}</h1>                                        
                                </>
                                : tab === "forgotpassword" ?
                                    <>
                                        <img className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20" src={require("components/assets/media/custom/6430776.webp")} alt="" />
                                        <h1 className="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">{t("Don't worry. We will help you find your password. Quickly.")}</h1>
                                    </>
                                    : <>
                                        <img className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20" src={require("components/assets/media/custom/woman-sitting-at-desk.svg").default} alt="" />
                                        <h1 className="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">{t("Join and use utilities to help optimize your revenue and sales system.")}</h1>
                                    </>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
