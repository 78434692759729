import { Suspense, lazy } from "react";
import { Provider } from "react-redux";
import Maincontainer from "./components/Maincontainer";
import { sleep } from "components/Function";
import store from "./store";
import { loadUser } from "./actions/userActions";

store.dispatch(loadUser());

const MainthemeLoaded = lazy(async () => {
    await sleep(100);
    return { default: Maincontainer };
});

function App() {
    return (
        <Provider store={store}>
            <Suspense fallback={""}>
                <MainthemeLoaded />
            </Suspense>
        </Provider>
    );
}

export default App;
