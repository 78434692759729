const initialState = {
	isLoading: false,
	list: [],
	totalsize: 0,
	list_shop: [],
};

function reuturnOrderReducer(state = initialState, action) {
	switch (action.type) {
		case 'LOADING_RETURN_ORDER':
			return {
				...state,
				isLoading: action?.payload
			};
		case 'ADMIN_LIST_RETURN_ORDER':
			return {
				...state,
				list: action?.payload?.returnOrders || [],
				totalsize: action?.payload?.totalsize || 0,
				list_shop: action?.payload?.list_shop || [],
			};
		default:
			return state;
	}
}

export default reuturnOrderReducer;
