const initialState = {
	isLoading: false,
	app: checkObject(localStorage.getItem("app")),
	listapps: [],
	totalsize: 0
};

function checkObject(obj) {
    try {
        return JSON.parse(obj);
    } catch (e) {
        return {};
    }
}

function appReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_APP':
			return {
				...state,
				isLoading: action?.payload
			};
		case 'APP_LOADED':
			localStorage.setItem("app", JSON.stringify(action.payload.app));
			return {
				...state,
				app: action?.payload?.app
			};		
		case 'ADMIN_LIST_APP':
			return {
				...state,
				listapps: action?.payload?.listapps || [],
				totalsize: action?.payload?.totalsize || 0
			};
		default:
		  return state;
    }
}

export default appReducer;
