import { Fragment, useState } from "react";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Header";
import Navbar from "./Navbar";
import Body from "./Body";
import Footer from "./Footer";
import Main from "./layout/login/Main";
import Landing from "./layout/landing";
import { Tooltip } from "react-tooltip";
import { Helmet } from "react-helmet";

function Maincontainer(props) {
    var { isAuthenticated } = props.user;
    const [toggleSidebar, setToggleSidebar] = useState(false);

    const htmlAdmin = () => (
        <Fragment>
            <Helmet>
                <title>{window.location.pathname.indexOf("/app") < 0 ? "CoTik" : "COTIK"}</title>
            </Helmet>
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed aside-enabled aside-fixed" style={{"--kt-toolbar-height": "55px", "--kt-toolbar-height-tablet-and-mobile": "55px"}} data-kt-aside-minimize={ toggleSidebar ? "on" : "" }>
                {isAuthenticated ? (
                    <Fragment>
                        <div className="page d-flex flex-row flex-column-fluid vh-100">
                            <Navbar toggleSidebar={toggleSidebar} setToggleSidebar={setToggleSidebar}/>
                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                <Header toggleSidebar={toggleSidebar} setToggleSidebar={setToggleSidebar} />
                                <Body />
                                <Footer />
                            </div>
                        </div>
                    </Fragment>
                ) : (
                    <div className="d-flex flex-column flex-root vh-100">
                        <Main />
                    </div>
                )}
            </div>
            <Tooltip id="my-tooltip" />
        </Fragment>
    );

    return (
        <Router>
            <Routes>
                <Route path="/admin/*" element={htmlAdmin()} />
                <Route path="/*" element={<Landing />} />
            </Routes>
        </Router>
    );
}

// Maincontainer.propTypes = {
// };
const mapStateToProps = (state) => ({
    user: state.user,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Maincontainer);
