import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";


const getListPackages = (page = 0, sizeperpage = 0, search = "") => (dispatch, getState) => {
    dispatch({ type: 'LOADING_PACKAGE', payload: true });
    var param_search = "";
    if (search !== "") {
        param_search = `&search=${search}`;
    }
    axios.get(`/api/package/list?page=${page}&sizeperpage=${sizeperpage}${param_search}`, tokenConfig(getState)).then(res => {
        dispatch({ type: 'LOADING_PACKAGE', payload: false });

        if (res.data.status === 200) {
            dispatch({
                type: 'ADMIN_LIST_PACKAGE',
                payload: res.data.data
            });
        } else {
            Swal.fire(t("Error"), t(res.data.message), 'error');
        }
    }).catch(err => {
        dispatch({ type: 'LOADING_PACKAGE', payload: false });
        Swal.fire(t("Error"), t("An error occurred!"), 'error');
    });
};

const getListPackageActive = () => (dispatch, getState) => {
    dispatch({ type: 'LOADING_PACKAGE', payload: true });

    return axios.get(`/api/package/`, tokenConfig(getState)).then(res => {
        dispatch({ type: 'LOADING_PACKAGE', payload: false });

        if (res.data.status === 200) {
            return res.data.data;
        } else {
            Swal.fire(t("Error"), t(res.data.message), 'error');
            return {};
        }
    }).catch(err => {
        dispatch({ type: 'LOADING_PACKAGE', payload: false });
        Swal.fire(t("Error"), t("An error occurred!"), 'error');
        return {};
    });
};


const buyPackage = ({ packageId, checkNew }) => async (dispatch, getState) => {
    dispatch({ type: 'LOADING_PACKAGE', payload: true });
    let message = "You will buy the package?";
    if (checkNew) message = "You will purchasing this package cause data loss from the current package"

    const confirm = await Swal.fire({
        title: t("Are you sure?"),
        text: t(message),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    })

    if (!confirm?.value) return dispatch({ type: 'LOADING_PACKAGE', payload: false });;

    return axios.post(`/api/package/buy`, {packageId}, tokenConfig(getState)).then(res => {
        dispatch({ type: 'LOADING_PACKAGE', payload: false });

        if (res.data.status === 200) {
            Swal.fire(t("Success"), t(res.data.message), 'success');
            return res.data.data;
        } else {
            Swal.fire(t("Error"), t(res.data.message), 'error');
            return {};
        }
    }).catch(err => {
        dispatch({ type: 'LOADING_PACKAGE', payload: false });
        Swal.fire(t("Error"), t("An error occurred!"), 'error');
        return {};
    });
};

const addPackage = (data, closemodal) => (dispatch, getState) => {
    var body = {
        name: data.name,
        description: data.description,
        time: data.time,
        price: data.price,
        type: data.type,
        limit_shop: data.limit_shop,
        limit_member: data.limit_member,
        limit_staff: data.limit_staff,
        limit_manage_staff: data.limit_manage_staff,
        manage_product: data.manage_product,
        manage_fulfillment: data.manage_fulfillment,
        manage_promotion: data.manage_promotion,
        manage_refund_return: data.manage_refund_return,
        manage_finance: data.manage_finance,
    };

    Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to add package?"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel")
    }).then((result) => {
        if (result.value) {
            dispatch({ type: 'LOADING_PACKAGE', payload: true });
            axios.post('/api/package/add', body, tokenConfig(getState)).then(res => {
                dispatch({ type: 'LOADING_PACKAGE', payload: false });
                if (res.data.status === 200) {
                    closemodal();
                    dispatch(getListPackages(1, 10));
                    Swal.fire(t("Success"), t(res.data.message), 'success');
                } else {
                    Swal.fire(t("Error"), t(res.data.message), 'error');
                }
            }).catch(err => {
                dispatch({ type: 'LOADING_PACKAGE', payload: false });
                Swal.fire(t("Error"), t("An error occurred!"), 'error');
            });
        }
    })
};

const updatePackage =
    (data) =>
        (dispatch, getState) => {
            const body = {
                ...data,
            };

            Swal.fire({
                title: t("Are you sure?"),
                text: t("Are you sure to edit package?"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t("Sure"),
                cancelButtonText: t("Cancel")
            }).then((result) => {
                if (result.value) {
                    dispatch({ type: 'LOADING_PACKAGE', payload: true });
                    axios
                        .post("/api/package/update", body, tokenConfig(getState))
                        .then((res) => {
                            dispatch({ type: "LOADING_PACKAGE", payload: false });
                            if (res.data.status === 200) {
                                dispatch(getListPackages(1, 10));
                                Swal.fire("Success", res.data.message, "success");
                            } else {
                                Swal.fire("Error", res.data.message, "error");
                            }
                        })
                        .catch((err) => {
                            dispatch({ type: "LOADING_PACKAGE", payload: false });
                            Swal.fire("Error", "An error occurred!", "error");
                        });
                }
            })
        };

const deletePackage = (data) => (dispatch, getState) => {
    var body = {
        _id: data._id
    };
    Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to delete package?"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel")
    }).then((result) => {
        if (result.value) {
            dispatch({ type: 'LOADING_PACKAGE', payload: true });
            axios.post('/api/package/delete', body, tokenConfig(getState)).then(res => {
                dispatch({ type: 'LOADING_PACKAGE', payload: false });
                if (res.data.status === 200) {
                    dispatch(getListPackages(data.page, data.sizePerPage, data.searchText));
                    Swal.fire(t("Success"), t(res.data.message), 'success');
                } else {
                    Swal.fire(t("Error"), t(res.data.message), 'error');
                }
            }).catch(err => {
                dispatch({ type: 'LOADING_PACKAGE', payload: false });
                Swal.fire(t("Error"), t("An error occurred!"), 'error');
            });
        }
    })
};

export {
    getListPackages,
    addPackage,
    updatePackage,
    deletePackage,
    getListPackageActive,
    buyPackage,
};
