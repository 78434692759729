import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { getShops } from "actions/shopActions";
import { getStaffs } from "actions/teamActions";
import { formatterCreateAt } from "components/Function";
import { ReactTable } from "components/Table";
import Loading from "components/Loading";
import SelectBox from "components/elements/selectBox";

function ShopList(props) {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [toggleCard, setToggleCard] = useState(false);
    const [filter, setFilter] = useState({});
    const [staffs, setStaffs] = useState([]);
    const { t } = useTranslation();
    var { list, totalsize, isLoading } = props.shop;
    var { getShops, shops_selected, setShopsSelected, getStaffs } = props;

    useEffect(() => {
        getShops({ page, sizeperpage: sizePerPage, search: searchText, filter });
    }, [getShops, page, sizePerPage, searchText, filter]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    useEffect(() => {
        getStaffs({ sizeperpage: 100 }).then((data) => {
            setStaffs(data?.data?.map((staff) => ({ value: staff?.user?._id, label: staff.user.email })) || []);
        });
    }, [getStaffs]);

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "apiShopId",
            text: t("Shop ID"),
            sort: true,
        },
        {
            dataField: "name",
            text: t("Shop Name"),
            sort: true,
        },
        {
            dataField: "orders_count",
            text: t("Total Order"),
            sort: true,
        },
        {
            dataField: "products_count",
            text: t("Total Product"),
            sort: true,
        },
        {
            dataField: "note",
            text: t("Note"),
            sort: true,
        },
        {
            dataField: "region",
            text: t("Region"),
            sort: true,
        },
        {
            dataField: "code",
            text: t("Code"),
            sort: true,
        },
    ];

    const sizePerPageList = [
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalsize || 10,
        },
    ];

    const groupButton = () => {
        return (
            <>
                <div className="mb-5 d-flex justify-content-between">
                    <SelectBox
                        options={staffs}
                        name="filter_staff"
                        value={filter.staff || ""}
                        onChange={(data) => {
                            setFilter((prev) => ({
                                ...prev,
                                staff: data.value || "",
                            }));
                            setPage(1);
                        }}
                    />
                </div>
            </>
        );
    };

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };

    // const removeSelect = (arr, value) => {
    //     var i = 0;
    //     while (i < arr.length) {
    //         if (arr[i] === value) {
    //             arr.splice(i, 1);
    //         } else {
    //             ++i;
    //         }
    //     }
    //     return arr;
    // };
    
    const onSelect = (row, isSelect) => {
        if (isSelect) {
            setShopsSelected((prev) => [...prev, row._id]);
        } else {
            setShopsSelected((prev) => [...prev].filter((d) => d !== row._id));
        }
    };

    const onSelectAll = (checked, row) => {
        let selects = [];
        if (checked) selects = (row || []).map((s) => s._id);
        setShopsSelected(selects);
    };

    const selectRow = {
        mode: "checkbox",
        clickToSelect: false,
        selected: shops_selected,
        onSelect: onSelect,
        onSelectAll: onSelectAll,
    };

    return (
        <Fragment>
            <Loading isLoading={isLoading} />
            <div className="card mb-5 mb-xl-10">
                <div
                    className={cx("card-header collapsible cursor-pointer rotate", {
                        active: !toggleCard,
                        collapsed: toggleCard,
                    })}
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_docs_card_collapsible"
                    onClick={() => setToggleCard(!toggleCard)}
                >
                    <h3 className="card-title">{t("List Shops")}</h3>
                    <div className="card-toolbar rotate-180">
                        <i className="ki-duotone ki-down fs-1"></i>
                    </div>
                </div>
                <div id="kt_docs_card_collapsible" className={cx("collapse", { show: !toggleCard })}>
                    <div className="card-body p-9">
                        <div className="al-variants-table">
                            <ReactTable
                                columns={columns}
                                data={list}
                                groupButton={groupButton}
                                handleTableChange={handleTableChange}
                                page={page}
                                sizePerPage={sizePerPage}
                                totalSize={totalSize}
                                alClassName="table-layout-inherit"
                                isSelectRow={true}
                                selectRow={selectRow}
                                sizePerPageList={sizePerPageList}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

ShopList.propTypes = {
    getStaffs: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    shop: state.shop,
});
const mapDispatchToProps = {
    formatterCreateAt,
    getShops,
    getStaffs,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopList);
