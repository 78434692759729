const initialState = {
	isLoading: false,
	listpromotions: [],
	totalsize: 0,
	listpromotionproducttiktoks: [],
	totalsize_listpromotionproducttiktoks: 0,
	successfully: 0,
	failed: 0,
	percent: 0,
	logs_arr: [],
};

function promotionReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_PROMOTION':
			return {
				...state,
				isLoading: action.payload
			};
		case 'LIST_PROMOTION':
			return {
				...state,
				listpromotions: action.payload.listpromotions,
				totalsize: action.payload.totalsize
			};
		case 'LIST_PROMOTION_PRODUCT_TIKTOK':
			return {
				...state,
				listpromotionproducttiktoks: action.payload.listpromotionproducttiktoks,
				totalsize_listpromotionproducttiktoks: action.payload.totalsize
			};
		case 'UP_PROMOTION_LOGS':
			return {
				...state,
				successfully: action.payload.successfully,
				failed: action.payload.failed,
				logs_arr: action.payload.logs_arr
			};
		case 'UP_PROMOTION_PERCENT':
			return {
				...state,
				percent: action.payload.percent
			};
		default:
		  return state;
    }
}

export default promotionReducer;
