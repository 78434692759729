import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";

const getListOrders = (page = 0, sizeperpage = 0, search = "") => (dispatch, getState) => {
	dispatch({type: 'LOADING_ORDER', payload: true});
	var param_search = "";
	if (search !== "") {
		param_search = `&search=${search}`;
	}
	axios.get(`/api/order/list?page=${page}&sizeperpage=${sizeperpage}${param_search}`, tokenConfig(getState)).then(res => {
		dispatch({type: 'LOADING_ORDER', payload: false});
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_ORDER',
				payload: res.data.data
			});
		} else {
			Swal.fire(t("Error"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({type: 'LOADING_ORDER', payload: false});
		Swal.fire(t("Error"), t("An error occurred!"), 'error');
	});
};

const saveEditOrder = (data, closemodal = ()=>{}) => (dispatch, getState) => {
	var body = {
		_id: data?._id,
		keywords: data?.keywords,
		type: data?.type,
		printer: data?.printer,
		design_fee: data?.design_fee,
		mockup: data?.mockup,
		design: data?.design,
		shipping_label: data?.shipping_label,
		order_status: data?.order_status,
		tracking_number: data?.tracking_number,
	};
	// Swal.fire({
	// 	title: t("Are you sure?"),
	// 	text: t("Are you sure to edit order?"),
	// 	icon: 'warning',
	// 	showCancelButton: true,
	// 	confirmButtonColor: '#3085d6',
	// 	cancelButtonColor: '#d33',
	// 	confirmButtonText: t("Sure"),
	// 	cancelButtonText: t("Cancel")
	// }).then((result) => {
	// 	if (result.value) {
			dispatch({type: 'LOADING_ORDER', payload: true});
			axios.post('/api/order/edit', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_ORDER', payload: false});
				if (res.data.status === 200) {
					closemodal();
					dispatch(getListOrders(data.page, data.sizePerPage, data.searchText));
					// Swal.fire(t("Success"), t(res.data.message), 'success');
				} else {
                    Swal.fire(t("Error"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_ORDER', payload: false});
                Swal.fire(t("Error"), t("An error occurred!"), 'error');
			});
	// 	}
	// })
};

const sendToPrinter = (data) => (dispatch, getState) => {
	var body = {
		_id: data?._id ? data?._id.split(",") : []
	};
	Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to send to printer?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_ORDER', payload: true});
			axios.post('/api/order/send-to-printer', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_ORDER', payload: false});
				if (res.data.status === 200) {
					dispatch(getListOrders(data.page, data.sizePerPage, data.searchText));
					Swal.fire(t("Success"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Error"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_ORDER', payload: false});
                Swal.fire(t("Error"), t("An error occurred!"), 'error');
			});
		}
	})
};

const cancelToPrinter = (data) => (dispatch, getState) => {
	var body = {
		_id: data?._id ? data?._id.split(",") : []
	};
	Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to cancel to printer?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_ORDER', payload: true});
			axios.post('/api/order/cancel-to-printer', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_ORDER', payload: false});
				if (res.data.status === 200) {
					dispatch(getListOrders(data.page, data.sizePerPage, data.searchText));
					Swal.fire(t("Success"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Error"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_ORDER', payload: false});
                Swal.fire(t("Error"), t("An error occurred!"), 'error');
			});
		}
	})
};

export {
    getListOrders,
    saveEditOrder,
    sendToPrinter,
    cancelToPrinter,
};
