import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

function ModalUploadCSV({ onSubmit }) {
    const [isOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState("");
    const { t } = useTranslation();

    const onChange = (e) => {
        if (e.target.name === "email") {
            setEmail(e.target.value);
        }
    };

    const handleSubmit = async () => {
        await onSubmit(email);
        setIsOpen(false);
    };

    return (
        <Fragment>
            <button
                className="btn btn-icon btn-bg-light btn-active-color-info btn-sm"
                data-tooltip-id="my-tooltip"
                data-tooltip-content={t("Share")}
                onClick={() => setIsOpen(true)
                }
            >
                <i className="ki-outline ki-exit-up fs-2"></i>
            </button>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    setIsOpen(!isOpen);
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Email Receive?")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="form-group">
                        <input className="form-control" placeholder="Email?" type="email" name="email" onChange={(e) => { onChange(e) }} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Share")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalUploadCSV);
