const initialState = {
	isLoading: false,
	list: [],
	totalsize: 0
};

function productTemplateReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_PRODUCT_TEMPLATE':
			return {
				...state,
				isLoading: action?.payload
			};	
		case 'ADMIN_LIST_PRODUCT_TEMPLATE':
			return {
				...state,
				list: action?.payload?.products || [],
				totalsize: action?.payload?.totalsize || 0
			};
		default:
		  return state;
    }
}

export default productTemplateReducer;
