import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";


const getNotification = () => (dispatch, getState) => {
	dispatch({ type: 'LOADING_NOTIFICATION', payload: true });
	axios.get(`/api/notification`, tokenConfig(getState)).then(res => {
		dispatch({ type: 'LOADING_NOTIFICATION', payload: false });

		if (res.data.status === 200) {
			dispatch({
				type: 'ADMIN_NOTIFICATION',
				payload: res.data.data
			});
		} else {
			Swal.fire(t("Error"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({ type: 'LOADING_NOTIFICATION', payload: false });
		Swal.fire(t("Error"), t("An error occurred!"), 'error');
	});
};

const saveNotification = (data) => (dispatch, getState) => {
	var body = {
		_id: data._id,
		chat_id: data.chat_id,
		message: data.message,
		type: data.type,
	};
	Swal.fire({
		title: "Are you sure?",
		text: "Are you sure to edit notification?",
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: "Sure",
		cancelButtonText: "Cancel"
	}).then((result) => {
		if (result.value) {
			dispatch({ type: 'LOADING_NOTIFICATION', payload: true });
			axios.post('/api/notification', body, tokenConfig(getState)).then(res => {
				dispatch({ type: 'LOADING_NOTIFICATION', payload: false });
				if (res.data.status === 200) {
					dispatch(getNotification());
					Swal.fire("Success", res.data.message, 'success');
				} else {
					Swal.fire("Error", res.data.message, 'error');
				}
			}).catch(err => {
				dispatch({ type: 'LOADING_NOTIFICATION', payload: false });
				Swal.fire("Error", "An error occurred!", 'error');
			});
		}
	})
};

const testNotification = (data) => (dispatch, getState) => {
	axios.post('/api/notification/test', data, tokenConfig(getState)).then(res => {
		if (res.data.status === 200) {
			Swal.fire("Success", res.data.message, 'success');
		} else {
			Swal.fire("Error", res.data.message, 'error');
		}
	}).catch(err => {
		Swal.fire("Error", "An error occurred!", 'error');
	});
};

export {
	getNotification,
	saveNotification,
	testNotification,
};
