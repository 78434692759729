import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { checkImageURL } from "components/Function";

function ModalLinkImages({ onSubmit }) {
    const [data, setData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    const handleChange = (e) => {
        const arr = e.target.value.split("\n");
        setData(arr.filter((v) => checkImageURL(v)));
    };

    const handleSubmit = () => {
        onSubmit(data);
        setIsOpen(false);
    };

    return (
        <Fragment>
            <button onClick={() => setIsOpen(true)} type="button" className="btn btn-primary mr-1">
                <i className="fa fa-cloud-upload mr-1"></i> {t("Import media from URL")}
            </button>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    setIsOpen(!isOpen);
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Import media from URL")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="form-group">
                        <label>URL</label>
                        <div>
                            <textarea
                                className="form-control"
                                type="text"
                                name="list_image_url"
                                placeholder="https://image.com/1.png
https://image.com/2.jpg
https://image.com/3.gif
..."
                                rows={5}
                                defaultValue={""}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalLinkImages);
