import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";


const getEmail = () => (dispatch, getState) => {
    dispatch({ type: 'LOADING_EMAIL', payload: true });
    axios.get(`/api/email`, tokenConfig(getState)).then(res => {
        dispatch({ type: 'LOADING_EMAIL', payload: false });

        if (res.data.status === 200) {
            dispatch({
                type: 'ADMIN_EMAIL',
                payload: res.data.data
            });
        } else {
            Swal.fire(t("Error"), t(res.data.message), 'error');
        }
    }).catch(err => {
        dispatch({ type: 'LOADING_EMAIL', payload: false });
        Swal.fire(t("Error"), t("An error occurred!"), 'error');
    });
};

const saveEmail = (data) => (dispatch, getState) => {
	var body = {
		_id: data._id,
		email: data.email_,
		password: data.password,
		host: data.host,
		port: data.port,
	};
	Swal.fire({
		title: "Are you sure?",
		text: "Are you sure to edit email system?",
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: "Sure",
		cancelButtonText: "Cancel"
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_EMAIL', payload: true});
			axios.post('/api/email', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_EMAIL', payload: false});
				if (res.data.status === 200) {
					dispatch(getEmail());
					Swal.fire("Success", res.data.message, 'success');
				} else {
					Swal.fire("Error", res.data.message, 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_EMAIL', payload: false});
				Swal.fire("Error", "An error occurred!", 'error');
			});
		}
	})
};

export {
    getEmail,
    saveEmail
};
