import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import PropTypes from "prop-types";
import { getProductsByShops } from "actions/productActions";
import { formatterCreateAt, subStringText } from "components/Function";
import { ReactTable } from "components/Table";
import { syncProduct } from "actions/productActions";

function Listproducts(props) {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [totalSize, setTotalSize] = useState(0);
    const [toggleCard, setToggleCard] = useState(false);
    const [loadingSync, setLoadingSync] = useState(false);
    const { t } = useTranslation();
    var { listproductsbyshops, totalsize_listproductsbyshops } = props.product;
	var { list } = props.shop;
    var {
        getProductsByShops,
        promotion_selected,
        shops_selected,
        products_selected,
        setProductsSelected,
        searchText,
        setSearchText,
        syncProduct,
        subStringText,
        check_all_products,
        setCheckAllProducts,
    } = props;

    useEffect(() => {
        setProductsSelected([]);
        getProductsByShops({ page, sizeperpage: sizePerPage, search: searchText, promotion_selected, shops_selected });
    }, [setProductsSelected, getProductsByShops, page, sizePerPage, searchText, promotion_selected, shops_selected]);

    useEffect(() => {
        setTotalSize(totalsize_listproductsbyshops);
    }, [totalsize_listproductsbyshops]);
	
	const onClickSelectAllProducts = async () => {
        setProductsSelected([]);
        setCheckAllProducts(!check_all_products);
	};

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "shopId",
            text: t("Shop"),
            sort: true,
            formatter: (cell, row) => list.find((v) => v._id === cell)?.name||"",
        },
        {
            dataField: "title",
            text: t("Title"),
            sort: true,
            formatter: (cell, row) => (
                <Fragment>
                    <div className="symbol symbol-50px me-2">
                        <img src={row?.main_images?.[0]||require("components/assets/media/misc/image.png")} alt="" />
                    </div>
                    <div className="d-inline-block">
                        <span className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">{subStringText(cell, 130)}</span>
                    </div>
                </Fragment>
            ),
        },
        {
            dataField: "skus",
            text: t("Skus"),
            sort: true,
            formatter: (cell, row) => <Fragment>{cell?.length > 0 ? "VARIANTS" : "PRODUCT"}</Fragment>,
        },
        {
            dataField: "price",
            text: t("Price"),
            sort: true,
        },   
    ];

    const groupButton = () => {
        return (
			<div className="mb-5">
                <button
                    onClick={async () => {
                        setLoadingSync(true);
                        await syncProduct(shops_selected).then(() => {
                            // setProductsSelected([]);
                            getProductsByShops({
                                page,
                                sizeperpage: sizePerPage,
                                search: searchText,
                                promotion_selected,
                                shops_selected,
                            });
                        });
                        setLoadingSync(false);
                    }}
                    disabled={loadingSync}
                    type="button"
                    className="btn btn-primary btn-sm me-2"
                >
                    {loadingSync && (
                        <div className="spinner-border text-light spinner-border-sm mx-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    )}

                    {t("Product Sync")}
                </button>
				<button onClick={() => { onClickSelectAllProducts() }} type="button" className={cx("btn btn-sm me-2", { "btn-secondary": !check_all_products, "btn-success": check_all_products })}>
					<i className={cx("ki-outline fs-2", { "ki-cross-square": !check_all_products, "ki-check-square": check_all_products })}></i> {t("Select all products")}
				</button>
            </div>
        );
    };

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(10);
            setSearchText(searchText);
        } else {
            setPage(page||1);
            setSizePerPage(sizePerPage);
        }
    };

    const removeSelect = (arr, value) => {
        var i = 0;
        while (i < arr.length) {
            if (arr[i] === value) {
                arr.splice(i, 1);
            } else {
                ++i;
            }
        }
        return arr;
    };

    const onSelect = (row, isSelect) => {
        setCheckAllProducts(false);
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp.push(row._id);
        } else {
            products_selected_tmp = removeSelect(products_selected_tmp, row._id);
        }
        var new_arr = JSON.stringify(products_selected_tmp);
        setProductsSelected(JSON.parse(new_arr));
    };

    const onSelectAll = (isSelect, row) => {
        setCheckAllProducts(false);
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp = row.map((v, i) => v._id);
        } else {
            products_selected_tmp = [];
        }
        setProductsSelected(products_selected_tmp);
    };

    const selectRow = {
        mode: "checkbox",
        clickToSelect: false,
        selected: products_selected,
        onSelect: onSelect,
        onSelectAll: onSelectAll,
    };

    const sizePerPageList = [
        {
            text: '10', value: 10
        },
        {
            text: '50', value: 50
        },
        {
            text: '100', value: 100
        },
        {
            text: '150', value: 150
        },
        {
            text: '200', value: 200
        },
        {
            text: 'All', value: totalsize_listproductsbyshops||10
        }
    ];

    return (
        <Fragment>
            <div className="card mb-5 mb-xl-10">
                <div
                    className={cx("card-header collapsible cursor-pointer rotate", {
                        active: !toggleCard,
                        collapsed: toggleCard,
                    })}
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_docs_card_collapsible"
                    onClick={() => setToggleCard(!toggleCard)}
                >
                    <h3 className="card-title">{t("List Products")}</h3>
                    <div className="card-toolbar rotate-180">
                        <i className="ki-duotone ki-down fs-1"></i>
                    </div>
                </div>
                <div id="kt_docs_card_collapsible" className={cx("collapse", { show: !toggleCard })}>
                    <div className="card-body p-9">
                        <div className="al-variants-table">
                            <ReactTable
                                columns={columns}
                                data={listproductsbyshops}
                                groupButton={groupButton}
                                handleTableChange={handleTableChange}
                                page={page}
                                sizePerPage={sizePerPage}
                                totalSize={totalSize}
                                alClassName={cx("table-layout-inherit", { "al-disabled": check_all_products })}
                                isSelectRow={true}
                                selectRow={selectRow}
                                sizePerPageList={sizePerPageList}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

Listproducts.propTypes = {
    formatterCreateAt: PropTypes.func.isRequired,
    getProductsByShops: PropTypes.func.isRequired,
    subStringText: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    product: state.product,
    shop: state.shop,
});
const mapDispatchToProps = {
    formatterCreateAt,
    getProductsByShops,
    syncProduct,
    subStringText,
};

export default connect(mapStateToProps, mapDispatchToProps)(Listproducts);
