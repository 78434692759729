import { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

function Main(props) {
    const { t } = useTranslation();
    var { role } = props.user.user;
    return (
        <Fragment>
            <div className="d-flex justify-content-evenly align-items-center flex-wrap gap-5" role="tablist">
                {(role === 1 || role === 0) && (
                    <>
                        <div className="card">
                            <Link className="nav-link btn btn-active-danger btn-center bg-gray-200 btn-color-gray-600 rounded-3 flex-column overflow-hidden w-140px h-140px pt-7 pt-lg-5 pb-2 border border-secondary" data-bs-toggle="tab" to="/admin/finances/statements" aria-selected="true" role="tab">
                                <div className="nav-icon mb-3">
                                    <i className="ki-outline ki-lots-shopping fs-5x p-0"></i>
                                </div>
                                <span className="fw-semibold fs-6 lh-3">{t("Statements")}</span>
                            </Link>
                        </div>
                        <div className="card">
                            <Link className="nav-link btn btn-active-danger btn-center bg-gray-200 btn-color-gray-600 rounded-3 flex-column overflow-hidden w-140px h-140px pt-7 pt-lg-5 pb-2 border border-secondary" data-bs-toggle="tab" to="/admin/finances/withdrawals" aria-selected="true" role="tab">
                                <div className="nav-icon mb-3">
                                    <i className="ki-outline ki-exit-up fs-5x p-0"></i>
                                </div>
                                <span className="fw-semibold fs-6 lh-3">{t("Withdrawals")}</span>
                            </Link>
                        </div>
                        <div className="card">
                            <Link className="nav-link btn btn-active-danger btn-center bg-gray-200 btn-color-gray-600 rounded-3 flex-column overflow-hidden w-140px h-140px pt-7 pt-lg-5 pb-2 border border-secondary" data-bs-toggle="tab" to="/admin/finances/payments" aria-selected="true" role="tab">
                                <div className="nav-icon mb-3">
                                    <i className="ki-outline ki-bank fs-5x p-0"></i>
                                </div>
                                <span className="fw-semibold fs-6 lh-3">{t("Payments")}</span>
                            </Link>
                        </div>
                    </>
                )}
            </div>
        </Fragment>
    );
}

Main.propTypes = {
};
const mapStateToProps = state => ({
    user: state.user,
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);