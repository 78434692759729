import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Loading from "components/Loading";
import { addDesign } from "actions/designAction";
import { Link } from "react-router-dom";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import ReactImageMagnify from 'react-image-magnify';
import { formatterImage } from "components/Function";

function ModalAddDesign(props) {
    const [title, setTitle] = useState("");
    const [keyword, setKeyword] = useState("");
    const [position_front, setPositionFront] = useState("");
    const [position_back, setPositionBack] = useState("");
    const [position_neck, setPositionNeck] = useState("");
    const [position_right, setPositionRight] = useState("");
    const [position_left, setPositionLeft] = useState("");
    const [position_aop_3d, setPositionAOP3D] = useState("");

    const [mockup_front, setMockupFront] = useState("");
    const [mockup_back, setMockupBack] = useState("");
    const [mockup_neck, setMockupNeck] = useState("");
    const [mockup_right, setMockupRight] = useState("");
    const [mockup_left, setMockupLeft] = useState("");
    const [mockup_aop_3d, setMockupAOP3D] = useState("");

    var { isLoading } = props.design;
    var { isOpenModalAddDesign, openModal, addDesign, formatterImage } = props;
    const { t } = useTranslation();

    const onClickSaveAddDesign = () => {
        const mockup = {
            Front: mockup_front,
            Back: mockup_back,
            Neck: mockup_neck,
            Right: mockup_right,
            Left: mockup_left,
            AOP_3D: mockup_aop_3d
        }
        const design = {
            Front: position_front,
            Back: position_back,
            Neck: position_neck,
            Right: position_right,
            Left: position_left,
            AOP_3D: position_aop_3d
        }
        addDesign({ title, keyword, mockup, design }, openModal);
    };

    const onChange = (e) => {
        if (e.target.name === "title") {
            setTitle(e.target.value)
        }
        if (e.target.name === "keyword") {
            setKeyword(e.target.value);
        }
        if (e.target.name === "position_front") {
            setPositionFront(e.target.value);
        }
        if (e.target.name === "position_back") {
            setPositionBack(e.target.value);
        }
        if (e.target.name === "position_neck") {
            setPositionNeck(e.target.value);
        }
        if (e.target.name === "position_right") {
            setPositionRight(e.target.value);
        }
        if (e.target.name === "position_left") {
            setPositionLeft(e.target.value);
        }
        if (e.target.name === "position_aop_3d") {
            setPositionAOP3D(e.target.value);
        }
        if (e.target.name === "mockup_front") {
            setMockupFront(e.target.value);
        }
        if (e.target.name === "mockup_back") {
            setMockupBack(e.target.value);
        }
        if (e.target.name === "mockup_neck") {
            setMockupNeck(e.target.value);
        }
        if (e.target.name === "mockup_right") {
            setMockupRight(e.target.value);
        }
        if (e.target.name === "mockup_left") {
            setMockupLeft(e.target.value);
        }
        if (e.target.name === "mockup_aop_3d") {
            setMockupAOP3D(e.target.value);
        }
    }

    const onOpenModal = () => {
        openModal();
    };

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => { onOpenModal() }} isOpen={isOpenModalAddDesign}>
                <Loading isLoading={isLoading} />
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Add Design")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onOpenModal() }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Title")}</span>
                        </label>
                        <input type="text" className="form-control" value={title} placeholder={t("Title")} name="title" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Keyword")}</span>
                        </label>
                        <input type="text" className="form-control" value={keyword} placeholder={t("Keyword")} name="keyword" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="mb-5">
                        <div className="mb-5">
                            <div className="h3">DESIGN</div>
                            <div className="d-flex justify-content-around mb-5">
                                <div className="d-flex align-items-center justify-content-around flex-column">
                                    <span className="h4">Front</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            position_front ?
                                                <Link to={position_front} target="_blank">
                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(position_front) }, largeImage: { src: formatterImage(position_front), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer" }} />

                                                </Link>
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={position_front} placeholder={t("Front")} name="position_front" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-around flex-column ml-5">
                                    <span className="h4">Back</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            position_back ?
                                                <Link to={position_back} target="_blank">
                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(position_back) }, largeImage: { src: formatterImage(position_back), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer" }} />

                                                </Link>
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={position_back} placeholder={t("Back")} name="position_back" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-around flex-column mr-5">
                                    <span className="h4">Neck</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            position_neck ?
                                                <Link to={position_neck} target="_blank">
                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(position_neck) }, largeImage: { src: formatterImage(position_neck), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer" }} />

                                                </Link>
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={position_neck} placeholder={t("Neck")} name="position_neck" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-around mb-5">
                                <div className="d-flex  align-items-center justify-content-around flex-column mr-5">
                                    <span className="h4">Right</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            position_right ?
                                                <Link to={position_right} target="_blank">
                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(position_right) }, largeImage: { src: formatterImage(position_right), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer" }} />

                                                </Link>
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={position_right} placeholder={t("Right")} name="position_right" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-around flex-column mr-5">
                                    <span className="h4">Left</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            position_left ?
                                                <Link to={position_left} target="_blank">
                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(position_left) }, largeImage: { src: formatterImage(position_left), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer" }} />

                                                </Link>
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={position_left} placeholder={t("Left")} name="position_left" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-around flex-column mr-5">
                                    <span className="h4">AOP 3D</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            position_aop_3d ?
                                                <Link to={position_aop_3d} target="_blank">
                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(position_aop_3d) }, largeImage: { src: formatterImage(position_aop_3d), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer" }} />

                                                </Link>
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={position_aop_3d} placeholder={t("AOP 3D")} name="position_aop_3d" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div>
                            <div className="h3">MOCKUP</div>
                            <div className="d-flex justify-content-around mb-5">
                                <div className="d-flex align-items-center justify-content-around flex-column">
                                    <span className="h4">Front</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            mockup_front ?
                                                <Link to={mockup_front} target="_blank">
                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup_front) }, largeImage: { src: formatterImage(mockup_front), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer" }} />

                                                </Link>
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={mockup_front} placeholder={t("Front")} name="mockup_front" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-around flex-column ml-5">
                                    <span className="h4">Back</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            mockup_back ?
                                                <Link to={mockup_back} target="_blank">
                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup_back) }, largeImage: { src: formatterImage(mockup_back), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer" }} />

                                                </Link>
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={mockup_back} placeholder={t("Back")} name="mockup_back" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-around flex-column mr-5">
                                    <span className="h4">Neck</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            mockup_neck ?
                                                <Link to={mockup_neck} target="_blank">
                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup_neck) }, largeImage: { src: formatterImage(mockup_neck), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer" }} />

                                                </Link>
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={mockup_neck} placeholder={t("Neck")} name="mockup_neck" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-around mb-5">
                                <div className="d-flex  align-items-center justify-content-around flex-column mr-5">
                                    <span className="h4">Right</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            mockup_right ?
                                                <Link to={mockup_right} target="_blank">
                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup_right) }, largeImage: { src: formatterImage(mockup_right), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer" }} />

                                                </Link>
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={mockup_right} placeholder={t("Right")} name="mockup_right" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-around flex-column mr-5">
                                    <span className="h4">Left</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            mockup_left ?
                                                <Link to={mockup_left} target="_blank">
                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup_left) }, largeImage: { src: formatterImage(mockup_left), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer" }} />

                                                </Link>
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={mockup_left} placeholder={t("Left")} name="mockup_left" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-around flex-column mr-5">
                                    <span className="h4">AOP 3D</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            mockup_aop_3d ?
                                                <Link to={mockup_aop_3d} target="_blank">
                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup_aop_3d) }, largeImage: { src: formatterImage(mockup_aop_3d), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer" }} />

                                                </Link>
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={mockup_aop_3d} placeholder={t("AOP 3D")} name="mockup_aop_3d" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <button type="reset" id="kt_modal_users_search_reset" data-bs-dismiss="modal" className="btn btn-active-light me-3" onClick={() => { onOpenModal() }} >{t("Cancel")}</button>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveAddDesign() }}  >{t("Save")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

ModalAddDesign.propTypes = {
    addDesign: PropTypes.func.isRequired,
    formatterImage: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    design: state.design
});
const mapDispatchToProps = {
    addDesign,
    formatterImage,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddDesign);