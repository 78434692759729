import { connect } from "react-redux";
import { Link } from "react-router-dom";

function Main(props) {
    var { role } = props.user.user;

    return (
        <>
            <div className="d-flex justify-content-evenly align-items-center flex-wrap gap-5" role="tablist">
                {role === 1 && (
                    <div className="card">
                        <Link className="nav-link btn btn-active-danger btn-center bg-gray-200 btn-color-gray-600 rounded-3 flex-column overflow-hidden w-140px h-140px pt-7 pt-lg-5 pb-2 border border-secondary" data-bs-toggle="tab" to="/admin/manager" aria-selected="true" role="tab">
                            <div className="nav-icon mb-3">
                                <i className="ki-outline ki-rocket fs-5x p-0"></i>
                            </div>
                            <span className="fw-semibold fs-6 lh-3">Admin</span>
                        </Link>
                    </div>
                )}
                {(role === 1 || role === 0) && (
                    <div className="card">
                        <Link className="nav-link btn btn-active-danger btn-center bg-gray-200 btn-color-gray-600 rounded-3 flex-column overflow-hidden w-140px h-140px pt-7 pt-lg-5 pb-2 border border-secondary" data-bs-toggle="tab" to="/admin/users-staff" aria-selected="true" role="tab">
                            <div className="nav-icon mb-3">
                                <i className="ki-outline ki-address-book fs-5x p-0"></i>
                            </div>
                            <span className="fw-semibold fs-6 lh-3">Users Staff</span>
                        </Link>
                    </div>
                )}
                {(role === 1 || role === 0) && (
                    <div className="card">
                        <Link className="nav-link btn btn-active-danger btn-center bg-gray-200 btn-color-gray-600 rounded-3 flex-column overflow-hidden w-140px h-140px pt-7 pt-lg-5 pb-2 border border-secondary" data-bs-toggle="tab" to="/admin/shops" aria-selected="true" role="tab">
                            <div className="nav-icon mb-3">
                                <i className="ki-outline ki-shop fs-5x p-0"></i>
                            </div>
                            <span className="fw-semibold fs-6 lh-3">Shops</span>
                        </Link>
                    </div>
                )}
                <div className="card">
                    <Link className="nav-link btn btn-active-danger btn-center bg-gray-200 btn-color-gray-600 rounded-3 flex-column overflow-hidden w-140px h-140px pt-7 pt-lg-5 pb-2 border border-secondary" data-bs-toggle="tab" to="/admin/promotions" aria-selected="true" role="tab">
                        <div className="nav-icon mb-3">
                            <i className="ki-outline ki-discount fs-5x p-0"></i>
                        </div>
                        <span className="fw-semibold fs-6 lh-3">Promotions</span>
                    </Link>
                </div>
                <div className="card">
                    <Link className="nav-link btn btn-active-danger btn-center bg-gray-200 btn-color-gray-600 rounded-3 flex-column overflow-hidden w-140px h-140px pt-7 pt-lg-5 pb-2 border border-secondary" data-bs-toggle="tab" to="/admin/products" aria-selected="true" role="tab">
                        <div className="nav-icon mb-3">
                            <i className="ki-outline ki-category fs-5x p-0"></i>
                        </div>
                        <span className="fw-semibold fs-6 lh-3">Products</span>
                    </Link>
                </div>
                {(role === 1 || role === 0) && (
                    <div className="card">
                        <Link className="nav-link btn btn-active-danger btn-center bg-gray-200 btn-color-gray-600 rounded-3 flex-column overflow-hidden w-140px h-140px pt-7 pt-lg-5 pb-2 border border-secondary" data-bs-toggle="tab" to="/admin/orders" aria-selected="true" role="tab">
                            <div className="nav-icon mb-3">
                                <i className="ki-outline ki-handcart fs-5x p-0"></i>
                            </div>
                            <span className="fw-semibold fs-6 lh-3">Orders</span>
                        </Link>
                    </div>
                )}
                {(role === 1 || role === 0) && (
                    <div className="card">
                        <Link className="nav-link btn btn-active-danger btn-center bg-gray-200 btn-color-gray-600 rounded-3 flex-column overflow-hidden w-140px h-140px pt-7 pt-lg-5 pb-2 border border-secondary" data-bs-toggle="tab" to="/admin/finances" aria-selected="true" role="tab">
                            <div className="nav-icon mb-3">
                                <i className="ki-outline ki-financial-schedule fs-5x p-0"></i>
                            </div>
                            <span className="fw-semibold fs-6 lh-3">Finance</span>
                        </Link>
                    </div>
                )}
                {(role === 1 || role === 0) && (
                    <div className="card">
                        <Link className="nav-link btn btn-active-danger btn-center bg-gray-200 btn-color-gray-600 rounded-3 flex-column overflow-hidden w-140px h-140px pt-7 pt-lg-5 pb-2 border border-secondary" data-bs-toggle="tab" to="/admin/notification" aria-selected="true" role="tab">
                            <div className="nav-icon mb-3">
                                <i className="ki-outline ki-notification-on fs-5x p-0"></i>
                            </div>
                            <span className="fw-semibold fs-6 lh-3">Notification</span>
                        </Link>
                    </div>
                )}
            </div>
            <div className="post d-flex flex-column-fluid mt-10" id="kt_post">

                <div id="kt_content_container">

                    <div className="card-body">

                        <div className="mb-18">

                            <div className="mb-10">

                                <div className="text-center mb-5">

                                    <h3 className="fs-2hx text-gray-900 mb-5">Optimize your sales system with US</h3>


                                    <div className="fs-5 text-muted fw-semibold">Within the last 10 years, we have sold over 100,000 admin theme copies that have been
                                        <br />successfully deployed by small businesses to global enterprises</div>

                                </div>


                                <div className="overlay">

                                    <img className="w-100 card-rounded" src={require("components/assets/media/stock/1600x800/img-4.jpg")} alt="" />


                                    <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                        <Link to="pages/pricing.html" className="btn btn-primary">Pricing</Link>
                                        <Link to="pages/careers/apply.html" className="btn btn-light-primary ms-3">Join Us</Link>
                                    </div>

                                </div>

                            </div>


                            <div className="fs-5 fw-semibold text-gray-600">

                                <p className="mb-8">First, a disclaimer – the entire process of writing a blog post often takes more than a couple of hours, even if you can type eighty words per minute and your writing skills are sharp. From the seed of the idea to finally hitting “Publish,” you might spend several days or maybe even a week “writing” a blog post, but it’s important to spend those vital hours planning your post and even thinking about
                                    <Link to="pages/blog/post.html" className="link-primary pe-1">Your Post</Link>(yes, thinking counts as working if you’re a blogger) before you actually write it.</p>


                                <p className="mb-8">There’s an old maxim that states,
                                    <span className="text-gray-800 pe-1">“No fun for the writer, no fun for the reader.”</span>No matter what industry you’re working in, as a blogger, you should live and die by this statement.</p>


                                <p className="mb-8">Before you do any of the following steps, be sure to pick a topic that actually interests you. Nothing – and
                                    <Link to="pages/blog/home.html" className="link-primary pe-1">I mean NOTHING</Link>– will kill a blog post more effectively than a lack of enthusiasm from the writer. You can tell when a writer is bored by their subject, and it’s so cringe-worthy it’s a little embarrassing.</p>


                                <p className="mb-17">I can hear your objections already. “But Dan, I have to blog for a cardboard box manufacturing company.” I feel your pain, I really do. During the course of my career, I’ve written content for dozens of clients in some less-than-thrilling industries (such as financial regulatory compliance and corporate housing), but the hallmark of a professional blogger is the ability to write well about any topic, no matter how dry it may be. Blogging is a lot easier, however, if you can muster at least a little enthusiasm for the topic at hand.</p>

                            </div>

                        </div>


                        <div className="card bg-light mb-18">

                            <div className="card-body py-15">

                                <div className="d-flex flex-center">

                                    <div className="d-flex flex-center flex-wrap mb-10 mx-auto gap-5 w-xl-900px">

                                        <div className="octagon d-flex flex-center h-200px w-200px bg-body mx-lg-10">

                                            <div className="text-center">

                                                <i className="ki-outline ki-element-11 fs-2tx text-primary"></i>


                                                <div className="mt-1">

                                                    <div className="fs-lg-2hx fs-2x fw-bold text-gray-800 d-flex align-items-center">
                                                        <div className="min-w-70px" data-kt-countup="true" data-kt-countup-value="700">0</div>+</div>


                                                    <span className="text-gray-600 fw-semibold fs-5 lh-0">Businesses</span>

                                                </div>

                                            </div>

                                        </div>


                                        <div className="octagon d-flex flex-center h-200px w-200px bg-body mx-lg-10">

                                            <div className="text-center">

                                                <i className="ki-outline ki-chart-pie-4 fs-2tx text-success"></i>


                                                <div className="mt-1">

                                                    <div className="fs-lg-2hx fs-2x fw-bold text-gray-800 d-flex align-items-center">
                                                        <div className="min-w-50px" data-kt-countup="true" data-kt-countup-value="80">0</div>K+</div>


                                                    <span className="text-gray-600 fw-semibold fs-5 lh-0">Quick Reports</span>

                                                </div>

                                            </div>

                                        </div>


                                        <div className="octagon d-flex flex-center h-200px w-200px bg-body mx-lg-10">

                                            <div className="text-center">

                                                <i className="ki-outline ki-basket fs-2tx text-info"></i>


                                                <div className="mt-1">

                                                    <div className="fs-lg-2hx fs-2x fw-bold text-gray-800 d-flex align-items-center">
                                                        <div className="min-w-50px" data-kt-countup="true" data-kt-countup-value="35">0</div>M+</div>


                                                    <span className="text-gray-600 fw-semibold fs-5 lh-0">Payments</span>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <div className="fs-2 fw-semibold text-muted text-center mb-3">
                                    <span className="fs-1 lh-1 text-gray-700">“</span>When you care about your topic, you’ll write about it in a
                                    <br />
                                    <span className="text-gray-700 me-1">more powerful</span>, emotionally expressive way
                                    <span className="fs-1 lh-1 text-gray-700">“</span></div>


                                <div className="fs-2 fw-semibold text-muted text-center">
                                    <Link to="account/security.html" className="link-primary fs-4 fw-bold">Marcus Levy</Link>
                                    <span className="fs-4 fw-bold text-gray-600">,KeenThemes CEO</span>
                                </div>

                            </div>

                        </div>


                        <div className="mb-16">

                            <div className="text-center mb-12">

                                <h3 className="fs-2hx text-gray-900 mb-5">Publications</h3>


                                <div className="fs-5 text-muted fw-semibold">Our goal is to provide a complete and robust theme solution
                                    <br />to boost all of our customer’s project deployments</div>

                            </div>


                            <div className="row g-10">

                                <div className="col-md-4">

                                    <div className="card-xl-stretch me-md-6">


                                        <div className="m-0">

                                            <Link to="pages/user-profile/overview.html" className="fs-4 text-gray-900 fw-bold text-hover-primary text-gray-900 lh-base">Admin Panel - How To Started the Dashboard Tutorial</Link>


                                            <div className="fw-semibold fs-5 text-gray-600 text-gray-900 mt-3 mb-5">We’ve been focused on making a the from also not been afraid to and step away been focused create eye</div>


                                            <div className="fs-6 fw-bold">

                                                <Link to="apps/projects/users.html" className="text-gray-700 text-hover-primary">Jane Miller</Link>


                                                <span className="text-muted">on Mar 21 2021</span>

                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <div className="col-md-4">

                                    <div className="card-xl-stretch mx-md-3">


                                        <div className="m-0">

                                            <Link to="pages/user-profile/overview.html" className="fs-4 text-gray-900 fw-bold text-hover-primary text-gray-900 lh-base">Admin Panel - How To Started the Dashboard Tutorial</Link>


                                            <div className="fw-semibold fs-5 text-gray-600 text-gray-900 mt-3 mb-5">We’ve been focused on making the from v4 to v5 but we have also not been afraid to step away been focused</div>


                                            <div className="fs-6 fw-bold">

                                                <Link to="apps/projects/users.html" className="text-gray-700 text-hover-primary">Cris Morgan</Link>


                                                <span className="text-muted">on Apr 14 2021</span>

                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <div className="col-md-4">

                                    <div className="card-xl-stretch ms-md-6">


                                        <div className="m-0">

                                            <Link to="pages/user-profile/overview.html" className="fs-4 text-gray-900 fw-bold text-hover-primary text-gray-900 lh-base">Admin Panel - How To Started the Dashboard Tutorial</Link>


                                            <div className="fw-semibold fs-5 text-gray-600 text-gray-900 mt-3 mb-5">We’ve been focused on making the from v4 to v5 but we’ve also not been afraid to step away been focused</div>


                                            <div className="fs-6 fw-bold">

                                                <Link to="apps/projects/users.html" className="text-gray-700 text-hover-primary">Carles Nilson</Link>


                                                <span className="text-muted">on May 14 2021</span>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>


                        <div className="mb-5">

                            <div className="text-center mb-5">

                                <h3 className="fs-2hx text-gray-900 mb-5">Our Great Team</h3>


                                <div className="fs-5 text-muted fw-semibold">It’s no doubt that when a development takes longer to complete, additional costs to
                                    <br />integrate and test each extra feature creeps up and haunts most of us.</div>

                            </div>

                        </div>


                        <div className="card mb-4 bg-light text-center">

                            <div className="card-body py-12">

                                <Link to="#" className="mx-4">
                                    <img src={require("components/assets/media/svg/brand-logos/facebook-4.svg").default} className="h-30px my-2" alt="" />
                                </Link>


                                <Link to="#" className="mx-4">
                                    <img src={require("components/assets/media/svg/brand-logos/instagram-2-1.svg").default} className="h-30px my-2" alt="" />
                                </Link>


                                <Link to="#" className="mx-4">
                                    <img src={require("components/assets/media/svg/brand-logos/github.svg").default} className="h-30px my-2" alt="" />
                                </Link>


                                <Link to="#" className="mx-4">
                                    <img src={require("components/assets/media/svg/brand-logos/behance.svg").default} className="h-30px my-2" alt="" />
                                </Link>


                                <Link to="#" className="mx-4">
                                    <img src={require("components/assets/media/svg/brand-logos/pinterest-p.svg").default} className="h-30px my-2" alt="" />
                                </Link>


                                <Link to="#" className="mx-4">
                                    <img src={require("components/assets/media/svg/brand-logos/twitter.svg").default} className="h-30px my-2" alt="" />
                                </Link>


                                <Link to="#" className="mx-4">
                                    <img src={require("components/assets/media/svg/brand-logos/dribbble-icon-1.svg").default} className="h-30px my-2" alt="" />
                                </Link>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </>
    );
};

// Main.propTypes = {
// };
const mapStateToProps = (state) => ({
    user: state.user,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
