import axios from "axios";
import Swal from "sweetalert2";
// import { tokenConfig } from "./websiteActions";
import { t } from "i18next";
import { getCookie, setCookie } from "components/Function";

const tokenConfig = (getState) => {
    const token = getState().user.token;
    const config = {
        headers: {
            "Content-type": "application/json",
        },
    };
    if (token) {
        config.headers["al-token"] = token;
    }

    return config;
};

const toggleMenuMobile = () => (dispatch, getState) => {
    dispatch({
        type: "TOGGLE_MENU_MOBILE",
    });
};

const toggleMenuUser = (isTogglemenuuser) => (dispatch, getState) => {
    dispatch({
        type: "TOGGLE_MENU_USER",
        payload: isTogglemenuuser,
    });
};

const getAppSetting = () => (dispatch, getState) => {
    axios
        .get("/api/system-setting/user", tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                dispatch({
                    type: "SET_APP_CONFIG",
                    payload: res.data.data,
                });
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            Swal.fire(t("Error"), t("An error occurred!"), "error");
        });
};

const getExchangeRate =
    (base = "") =>
    (dispatch, getState) => {
        return new Promise(async (resolve) => {
            try {
                dispatch({ type: "LOADING_AD", payload: true });

                var exchange_rate = getCookie("ziads_exchange_rate")();
                if (exchange_rate) {
                    if (Object.keys(JSON.parse(exchange_rate))[0] === base) {
                        resolve("");
                        return false;
                    }
                }

                var body = {
                    base,
                };
                await axios
                    .post("/api/website/get-exchange-rate", body, tokenConfig(getState))
                    .then(async (res) => {
                        setCookie("ziads_exchange_rate", JSON.stringify(res.data.data.rates), 1)();

                        resolve(JSON.stringify(res.data.data.rates));
                    })
                    .catch((err) => {
                        Swal.fire(t("Error"), t("An error occurred!"), "error");
                        resolve("");
                    });

                dispatch({ type: "LOADING_AD", payload: false });
            } catch (err) {
                Swal.fire(t("Error"), t("An error occurred!"), "error");
                resolve("");
            }
        });
    };

const uploadImage =
    (file, data = {}) =>
    (dispatch, getState) => {
        const form = new FormData();
        for (var i = 0; i < file.length; i++) {
            form.append(`file[${i}]`, file[i]);
        }
        for (const [key, value] of Object.entries(data)) {
            form.append(key, value);
        }

        const options = {
            method: "POST",
            url: "/api/upload",
            headers: {
                "Content-Type": "multipart/form-data; boundary=---011000010111000001101001",
                "al-token": getState().user.token,
            },
            data: form,
        };
        return axios
            .request(options)
            .then(function (res) {
                if (res.data.status === 200) {
                    Swal.fire("Success", res.data.message, "success");
                    return res.data.data;
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            })
            .catch(function (error) {
                Swal.fire("Error", "An error occurred!", "error");
            });
    };

export { tokenConfig, toggleMenuMobile, toggleMenuUser, getAppSetting, getExchangeRate, uploadImage };
