import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { loginUser } from "actions/userActions";

function Login(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { handleTab, loginUser } = props;
    const { t } = useTranslation();

    const handleLogin = () => {
        loginUser(email, password);
    }

    const onChange = (e) => {
        if (e.target.name === "email") {
            setEmail(e.target.value);
        }
        if (e.target.name === "password") {
            setPassword(e.target.value);
        }
    }

    const onKeyPress = (e) => {
        if (e.charCode === 13) {
            loginUser(email, password);
        }
    }

    return (
        <Fragment>
            <div className="form w-100">
                <div className="text-center mb-11">
                    <h1 className="text-gray-900 fw-bolder mb-3">{t("Sign In")}</h1>
                </div>
                <div className="row g-3">
                    <div className="fv-row mb-8">
                        <input type="text" placeholder={t("Email")} name="email" autoComplete="off" className="form-control bg-transparent" value={email} onChange={(e) => { onChange(e) }} onKeyPress={(e) => { onKeyPress(e) }} />
                    </div>
                    <div className="fv-row mb-3">
                        <input type="password" placeholder={t("Password")} name="password" autoComplete="off" className="form-control bg-transparent" value={password} onChange={(e) => { onChange(e) }} onKeyPress={(e) => { onKeyPress(e) }} />
                    </div>
                    <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                        <div></div>
                        <Link to="#" className="link-danger" onClick={() => { handleTab("forgotpassword") }}>{t("Forgot Password")} ?</Link>
                    </div>
                    <div className="d-grid mb-8">
                        <button type="submit" id="kt_sign_in_submit" className="btn btn-danger" onClick={() => { handleLogin() }}>
                            <span className="indicator-label">{t("Sign In")}</span>
                        </button>
                    </div>
                    <div className="text-gray-500 text-center fw-semibold fs-6">{t("Not a Member yet?")}
                        <Link to="#" className="link-danger" onClick={() => { handleTab("signup") }}> {t("Sign up")}</Link>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    loginUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
