import { Fragment, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";
import { getProductTikTokv1, uploadTemplateByLink } from "actions/spytoolActions";
import { getProductTemplate } from "actions/productTemplateAction";
import { sleep } from "components/Function";
import { CSVLink } from "react-csv";
import ModalImport from "./modalImport";
import ModalChangeText from "./modalChangeText";
import Swal from "sweetalert2";
import _ from "lodash";

function TikTokProduct(props) {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [dataLink, setDataLink] = useState([]);
    const [selected, setSelected] = useState([]);
    const [isSelected, setIsSelected] = useState(false);
    const [dataExport, setDataExport] = useState([]);
    const btnRef = useRef(null);
    const [initiateDownload, setInitiateDownload] = useState(false);
    const [minSale, setMinSale] = useState(-1);
    const [searchText, setSearchText] = useState("");
    const [listLink, setListLink] = useState("");
    const [shop, setShop] = useState("");

    const { getProductTikTokv1, getProductTemplate, uploadTemplateByLink } = props;
    const { list } = props.productTemplate;

    const list_template = list.map((v, i) => {
        return { "value": v._id, "label": v.title }
    })

    const onChange = (e) => {
        if (e.target.name === "select_link") {
            if (e.target.checked) {
                setSelected((prev) => {
                    const dataRs = [...prev, e.target.value];
                    return dataRs;
                });
            } else {
                setSelected((prev) => {
                    const dataRs = prev?.filter(item => item !== e.target.value);
                    return dataRs;
                });
            }
        }
        if (e.target.name === "searchText") {
            setSearchText(e.target.value)
        }
        if (e.target.name === "listLink") {
            setListLink(e.target.value)
        }
        if (e.target.name === "shop") {
            setShop(e.target.value)
        }
        if (e.target.name === "minSale") {
            setMinSale(e.target.value)
        }
        if (e.target.name?.includes('title_')) {
            var id_change = parseInt(e.target.name?.split('title_')?.[1]);
            var value_new = e.target.value;
            var new_title = dataLink?.map((v, i) => {
                if (parseInt(v.id || 0) === id_change) {
                    v.name = value_new;
                }
                return v;
            })
            setDataLink(new_title);
        }
    }
    const selectedAll = () => {
        setIsSelected(!isSelected)
    }
    const onClickExportData = async () => {
        setIsLoading(true)
        let save_data_export = [];
        if (isSelected) {
            save_data_export = dataLink?.map((v, i) => {
                return {
                    ...v, title: v.name, image1: v.cover, image2: "", image3: "", image4: "", image5: "", image6: "", image7: "", image8: "", image9: ""
                }
            })
        } else {
            save_data_export = dataLink.filter((v, i) => selected?.includes(v.id.toString()))?.map((v, i) => {
                return {
                    ...v, title: v.name, image1: v.cover, image2: "", image3: "", image4: "", image5: "", image6: "", image7: "", image8: "", image9: ""
                }
            })
        }
        setDataExport(save_data_export)
        setInitiateDownload(true);
        await sleep(2500)
        setIsLoading(false)
    }

    useEffect(() => {
        getProductTemplate()
    }, [getProductTemplate]);

    useEffect(() => {
        setInitiateDownload(false);
        if (initiateDownload) {
            btnRef.current?.click();
        }
    }, [initiateDownload]);

    const onClickCrawl = async (type) => {
        setIsLoading(true)
        setDataLink([])
        setMinSale(0)
        if (type === 'limited') {
            await getProductTikTokv1(type, '').then((data) => {
                setDataLink(data?.data?.data || [])
                setIsLoading(false)
                if (data.status === 200) {
                    return Swal.fire(t("Success"), data.message, "success");
                }
                return Swal.fire(t("Error"), data.message, "error");
            })
                .catch((err) => {
                    console.log(err)
                });

        } else {
            const data = listLink?.split('\n');
            if (data?.length > 0 && shop === '') {
                Promise.all(data?.map(async (link, iv) => {
                    await sleep(500 * iv)
                    return new Promise(async (resolve2, reject2) => {
                        if (link && link !== '') {
                            await getProductTikTokv1(type, link).then((data) => {
                                var body = data?.data?.data || {};
                                if (body?.id) {
                                    setDataLink((prev) => {
                                        const dataRs = [...prev, { ...body }];
                                        return dataRs;
                                    });
                                }

                            })
                                .catch((err) => {
                                    console.log(err)
                                });
                        }

                        resolve2('ok');
                    });
                })).then(async (dat) => {
                    setIsLoading(false)
                    return Swal.fire(t("Success"), t("Crawl Success!"), "success");
                })
            } else {
                await getProductTikTokv1(type, '', shop).then((data) => {
                    setIsLoading(false)
                    data?.data?.getProduct?.map((v, i) => {
                        setDataLink((prev) => {
                            const dataRs = [...prev, { ...v }];
                            return dataRs;
                        });
                        return v;
                    })

                })
                    .catch((err) => {
                        console.log(err)
                    });
                return Swal.fire(t("Success"), t("Crawl Success!"), "success");
            }

        }
    };

    const sortData = async (data) => {
        setIsLoading(true)
        if (data === "ASC") {
            setDataLink(_.sortBy(dataLink, 'sold_count'))
        } else {
            setDataLink(_.sortBy(dataLink, 'sold_count').reverse())
        }
        setIsLoading(false)
    };

    const handleChangeTitle = async (data) => {
        var new_change_title = dataLink?.map((v, i) => {
            let textStart = data?.textStart ? data?.textStart + " " : "";
            let textEnd = data?.textEnd ? " " + data?.textEnd : "";
            let change_text = textStart + v?.name?.toLowerCase().split(data?.textOld?.toLowerCase()).join(data?.textNew?.toLowerCase() || "") + textEnd;
            let new_title = change_text?.split(" ")?.map((v, i) => v?.[0]?.toUpperCase() + v?.slice(1))?.join(" ");
            v.name = new_title;
            return v;
        })
        new_change_title = _.uniqBy(new_change_title, "id");
        setDataLink([])
        setDataLink(new_change_title);
        return Swal.fire(t("Success"), t("Change Success!"), "success");
    };

    const onDelete = async (id) => {
        setDataLink(dataLink?.filter((v, i) => v?.id !== id))
    };

    const handleSubmitUpload = async (data) => {
        setIsLoading(true)
        let data_uniqe = _.uniqBy(data, "id");
        Promise.all(data_uniqe?.map(async (obj, iv) => {
            await sleep(500 * iv)
            return new Promise(async (resolve2, reject2) => {
                await uploadTemplateByLink(obj).then((data_) => {
                    resolve2('ok');
                })
                    .catch((err) => {
                        resolve2('ok');
                    });
                resolve2('ok');
            });
        })).then(async (dat) => {
            setDataLink(dataLink?.filter((v, i) => !data_uniqe?.map((o, k) => o.id).includes(v.id)))
            setSelected([])
            setIsLoading(false)
            return Swal.fire(t("Success"), t("Upload Success!"), "success");
        })
    };

    return (
        <Fragment>
            <Loading isLoading={isLoading} />

            <div className="card mb-5 mb-xl-10">
                <h1 className="mb-5 mt-5 text-dark text-center">{t("Crawl TikTok")}</h1>
                <div className="row">
                    <div className="col-lg-2 col-md-12">
                        <div className="card-body">
                            <button
                                onClick={() => onClickCrawl('limited')}
                                type="submit"
                                id="kt_modal_users_search_submit"
                                className="btn btn-primary"
                            >
                                {t("Crawl Other")}
                            </button>
                        </div>

                    </div>
                    <div className="col-lg-5 col-md-12">
                        <div className="card-body">
                            <div className="form-group">
                                <label>Link TikTok
                                    <div><i className="text-danger">Suggest example: https://www.tiktok.com/t/ZP81Wgcyj/</i></div>
                                </label>
                                <textarea
                                    className="form-control mb-5"
                                    type="text"
                                    name="listLink"
                                    placeholder=""
                                    value={listLink}
                                    rows={5}
                                    onChange={(e) => { onChange(e) }}
                                />
                                <button
                                    onClick={() => onClickCrawl('normal')}
                                    type="submit"
                                    id="kt_modal_users_search_submit"
                                    className="btn btn-primary"
                                >
                                    {t("Crawl Link")}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                        <div className="card-body">
                            <div className="form-group">
                                <label>Shop TikTok
                                    <div><i className="text-danger">ID or Name</i></div>
                                </label>
                                <input
                                    className="form-control mb-5"
                                    type="text"
                                    name="shop"
                                    placeholder=""
                                    value={shop}
                                    onChange={(e) => { onChange(e) }}
                                />
                                <button
                                    onClick={() => onClickCrawl('normal')}
                                    type="submit"
                                    id="kt_modal_users_search_submit"
                                    className="btn btn-primary"
                                >
                                    {t("Crawl Shop")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="card card-body col-lg-4 mb-5">
                    <h1 className="mb-5 mt-5 text-dark text-center">{t(`DATA CRAWL ${(dataLink?.length || 0) + " PRODUCTS"} `)}</h1>
                    <div className="d-flex">
                        <button onClick={() => { selectedAll() }} type="button" className={"btn " + (isSelected ? "btn-success" : "btn-secondary") + " btn-sm mr-1"}>
                            <i className="fa fa-cloud-upload mr-1"></i> {t("Select all")}
                        </button>
                        <ModalImport
                            data={isSelected ? dataLink.map((v, i) => v.id) : selected}
                            dataLink={dataLink?.filter((v, i) => v?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) && v?.sold_count >= minSale)}
                            template={list_template}
                            onSubmit={(data) => {
                                handleSubmitUpload(data)
                            }}
                        />
                        <button onClick={onClickExportData} className="btn btn-sm btn-info mr-1"><i className="ki-outline ki-exit-down"></i>Export</button>
                        <CSVLink filename={"data_crawl_" + dataExport.length + "_products.csv"} data={dataExport}>
                            <span ref={btnRef} />
                        </CSVLink>
                    </div>
                    <div className="card-body">
                        {dataLink.length > 0 && <>
                            <div className="d-flex">
                                <span className="">Min Sold: <input type="number" className="btn btn-sm border" value={minSale} placeholder={t("1")} name="minSale" min={-1} onChange={(e) => { onChange(e) }} /></span>
                                <span className="">Search: <input type="text" className="btn btn-sm border border-primary" value={searchText} placeholder={t("Search Text...")} name="searchText" onChange={(e) => { onChange(e) }} /></span>
                                <button className="btn btn-sm btn-dark mr-5" onClick={() => { sortData("DESC") }}>Sort Sales DESC</button>
                                <button className="btn btn-sm btn-warning" onClick={() => { sortData("ASC") }}>Sort Sales ASC</button>
                                <ModalChangeText
                                    onSubmit={(data) => {
                                        handleChangeTitle(data)
                                    }}
                                />
                            </div>
                            <table className="table table-striped">
                                <thead>
                                    <tr className="card-title text-gray-800 fw-bold">
                                        <th scope="col">#</th>
                                        <th scope="col">Shop Name</th>
                                        <th scope="col">ID</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Sold Count</th>
                                        <th scope="col">Image</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataLink.map((v, k) => <><tr key={k} hidden={v?.sold_count >= minSale && v?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ? false : true} className={"border-bottom border-info"}>
                                        <td>
                                            <div className="d-flex justify-content-evenly">
                                                <input type="checkbox" value={v.id} onChange={(e) => { onChange(e) }} name="select_link" disabled={isSelected} style={{ width: "40px", height: "40px" }} />
                                                <button type="button" className="btn btn-sm btn-danger" onClick={() => { onDelete(v.id) }}><i class="ki-outline ki-tablet-delete fs-2" ></i></button>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                {v.shop_nickname}
                                            </div>
                                            <i>
                                                {v.shop_id} - {v.shop_unique_id}
                                            </i>
                                        </td>
                                        <td>{v.id}</td>
                                        <td><textarea type="text" rows={10} cols={50} name={"title_" + v.id} value={v.name} onChange={(e) => { onChange(e) }} >{v.name}</textarea></td>
                                        <td>{v?.sold_count}</td>
                                        <td>
                                            <div className={"d-flex border"}>
                                                <div className="">
                                                    <Link to={v.cover}><img className="img-responsive pd-5 border" target="_blank" alt={v.name} src={v.cover} width={300} /></Link>
                                                </div>
                                                <div className="">
                                                    {v?.image_others && <div className="d-flex align-items-center flex-wrap">
                                                        {v?.image_others?.map((o, j) => <div className="">
                                                            <Link target="_blank" to={v.cover}><img className="img-responsive pd-5 border" alt={v.name} src={o} width={100} /></Link>
                                                        </div>)}
                                                    </div>}
                                                </div>
                                                {/* <div className="col-lg-3 col-md-6">
                                                    <Link to={v.cover}><img className="img-responsive pd-5 border" alt={v.name} src={v.image2} width={300} /></Link>
                                                </div>
                                                <div className="col-lg-3 col-md-6">
                                                    <Link to={v.cover}><img className="img-responsive pd-5 border" alt={v.name} src={v.image3} width={300} /></Link>
                                                </div>
                                                <div className="col-lg-3 col-md-6">
                                                    <Link to={v.cover}><img className="img-responsive pd-5 border" alt={v.name} src={v.image4} width={300} /></Link>
                                                </div> */}
                                            </div>
                                        </td>
                                    </tr></>

                                    )}
                                </tbody>
                            </table>

                        </>}
                    </div>
                </div>

            </div>
        </Fragment>
    );
}

TikTokProduct.propTypes = {
    getProductTikTokv1: PropTypes.func.isRequired,
    getProductTemplate: PropTypes.func.isRequired,
    uploadTemplateByLink: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    productTemplate: state.productTemplate
});
const mapDispatchToProps = {
    getProductTikTokv1,
    getProductTemplate,
    uploadTemplateByLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(TikTokProduct);
