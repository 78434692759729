import { NumericFormat } from 'react-number-format';
import { t } from "i18next";
import cx from "classnames";

const formatterCreateAt = (time = 0, type = "full", tz = "Asia/Ho_Chi_Minh") => () => {
		if (!Date.parse(time)) time = +time;

		if (parseInt(time) > 0) {
			var d = new Date(time);

			if (tz) {
				d = convertTZ(d, tz);
			}
			var month = '' + (d.getMonth() + 1);
			var day = '' + d.getDate();
			var year = d.getFullYear();
			var hour = '' + (d.getHours());
			var min = '' + d.getMinutes();
			var sec = '' + d.getSeconds();

			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;
			if (hour.length < 2)
				hour = '0' + hour;
			if (min.length < 2)
				min = '0' + min;
			if (sec.length < 2)
				sec = '0' + sec;

			if (type === "dmy") {
				return [day, month, year].join('-');
			}
			return [day, month, year].join('-') + ' ' + [hour, min, sec].join(':');
		} else {
			return 'Updating';
		}

};

const convertTZ = (date, tzString = "Asia/Ho_Chi_Minh") => {
	return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
}

const getTZOffset = () => {
	var offset = new Date().getTimezoneOffset();
	var o = -offset;
	return Math.floor(o / 60);
}

const getRange2TZ = (utc_offset = 0) => {
	var tz = getTZOffset();
	// if (tz === 7) {
	return tz - utc_offset;
	// }
	// var start = 0;
	// var end = 0;
	// if (utc_offset > 7) {
	// 	start = 7;
	// 	end = utc_offset;
	// } else {
	// 	start = utc_offset;
	// 	end = 7;
	// }
	// var list = [];
	// for (var i = start; i <= end; i++) {
	// 	list.push(i);
	// }
	// var range = 0;
	// if (utc_offset > 7) {
	// 	range = -list.length;
	// } else {
	// 	range = list.length;
	// }
	// return range-utc_offset >= 24 ? range-utc_offset-24 : range;
}

const getTimezoneOffset = (atTime, timeZone) => {
	const localizedTime = new Date(atTime.toLocaleString("en-US", { timeZone }));
	const utcTime = new Date(atTime.toLocaleString("en-US", { timeZone: "UTC" }));
	var timezone_offset = Math.round((localizedTime.getTime() - utcTime.getTime()) / (60 * 1000));

	return Math.floor(timezone_offset / 60);
}

const FormatterStatus = (props) => () => {
	var { status, small = false, large = false } = props;

	if (status === "active" || status === 1 || status === true) {
		return <span className={cx("badge badge-light-success", { "badge-sm": small, "fs-xl": large })}>Active</span>;
	} else if (status === "ACTIVATE") {
		return <span className={cx("badge badge-light-success", { "badge-sm": small, "fs-xl": large })}>ACTIVATE</span>;
	} else if (status === "inactive" || status === 0 || status === false) {
		return <span className={cx("badge badge-light-warning", { "badge-sm": small, "fs-xl": large })}>Inactive</span>;
	} else if (status === "issue") {
		return <span className={cx("badge badge-light-danger", { "badge-sm": small, "fs-xl": large })}>Issue</span>;
	} else if (status === "FAILED") {
		return <span className={cx("badge badge-light-danger", { "badge-sm": small, "fs-xl": large })}>FAILED</span>;
	} else if (status === "SELLER_DEACTIVATED") {
		return <span className={cx("badge badge-light-danger", { "badge-sm": small, "fs-xl": large })}>SELLER_DEACTIVATED</span>;
	} else if (status === "PLATFORM_DEACTIVATED") {
		return <span className={cx("badge badge-light-danger", { "badge-sm": small, "fs-xl": large })}>PLATFORM_DEACTIVATED</span>;
	} else if (status === "DELETED" || status === "Deleted") {
		return <span className={cx("badge badge-light-danger", { "badge-sm": small, "fs-xl": large })}>DELETED</span>;
	} else if (status === "FREEZE") {
		return <span className={cx("badge badge-light-info", { "badge-sm": small, "fs-xl": large })}>FREEZE</span>;
	} else if (status === "draft") {
		return <span className={cx("badge badge-light-secondary", { "badge-sm": small, "fs-xl": large })}>Draft</span>;
	} else if (status === "DRAFT") {
		return <span className={cx("badge badge-light-secondary", { "badge-sm": small, "fs-xl": large })}>DRAFT</span>;
	} else if (status === "pending") {
		return <span className={cx("badge badge-light-warning", { "badge-sm": small, "fs-xl": large })}>Pending</span>;
	} else if (status === "PENDING") {
		return <span className={cx("badge badge-light-warning", { "badge-sm": small, "fs-xl": large })}>PENDING</span>;
	} else if (status === "processing") {
		return <span className={cx("badge badge-light-warning", { "badge-sm": small, "fs-xl": large })}>Processing</span>;
	} else if (status === "approved") {
		return <span className={cx("badge badge-light-primary", { "badge-sm": small, "fs-xl": large })}>Approved</span>;
	} else if (status === "cancel") {
		return <span className={cx("badge badge-light-danger", { "badge-sm": small, "fs-xl": large })}>Cancel</span>;
	} else if (status === "unfulfilled") {
		return <span className={cx("badge badge-light-secondary", { "badge-sm": small, "fs-xl": large })}>Unfulfilled</span>;
	} else if (status === "on_delivery") {
		return <span className={cx("badge badge-light-success", { "badge-sm": small, "fs-xl": large })}>On Delivery</span>;
	} else if (status === "completed") {
		return <span className={cx("badge badge-light-success", { "badge-sm": small, "fs-xl": large })}>Completed</span>;
	} else if (status === "paid") {
		return <span className={cx("badge badge-light-success", { "badge-sm": small, "fs-xl": large })}>Paid</span>;
	} else if (status === "unpaid") {
		return <span className={cx("badge badge-light-secondary", { "badge-sm": small, "fs-xl": large })}>Unpaid</span>;
	} else if (status === "custom") {
		return <span className={cx("badge badge-light-info", { "badge-sm": small, "fs-xl": large })}>Custom</span>;
	} else if (status === "processing_fulfill") {
		return <span className={cx("badge badge-light-warning", { "badge-sm": small, "fs-xl": large })}>Processing Fulfill</span>;
	} else if (status === "on_hold") {
		return <span className={cx("badge badge-light-danger", { "badge-sm": small, "fs-xl": large })}>On Hold</span>;
	} else if (status === "refunded") {
		return <span className={cx("badge badge-light-danger", { "badge-sm": small, "fs-xl": large })}>Refunded</span>;
	} else if (status === "fulfilled") {
		return <span className={cx("badge badge-light-success", { "badge-sm": small, "fs-xl": large })}>Fulfilled</span>;
	} else if (status === "partially_refunded") {
		return <span className={cx("badge badge-light-warning", { "badge-sm": small, "fs-xl": large })}>Partially Refunded</span>;
	} else if (status === "maintaince") {
		return <span className={cx("badge badge-light-danger", { "badge-sm": small, "fs-xl": large })}>Maintaince</span>;
	} else if (status === "show") {
		return <span className={cx("badge badge-light-success", { "badge-sm": small, "fs-xl": large })}>show</span>;
	} else if (status === "hide") {
		return <span className={cx("badge badge-light-secondary", { "badge-sm": small, "fs-xl": large })}>hide</span>;
	} else if (status === "urgent") {
		return <span className={cx("badge badge-light-danger", { "badge-sm": small, "fs-xl": large })}>URGENT</span>;
	} else if (status === "partially_paid") {
		return <span className={cx("badge badge-light-secondary", { "badge-sm": small, "fs-xl": large })}>Partially Paid</span>;
	} else if (status === "hold") {
		return <span className={cx("badge badge-light-warning", { "badge-sm": small, "fs-xl": large })}>Hold</span>;
	} else if (status === true) {
		return <span className={cx("badge badge-light-success", { "badge-sm": small, "fs-xl": large })}>TRUE</span>;
	} else if (status === false) {
		return <span className={cx("badge badge-light-danger", { "badge-sm": small, "fs-xl": large })}>FALSE</span>;
	} else if (status === "Connected") {
		return <span className={cx("badge badge-light-success", { "badge-sm": small, "fs-xl": large })}>Connected</span>;
	} else if (status === "Disconnected") {
		return <span className={cx("badge badge-light-danger", { "badge-sm": small, "fs-xl": large })}>Disconnected</span>;
	} else if (status === "new") {
		return <span className={cx("badge badge-light-primary", { "badge-sm": small, "fs-xl": large })}>New</span>;
	} else if (status === "working") {
		return <span className={cx("badge badge-light-info", { "badge-sm": small, "fs-xl": large })}>Working</span>;
	} else if (status === "worked") {
		return <span className={cx("badge badge-light-success", { "badge-sm": small, "fs-xl": large })}>Worked</span>;
	} else {
		return "";
	}
}

const formatterImage = (url = "") => () => {
	if (url.includes("drive.google.com") && url.includes('/d/')){
		var new_url = `https://drive.google.com/thumbnail?id=${url.split('/d/')?.[1]?.split('/')[0]}`;
		return new_url;
	}else{
		return url;
	}

};
const FormatterUsed = (props) => () => {
	var { status, small = false, large = false } = props;

	if (status === true) {
		return <span className={cx("badge badge-light-success", { "badge-sm": small, "fs-xl": large })}>TRUE</span>;
	} else if (status === false) {
		return <span className={cx("badge badge-light-danger", { "badge-sm": small, "fs-xl": large })}>FALSE</span>;
	} else {
		return "";
	}
}

const isEmptyObject = (obj) => () => {
	try {
		for (var key in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, key)) {
				return false;
			}
		}
		return true;
	} catch (error) {
		return false;
	}
};

const splitVariants = (obj) => () => {
	try {
		return Object.entries(obj).reduce((r, [k, v]) => {
			var temp = [];
			r.forEach(s =>
				(Array.isArray(v) ? v : [v]).forEach(w =>
					(w && typeof w === 'object' ? splitVariants(w)() : [w]).forEach(x =>
						temp.push(Object.assign({}, s, { [k]: x }))
					)
				)
			);
			return temp;
		}, [{}]);
	} catch (error) {
		return false;
	}
};

const convertKeyValuePair = (arr, media, price, quantity) => () => {
	try {
		var arr_tmp = [];
		return arr.reduce(function (r, e, i) {
			let obj = {};
			for (const [key, value] of Object.entries(e)) { // eslint-disable-line
				obj = {
					...obj,
					[value["key"]]: value["value"],
				};
			}
			arr_tmp.push({
				"_id": i,
				"thumbnail": media[0] || "",
				...obj,
				// "sku": "sku_"+(i+1),
				// "base_cost": 0,
				"price": 0,
				"quantity": 0,
				// "compare_price": 0,
				// "out_of_stock": false,
				// currency_code
			});
			return arr_tmp;
		}, {});
	} catch (error) {
		return false;
	}
};

const FormatterMoney = (props) => {
	var { money = 0, prefix = "$", suffix = "", currency = "", currency_to = "", style = {}, decimalScale = 2, is_split_100 = true } = props;
	var exchange_price = money;

	if (is_split_100 && !["", "CLP", "COP", "CRC", "HUF", "ISK", "IDR", "JPY", "KRW", "PYG", "TWD", "VND"].includes(currency)) {
		exchange_price = exchange_price / 100;
	}

	if (currency_to !== "") {
		if (getCookie("ziads_exchange_rate")()) {
			var exchange_rate = JSON.parse(getCookie("ziads_exchange_rate")());
			exchange_price = exchange_price / exchange_rate[currency];
		}
	}

	return (
		<NumericFormat value={exchange_price} displayType={'text'} thousandSeparator={true} prefix={prefix} suffix={" " + suffix} style={style} decimalScale={decimalScale} />
	);
};

const isNumeric = (n) => {
	return !isNaN(parseFloat(n)) && isFinite(n);
}

const subStringText = (data, length = 45) => () => {
	return data?.length > length ? data.slice(0, length - 1) + "…" : data;
}

const sleep = (ms) => {
	return new Promise((res) => setTimeout(res, ms));
}

const getDatesInRange = (dateStart, dateEnd) => () => {
	const date = new Date(dateStart);

	const dates = [];

	while (date <= dateEnd) {
		dates.push(formatterCreateAt((new Date(date)).getTime(), "dmy")());
		date.setDate(date.getDate() + 1);
	}

	return dates;
}

const cleanObject = (obj) => () => {
	for (var propName in obj) {
		if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
			delete obj[propName];
		}
	}
	return obj;
};

const setCookie = (name, value, days) => () => {
	var expires = "";
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

const getCookie = (name) => () => {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

const eraseCookie = (name) => () => {
	document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const getDiffDays = (dateStart, dateEnd) => () => {
	const oneDay = 24 * 60 * 60 * 1000;
	const diffDays = Math.floor(Math.abs((dateStart - dateEnd) / oneDay));

	return diffDays;
};

const getStatusAcc = (num) => () => {
	let astatus = ""
	switch (num) {
		case 1:
			astatus = t("Active")
			break ///active
		case 2:
			astatus = t("Disable")
			break //disabled
		case 3:
			astatus = t("Need pay")
			break
		case 7:
			astatus = t("Pending Review") //PENDING_RISK_REVIEW
			break
		case 8:
			astatus = t("Pending Settlement")
			break
		case 9:
			astatus = t("IN_GRACE_PERIOD In") //IN_GRACE_PERIOD In
			break
		case 100:
			astatus = t("Pending Closure")
			break
		case 101:
			astatus = t("Close")
			break
		case 201:
			astatus = t("Any Active")
			break
		case 202:
			astatus = t("Any Close")
			break
		case "active":
			astatus = t("Any Close")
			break
		default:
			astatus = t("Active")
			break
	}
	return astatus
}

function checkImageURL(url) {
	return (url.match(/\.(jpeg|jpg|gif|png|webp)$/) != null);
}

const arrayHierarchy = (ar) => {
	const mapped = ar.reduce((acc, item) => {
		acc[item.id] = item;
		return acc;
	}, {});

	const result = ar.reduce((acc, item) => {
		const parentId = item.parent_id;
		if (!parentId || parentId === "0") return [...acc, item];

		mapped[parentId].children = mapped[parentId].children || [];

		mapped[parentId].children.push({ ...item, value: item.id, label: item.local_name });

		return acc;
	}, []);

	return result;
};

function getKeyByValue(object, value) {
	return Object.keys(object).find((key) => object[key] === value);
}

const permissionComponent = ({ user, permission, component, type = "hidden" }) => {
	if (user.role !== 2) return component;

	if (!user?.teamPermission?.includes(permission)) {
		if (type !== "hidden") {
			return (
				<div className="alert mb-0 alert-danger">
					<strong>Oops!</strong> You don't have permission to view this page.
				</div>
			);
		}

		return <></>;
	}

	return component;
};

const getDaysArray = function (start, end) {
    const arr = [];
    for (const dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt).toLocaleDateString("en-CA"));
    }
    return arr;
};

export {
	formatterCreateAt,
	FormatterStatus,
	isEmptyObject,
	FormatterMoney,
	splitVariants,
	convertKeyValuePair,
	isNumeric,
	subStringText,
	sleep,
	getDatesInRange,
	cleanObject,
	convertTZ,
	getRange2TZ,
	setCookie,
	getCookie,
	eraseCookie,
	getDiffDays,
	getTimezoneOffset,
	getStatusAcc,
	checkImageURL,
	arrayHierarchy,
	FormatterUsed,
	getKeyByValue,
	permissionComponent,
	getDaysArray,
	formatterImage,
}