import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { TeamRole } from "helper/constant";

const EditStaff = ({ onSubmit, onClose, team }) => {
    const [formData, setFormData] = useState({ role: TeamRole.Staff });
    const { t } = useTranslation();

    useEffect(() => {
        if (team) setFormData(team);
    }, [team]);

    const handleSubmit = async () => {
        const result = await onSubmit(formData);
        if (result) onClose();
    };

    const handleChangeForm = (e) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    return (
        <>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    onClose();
                }}
                isOpen={!!team}
            >
                {/* <Loading isLoading={isLoading} /> */}
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Create staff")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Role")}</span>
                        </label>
                        <select
                            className="form-select"
                            data-control="select2"
                            data-hide-search="true"
                            data-placeholder="Select a role"
                            name="role"
                            onChange={handleChangeForm}
                        >
                            {Object.keys(TeamRole).map((r, k) => (
                                <option key={k} value={TeamRole[r]}>{r}</option>
                            ))}
                        </select>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditStaff);
