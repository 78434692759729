import React from "react";

const InputFile = ({ children, onChange }) => {
    const inputFileRef = React.useRef();

    const onFileChangeCapture = (e) => {
        onChange(e.target.files);
    };

    const onBtnClick = () => {
        inputFileRef.current.click();
    };

    return (
        <>
            <input
                multiple="multiple"
                className="d-none"
                type="file"
                ref={inputFileRef}
                onChangeCapture={onFileChangeCapture}
            />
            {React.cloneElement(children, { onClick: onBtnClick })}
        </>
    );
};

export default InputFile;
