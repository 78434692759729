const initialState = {
	isLoading: false,
	isTogglemenumobile: false,
	isTogglemenuuser: false,
    config: null,
    socket_id: ""
};

function websiteReducer(state = initialState, action) {
	switch (action.type) {
		case 'LOADING_WEBSITE':
			return {
				...state,
				isLoading: action.payload
			};
		case 'TOGGLE_MENU_MOBILE':
			return {
				...state,
				isTogglemenumobile: !state.isTogglemenumobile
			};
		case 'TOGGLE_MENU_USER':
			return {
				...state,
				isTogglemenuuser: action.payload
			};
		case "SET_APP_CONFIG":
			return {
				...state,
				config: action?.payload || null,
			};
		case 'SOCKET_ID':
			return {
				...state,
				socket_id: action.payload
			};
		default:
		  	return state;
	}
}

export default websiteReducer;