import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { addPayment } from "actions/paymentActions";
import Loading from "components/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";

function ModalAddPayment(props) {
    const [name, setName] = useState("");
    const [clientId, setClientID] = useState("");
    const [secret, setSecret] = useState("");
    const [address, setAddress] = useState("");
    const [merchant_id, setMerchantID] = useState("");
    var { isLoading } = props.payment;
    var { isOpenModalAddPayment, openModal, addPayment } = props;
    const { t } = useTranslation();

    const onClickSaveAddPayment = () => {
        addPayment({ name, clientId, secret, address, merchant_id }, openModal);
    };

    const onChange = (e) => {
        if (e.target.name === "name") {
            setName(e.target.value)
        }
        if (e.target.name === "clientId") {
            setClientID(e.target.value);
        }
        if (e.target.name === "secret") {
            setSecret(e.target.value);
        }
        if (e.target.name === "address") {
            setAddress(e.target.value);
        }
        if (e.target.name === "merchant_id") {
            setMerchantID(e.target.value);
        }
    }

    const onOpenModal = () => {
        openModal();
    };

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => { onOpenModal() }} isOpen={isOpenModalAddPayment}>
                <Loading isLoading={isLoading} />
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Add App")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onOpenModal() }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Name")}</span>
                        </label>
                        <input type="text" className="form-control" value={name} placeholder={t("Name")} name="name" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Client ID")}</span>
                        </label>
                        <input type="text" className="form-control" value={clientId} placeholder={t("App ID")} name="clientId" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Secret")}</span>
                        </label>
                        <input type="text" className="form-control" value={secret} placeholder={t("Secret")} name="secret" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Address")}</span>
                        </label>
                        <input type="text" className="form-control" value={address} placeholder={t("Address")} name="address" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Merchant ID")}</span>
                        </label>
                        <input type="text" className="form-control" value={merchant_id} placeholder={t("Merchant ID")} name="merchant_id" onChange={(e) => { onChange(e) }} />
                    </div>                    
                </ModalBody>
                <ModalFooter>
                    <button type="reset" id="kt_modal_users_search_reset" data-bs-dismiss="modal" className="btn btn-active-light me-3" onClick={() => { onOpenModal() }} >{t("Cancel")}</button>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveAddPayment() }}  >{t("Save")}</button>
                </ModalFooter>
            </Modal>


        </Fragment>
    );
}

ModalAddPayment.propTypes = {
    addPayment: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    payment: state.payment
});
const mapDispatchToProps = {
    addPayment
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddPayment);