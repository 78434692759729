import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { getShops } from "actions/shopActions";

function ModalUpload({ onSubmit, getShops }) {
    const [shops, setShops] = useState([]);
    const [data, setData] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        getShops({ sizeperpage: 100 }).then((d) => setShops(d?.shops || []));
    }, [getShops]);

    const onClose = () => {
        setIsOpen(false);
    };

    const handleSubmit = () => {
        onSubmit(data);
        onClose();
    };

    return (
        <Fragment>
            <button
                className="btn btn-sm btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_create_app"
                id="kt_toolbar_primary_button"
                onClick={() => setIsOpen(true)}
            >
                <i className="ki-outline ki-cloud-download"></i>
                {t("Upload Product")}
            </button>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    onClose();
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Upload product to tiktok")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="form-group">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Shop")}</span>
                        </label>
                        <div>
                            <select
                                className="form-select"
                                data-control="select2"
                                data-hide-search="true"
                                data-placeholder="Select a shop"
                                onChange={(e) => setData(e.target.value)}
                            >
                                <option value="">{t("-- Select a shop --")}</option>
                                {shops.map((s, k) => <option key={k} value={s._id}>{s.name}</option>)}
                            </select>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { getShops };

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpload);
