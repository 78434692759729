import { connect } from "react-redux";
import Analytic from "./analytic";
import Main from "./Main";

function Home(props) {
    const { role } = props.user.user;

    return role !== 2 ? <Analytic /> : <Main />;
}

const mapStateToProps = (state) => ({
    user: state.user,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
