const initialState = {
	isLoading: false,
	list: [],
	totalsize: 0
};

function teamReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_TEAM':
			return {
				...state,
				isLoading: action?.payload
			};		
		case 'ADMIN_LIST_TEAM':
			return {
				...state,
				list: action?.payload?.data || [],
				totalsize: action?.payload?.totalsize || 0
			};
		default:
		  return state;
    }
}

export default teamReducer;
