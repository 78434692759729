const initialState = {
	isLoading: false,
	notification_order: {},
	notification_fulfill: {},
	notification_shipping: {},
	notification_return_order: {},
};

function emailReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_NOTIFICATION':
			return {
				...state,
				isLoading: action?.payload
			};	
		case 'ADMIN_NOTIFICATION':
			return {
				...state,
				notification_order: action?.payload?.notification_order || {},
				notification_fulfill: action?.payload?.notification_fulfill || {},
				notification_shipping: action?.payload?.notification_shipping || {},
				notification_return_order: action?.payload?.notification_return_order || {},
			};
		default:
		  return state;
    }
}

export default emailReducer;
