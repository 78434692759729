import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";


function ModalChangeText({ onSubmit }) {
    const [isOpen, setIsOpen] = useState(false);
    const [textOld, setTextOld] = useState("");
    const [textNew, setTextNew] = useState("");
    const [textStart, setTextStart] = useState("");
    const [textEnd, setTextEnd] = useState("");
    const { t } = useTranslation();

    const onChange = (e) => {
        if (e.target.name === "textOld") {
            setTextOld(e.target.value);
        }
        if (e.target.name === "textNew") {
            setTextNew(e.target.value);
        }
        if (e.target.name === "textStart") {
            setTextStart(e.target.value);
        }
        if (e.target.name === "textEnd") {
            setTextEnd(e.target.value);
        }
    }

    const handleSubmit = () => {
        onSubmit({ textOld, textNew, textStart, textEnd });
        setIsOpen(false);
    };

    return (
        <Fragment>
            <button onClick={() => setIsOpen(true)} type="button" className="btn btn-primary btn-sm mr-1">
                <i className="fa fa-cloud-upload mr-1"></i> {t("Change Title All")}
            </button>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    setIsOpen(!isOpen);
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h2 className="mb-0 text-info">Change All Title Data</h2>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="form-group">
                        <label>Text Old</label>
                        <div>
                            <input type="text" className="form-control" value={textOld} placeholder={t("1")} name="textOld" onChange={(e) => { onChange(e) }} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Change To</label>
                        <div>
                            <input type="text" className="form-control" value={textNew} placeholder={t("1")} name="textNew" onChange={(e) => { onChange(e) }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Merge Start</label>
                                <div>
                                    <input type="text" className="form-control" value={textStart} placeholder={t("")} name="textStart" onChange={(e) => { onChange(e) }} />
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-6">
                            <div className="form-group">
                                <label>Merge End</label>
                                <div>
                                    <input type="text" className="form-control" value={textEnd} placeholder={t("")} name="textEnd" onChange={(e) => { onChange(e) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Start Change")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalChangeText);
