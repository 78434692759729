import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

function ModalUpdateParent(props) {
    const [formData, setFormData] = useState({ type: "add" });
    var { isLoading } = props.user;
    var { userId, onClose, onSubmit } = props;
    const { t } = useTranslation();

    const onChange = (e) => {
        let value = e.target.value;
        if (e.target.name === "price") value = Math.abs(+value) || 0;
        setFormData((prev) => ({ ...prev, [e.target.name]: value }));
    };

    const handleSubmit = async () => {
        await onSubmit({ ...formData, userId });
        onClose();
    };

    return (
        <Fragment>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    onClose();
                }}
                isOpen={!!userId}
            >
                <Loading isLoading={isLoading} />
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Add User")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Type")}</span>
                        </label>
                        <select
                            className="form-select"
                            data-control="select2"
                            data-hide-search="true"
                            data-placeholder="currency"
                            name="type"
                            onChange={onChange}
                            value={formData.type}
                        >
                            <option value="add">Add</option>
                            <option value="subtract">Subtract</option>
                        </select>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Amount")}</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData?.price}
                            placeholder={t("Amount")}
                            name="amount"
                            onChange={onChange}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Description")}</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData?.description || ""}
                            placeholder={t("Description")}
                            name="description"
                            onChange={onChange}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    user: state.user,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpdateParent);
