import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";

const getListPrinters = (page = 0, sizeperpage = 0, search = "") => (dispatch, getState) => {
	dispatch({type: 'LOADING_PRINTER', payload: true});
	var param_search = "";
	if (search !== "") {
		param_search = `&search=${search}`;
	}
	axios.get(`/api/printer/list?page=${page}&sizeperpage=${sizeperpage}${param_search}`, tokenConfig(getState)).then(res => {
		dispatch({type: 'LOADING_PRINTER', payload: false});
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_PRINTER',
				payload: res.data.data
			});
		} else {
			Swal.fire(t("Error"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({type: 'LOADING_PRINTER', payload: false});
		Swal.fire(t("Error"), t("An error occurred!"), 'error');
	});
};

const saveAddPrinter = (data, closemodal) => (dispatch, getState) => {
	var body = {
		printer: data.printer,
		username: data.username,
		password: data.password,
		access_token: data.access_token,
		auto_place: data.auto_place,
		api_key: data.api_key,
		api_signature: data.api_signature,
	};
	Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to add printer?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_PRINTER', payload: true});
			axios.post('/api/printer/add', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_PRINTER', payload: false});
				if (res.data.status === 200) {
					closemodal();
					dispatch(getListPrinters(1, 50));
					Swal.fire(t("Success"), t(res.data.message), 'success');
				} else {
                    Swal.fire(t("Error"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_PRINTER', payload: false});
                Swal.fire(t("Error"), t("An error occurred!"), 'error');
			});
		}
	})
};

const deletePrinter = (data) => (dispatch, getState) => {
	var body = {
		_id: data._id
	};
	Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to delete printer?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_PRINTER', payload: true});
			axios.post('/api/printer/delete', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_PRINTER', payload: false});
				if (res.data.status === 200) {
					dispatch(getListPrinters(data.page, data.sizePerPage, data.searchText));
					Swal.fire(t("Success"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Error"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_PRINTER', payload: false});
                Swal.fire(t("Error"), t("An error occurred!"), 'error');
			});
		}
	})
};

export {
    getListPrinters,
    saveAddPrinter,
    deletePrinter,
};
