const initialState = {
	isLoading: false,
	email: {},
};

function emailReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_EMAIL':
			return {
				...state,
				isLoading: action?.payload
			};	
		case 'ADMIN_EMAIL':
			return {
				...state,
				email: action?.payload?.email || {},
			};
		default:
		  return state;
    }
}

export default emailReducer;
