import { Fragment } from "react";
import { connect } from "react-redux";
// import { useTranslation } from "react-i18next";
import Listorders from "./listorders/Listorders";
import Loading from "components/Loading";

function Main(props) {
    var { isLoading } = props.order;

    return (
        <Fragment>
            <Loading isLoading={isLoading} />
            <Listorders />
        </Fragment>
    );
}

Main.propTypes = {
};
const mapStateToProps = state => ({
    order: state.order
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);