const initialState = {
	isLoading: false,
	payment: checkObject(localStorage.getItem("payment")),
	listpayments: [],
	totalsize: 0
};

function checkObject(obj) {
    try {
        return JSON.parse(obj);
    } catch (e) {
        return {};
    }
}

function paymentReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_PAYMENT':
			return {
				...state,
				isLoading: action?.payload
			};
		case 'PAYMENT_LOADED':
			localStorage.setItem("payment", JSON.stringify(action.payload.payment));
			return {
				...state,
				payment: action?.payload?.payment
			};
		case 'ADMIN_LIST_PAYMENT':
			return {
				...state,
				listpayments: action?.payload?.listpayments || [],
				totalsize: action?.payload?.totalsize || 0
			};
		default:
		  return state;
    }
}

export default paymentReducer;
