import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { getListOrders } from "./orderActions";
import { t } from "i18next";

const getBuyLabel = (data) => (dispatch, getState) => {
	var body = {
		_id: data?._id,
		type: data?.type,
		dimension_length: data?.dimension_length,
		dimension_width: data?.dimension_width,
		dimension_height: data?.dimension_height,
		dimension_unit: data?.dimension_unit,
		weight_value: data?.weight_value,
		weight_unit: data?.weight_unit,
	};
	Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to buy label?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_ORDER', payload: true});
			axios.post('/api/fulfillment/buy-label', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_ORDER', payload: false});
				if (res.data.status === 200) {
					dispatch(getListOrders(data.page, data.sizePerPage, data.searchText));
					Swal.fire(t("Success"), t(res.data.message), 'success');
				} else {
                    Swal.fire(t("Error"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_ORDER', payload: false});
                Swal.fire(t("Error"), t("An error occurred!"), 'error');
			});
		}
	})
};

export {
    getBuyLabel
};
