const AppRegions = {
    vi: "VN",
    us: "US",
};

const TeamRole = {
    Staff: "staff",
    Fulfill: "fulfill",
};

const ListDateType = [
    { "label": "Alltime", "value": "" },
    { "label": "Today", "value": "today" },
    { "label": "Yesterday", "value": "yesterday" },
    { "label": "Last week", "value": "last_week" },
    { "label": "Last month", "value": "last_month" },
    { "label": "Last year", "value": "last_year" },
    { "label": "This week", "value": "this_week" },
    { "label": "This month", "value": "this_month" },
    { "label": "This year", "value": "this_year" },
    { "label": "Custom", "value": "custom" }
];

export { AppRegions, ListDateType, TeamRole };
