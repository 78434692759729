const initialState = {
	isLoading: false,
	listprinters: [],
	totalsize: 0,
};

function printerReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_PRINTER':
			return {
				...state,
				isLoading: action.payload
			};
		case 'LIST_PRINTER':
			return {
				...state,
				listprinters: action.payload.listprinters,
				totalsize: action.payload.totalsize
			};
		default:
		  return state;
    }
}

export default printerReducer;
