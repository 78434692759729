import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { getNotification, saveNotification, testNotification } from "actions/notificationActions";
import { isEmptyObject } from "components/Function";

function Notification(props) {
    const { t } = useTranslation();
    const { isEmptyObject, getNotification, saveNotification, testNotification } = props;
    var { notification_order, notification_fulfill, notification_shipping, notification_return_order } = props.notification;

    const [order_chat_id, setOrderChatId] = useState("");
    const [order_message, setOrderMessage] = useState("");

    const [fulfill_chat_id, setFulfillChatId] = useState("");
    const [fulfill_message, setFulfillMessage] = useState("");

    const [shipping_chat_id, setShippingChatId] = useState("");
    const [shipping_message, setShippingMessage] = useState("");

    const [return_order_chat_id, setReturnOrderChatId] = useState("");
    const [return_order_message, setReturnOrderMessage] = useState("");

    useEffect(() => {
        getNotification();
    }, [getNotification]);

    useEffect(() => {
        if (!isEmptyObject(notification_order)) {
            setOrderChatId(notification_order?.chat_id || '');
            setOrderMessage(notification_order?.message || '');
        }
    }, [isEmptyObject, notification_order]);

    useEffect(() => {
        if (!isEmptyObject(notification_fulfill)) {
            setFulfillChatId(notification_fulfill?.chat_id || '');
            setFulfillMessage(notification_fulfill?.message || '');
        }
    }, [isEmptyObject, notification_fulfill]);

    useEffect(() => {
        if (!isEmptyObject(notification_shipping)) {
            setShippingChatId(notification_shipping?.chat_id || '');
            setShippingMessage(notification_shipping?.message || '');
        }
    }, [isEmptyObject, notification_shipping]);
    useEffect(() => {
        if (!isEmptyObject(notification_return_order)) {
            setReturnOrderChatId(notification_return_order?.chat_id || '');
            setReturnOrderMessage(notification_return_order?.message || '');
        }
    }, [isEmptyObject, notification_return_order]);

    const onClickSaveEdit = (id, type, chat_id, message) => {
        saveNotification({ _id: id, type: type, chat_id, message });
    };

    const onClickTest = (chat_id, message) => {
        testNotification({ chat_id, message });
    };

    const onChange = (e) => {
        if (e.target.name === "order_chat_id") {
            setOrderChatId(e.target.value);
        }
        if (e.target.name === "order_message") {
            setOrderMessage(e.target.value);
        }
        if (e.target.name === "fulfill_chat_id") {
            setFulfillChatId(e.target.value);
        }
        if (e.target.name === "fulfill_message") {
            setFulfillMessage(e.target.value);
        }
        if (e.target.name === "shipping_chat_id") {
            setShippingChatId(e.target.value);
        }
        if (e.target.name === "shipping_message") {
            setShippingMessage(e.target.value);
        }
        if (e.target.name === "return_order_chat_id") {
            setReturnOrderChatId(e.target.value);
        }
        if (e.target.name === "return_order_message") {
            setReturnOrderMessage(e.target.value);
        }
    }


    return (
        <>
            <div className="card mb-5 mb-xl-10">
                <h1 className="mb-5 mt-5 text-dark text-center">{t("Only allows via Telegram")}</h1>
                <div className="card-body">
                    <div className="row">
                        <div className="col col-lg-6 col-md-12">
                            <h3>How to use?</h3>
                            Step 1: Create your group on Telegram Web<br />
                            Step 2: Invite bot <b>@CoTik_App_Bot</b> to your group<br />
                            Step 3: Click <b>"Test"</b> below to check if the bot is working or not?
                        </div>
                        <div className="col col-lg-6 col-md-12">
                            <h3>Content code</h3>
                            <p className="fs-6">{"{SHOP_NAME} - Shop name"}</p>
                            <p className="fs-6">{"{SHOP_ID} - Shop id"}</p>
                            <p className="fs-6">{"{CEN_SHOP_NAME} - Shop name but censored with ****"}</p>
                            <p className="fs-6">{"{CEN_SHOP_ID} - Shop id but censored with ****"}</p>
                            <p className="fs-6">{"{AMOUNT} - Order total amount"}</p>
                            <p className="fs-6">{"{QUANTITY} - Order items"}</p>
                            <p className="fs-6">{"{ORDER_ID} - Order ID"}</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row">
                <div className="card card-body col-lg-4 mb-5">
                    <h1 className="mb-5 mt-5 text-dark text-center">{t("Order Message")}</h1>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Telegram Channel / Group ID")}</span>
                        </label>
                        <input type="text" className="form-control" name="order_chat_id" value={order_chat_id} placeholder="-1001123456789" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span>{t("Message")}</span>
                        </label>
                        <textarea rows={10} className="form-control" name="order_message" value={order_message} onChange={(e) => { onChange(e) }} ></textarea>
                    </div>
                    <div className="d-flex justify-content-evenly">
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-info" onClick={() => { onClickTest(order_chat_id, order_message) }}  >{t("Test")}</button>
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveEdit(notification_order?._id, "order", order_chat_id, order_message) }}  >{t("SAVE")}</button>
                    </div>
                </div>
                <div className="card card-body col-lg-4 mb-5">
                    <h1 className="mb-5 mt-5 text-dark text-center">{t("Fulfill Message")}</h1>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Telegram Channel / Group ID")}</span>
                        </label>
                        <input type="text" className="form-control" name="fulfill_chat_id" value={fulfill_chat_id} placeholder="-1001123456789" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span>{t("Message")}</span>
                        </label>
                        <textarea rows={10} className="form-control" name="fulfill_message" value={fulfill_message} onChange={(e) => { onChange(e) }} ></textarea>
                    </div>
                    <div className="d-flex justify-content-evenly">
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-info" onClick={() => { onClickTest(fulfill_chat_id, fulfill_message) }}  >{t("Test")}</button>
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveEdit(notification_fulfill?._id, "fulfill", fulfill_chat_id, fulfill_message) }}  >{t("SAVE")}</button>
                    </div>
                </div>
                <div className="card card-body col-lg-4 mb-5">
                    <h1 className="mb-5 mt-5 text-dark text-center">{t("Shipping Message")}</h1>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Telegram Channel / Group ID")}</span>
                        </label>
                        <input type="text" className="form-control" name="shipping_chat_id" value={shipping_chat_id} placeholder="-1001123456789" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span>{t("Message")}</span>
                        </label>
                        <textarea rows={10} className="form-control" name="shipping_message" value={shipping_message} onChange={(e) => { onChange(e) }} ></textarea>
                    </div>
                    <div className="d-flex justify-content-evenly">
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-info" onClick={() => { onClickTest(shipping_chat_id, shipping_message) }}  >{t("Test")}</button>
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveEdit(notification_shipping?._id, "shipping", shipping_chat_id, shipping_message) }}  >{t("SAVE")}</button>
                    </div>
                </div>
                <div className="card card-body col-lg-4 mb-5">
                    <h1 className="mb-5 mt-5 text-dark text-center">{t("Return Order Message")}</h1>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Telegram Channel / Group ID")}</span>
                        </label>
                        <input type="text" className="form-control" name="return_order_chat_id" value={return_order_chat_id} placeholder="-1001123456789" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span>{t("Message")}</span>
                        </label>
                        <textarea rows={10} className="form-control" name="return_order_message" value={return_order_message} onChange={(e) => { onChange(e) }} ></textarea>
                    </div>
                    <div className="d-flex justify-content-evenly">
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-info" onClick={() => { onClickTest(return_order_chat_id, return_order_message) }}  >{t("Test")}</button>
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveEdit(notification_return_order?._id, "returnorders", return_order_chat_id, return_order_message) }}  >{t("SAVE")}</button>
                    </div>
                </div>
            </div>
        </>
    );
};


Notification.propTypes = {
    isEmptyObject: PropTypes.func.isRequired,
    saveNotification: PropTypes.func.isRequired,
    getNotification: PropTypes.func.isRequired,
    testNotification: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    notification: state.notification,
});
const mapDispatchToProps = {
    isEmptyObject,
    saveNotification,
    getNotification,
    testNotification,
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
