import { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { updatePayment } from "actions/paymentActions";

const ModalEditApp = ({ data, onClose, onSubmit, updatePayment }) => {
    const [formData, setFormData] = useState({ ...data });
    const { t } = useTranslation();
    useEffect(() => {
        setFormData(data);
    }, [data]);

    const handleChange = (e) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async () => {
        let d = formData;
        await updatePayment(d);
        onSubmit?.();
        onClose?.();
    };

    return (
        <Fragment>
            <Modal centered={true} size="xl" toggle={onClose} isOpen={!!data}>
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Edit App")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onClose(); }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Name")}</span>
                        </label>
                        <input type="text" className="form-control" value={formData?.name || ""} placeholder={t("Name")} name="name" onChange={handleChange} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Client ID")}</span>
                        </label>
                        <input type="text" className="form-control" value={formData?.clientId || ""} placeholder={t("App ID")} name="clientId" onChange={handleChange} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Secret")}</span>
                        </label>
                        <input type="text" className="form-control" value={formData?.secret || ""} placeholder={t("Secret")} name="secret" onChange={handleChange} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Address")}</span>
                        </label>
                        <input type="text" className="form-control" value={formData?.address || ""} placeholder={t("Address")} name="address" onChange={handleChange} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Merchant ID")}</span>
                        </label>
                        <input type="text" className="form-control" value={formData?.merchant_id || ""} placeholder={t("Merchant ID")} name="merchant_id" onChange={handleChange} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Status")}</span>
                        </label>
                        <select className="form-select" data-control="select2" data-hide-search="true" data-placeholder="Status" value={formData?.status || "inactive"} name="status" onChange={handleChange} >
                            <option value="inactive">Inactive</option>
                            <option value="active">Active</option>
                        </select>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={handleSubmit}  >{t("Save")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({ payment: state.payment });

export default connect(mapStateToProps, { updatePayment })(ModalEditApp);
