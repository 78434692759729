import { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import _ from "lodash";
import { getAppRandomByRegion } from "actions/appActions";

const AddShop = ({ getAppRandomByRegion }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [region, setRegion] = useState("");
    const { t } = useTranslation();
    const handleCreateApp = () => {
        if (!region) {
            return Swal.fire(t("Error"), t("Region is require"), "error");
        }

        getAppRandomByRegion(region).then((data) => {
            if (!_.isString(data.data)) return Swal.fire(t("Error"), t(data.message || "Get link auth error"), "error");

            window.location = data.data;
        });
    };

    return (
        <>
            <span onClick={() => setIsOpen(true)} className="btn btn-primary" role="button">
                Add Shop
            </span>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    setIsOpen(false);
                }}
                isOpen={isOpen}
            >
                {/* <Loading isLoading={isLoading} /> */}
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Add Shop")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <select
                        className="form-select"
                        data-control="select2"
                        data-hide-search="true"
                        data-placeholder="Select a Region Target"
                        name="target"
                        onChange={(e) => {
                            setRegion(e.target.value);
                        }}
                    >
                        <option value="">{t("-- Select a region target --")}</option>
                        <option value="US">United States</option>
                        <option value="VN">Viet Nam</option>
                        <option value="OTHER">Other</option>
                    </select>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                        onClick={handleCreateApp}
                    >
                        {t("Next")}
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => ({
    app: state.app,
});
const mapDispatchToProps = {
    getAppRandomByRegion,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddShop);
