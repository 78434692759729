const NeedAction = ({ data }) => {
    return (
        <div className="card card-flush h-xl-100">
            <div
                className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-150px"
                style={{ backgroundImage: `url("${require("components/assets/media/custom/bg.jpg")}")` }}
            >
                <h3 className="card-title align-items-start flex-column text-white pt-5">
                    <span className="fw-bold fs-2x mb-3">Need Actions</span>
                </h3>
            </div>
            <div className="card-body mt-n20">
                <div className="mt-n20 position-relative">
                    <div className="row g-3 g-lg-6">
                        <div className="col-4">
                            <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5 border-bottom border-danger">
                                <div className="symbol symbol-30px me-5 mb-8">
                                    <span className="symbol-label">
                                        <i className="ki-outline ki-timer fs-1 text-primary" />
                                    </span>
                                </div>
                                <div className="m-0">
                                    <span className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">
                                        {data?.ship24less || 0}
                                    </span>
                                    <span className="text-gray-500 fw-semibold fs-6">Ship in 24hr or less</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5  border-bottom border-danger">
                                <div className="symbol symbol-30px me-5 mb-8">
                                    <span className="symbol-label">
                                        <i className="ki-outline ki-bank fs-1 text-primary" />
                                    </span>
                                </div>
                                <div className="m-0">
                                    <span className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">
                                        {data?.orderPendingCount || 0}
                                    </span>
                                    <span className="text-gray-500 fw-semibold fs-6">Pending Order</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5  border-bottom border-danger">
                                <div className="symbol symbol-30px me-5 mb-8">
                                    <span className="symbol-label">
                                        <i className="ki-outline ki-award fs-1 text-primary" />
                                    </span>
                                </div>
                                <div className="m-0">
                                    <span className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">
                                        {data?.overdueTotal || 0}
                                    </span>
                                    <span className="text-gray-500 fw-semibold fs-6">Overdue</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NeedAction;
