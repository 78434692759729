import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { ListDateType } from "helper/constant";
import { getStatements, syncStatement, exportStatements } from "actions/statementActions";
import { getStaffs } from "actions/teamActions";
import { ReactTable } from "components/Table";
import { formatterCreateAt, convertTZ } from "components/Function";
import SelectBox from "components/elements/selectBox";
import Loading from "components/Loading";
import { CSVLink } from "react-csv";


const Statements = ({ getStatements, syncStatement, statement, formatterCreateAt, getStaffs, exportStatements }) => {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [dateType, setDateType] = useState("");
    const [filter, setFilter] = useState({});
    const [staffs, setStaffs] = useState([]);
    const [shops, setShops] = useState([]);
    const btnRef = useRef(null);
    const [initiateDownload, setInitiateDownload] = useState(false);
    const [export_statements_data, setExportStatementsData] = useState([]);

    const { t } = useTranslation();
    const { list, totalsize, isLoading, statement_for_export, list_shop } = statement;

    const listshop = list_shop?.map((v, i) => {
        return { "label": `${v?.name} (${v?.note}) - ${v?.region}`, "value": v?._id };
    })

    const listStatus = [
        { "label": "PAID", "value": "PAID" },
        { "label": "FAILED", "value": "FAILED" },
        { "label": "PROCESSING", "value": "PROCESSING" },
    ]

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalsize || 50,
        },
    ];

    useEffect(() => {
        if (Object.keys(filter).length) {
            getStatements({ page, sizeperpage: sizePerPage, search: searchText, filter });
        }
    }, [getStatements, page, sizePerPage, searchText, filter]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    useEffect(() => {
        getStaffs({ sizeperpage: 100 }).then((data) => {
            setStaffs(data?.data?.map((staff) => ({ value: staff?.user?._id, label: staff.user.email })) || []);
        });
    }, [getStaffs]);

    useEffect(() => {
        setPage(1);
        setSizePerPage(50);

        if (dateType === "") {
            setFilter((prev) => ({
                ...prev,
                dateStart: "",
                dateEnd: "",
            }));
        }
        if (dateType === "custom") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "today") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "yesterday") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate());
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - 7 - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_month") {
            let date = new Date();
            date = convertTZ(date);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_month") {
            let date = new Date();
            date = convertTZ(date);
            date.setMonth(date.getMonth() - 1);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_year") {
            let date = new Date();
            date = convertTZ(date);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_year") {
            let date = new Date();
            date = convertTZ(date);
            date.setFullYear(date.getFullYear() - 1);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        // eslint-disable-next-line
    }, [dateType]);

    const onChangeShop = (e) => {
        var shops_tmp = e.map((v, i) => {
            return v.value
        });
        setFilter((prev) => ({
            ...prev,
            shops: shops_tmp || [],
        }));
        setShops(shops_tmp);
    }

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "shops",
            text: t("Shop"),
            sort: true,
            formatter: (cell, row) => `${cell?.name} (${cell?.note}) - ${cell?.region}`,
        },
        {
            dataField: 'shopId',
            text: t("Shop ID"),
            sort: true,
            headerClasses: 'd-none',
            classes: 'd-none',
            formatter: (cell, row) => `${cell?.name} (${cell?.region})`,
        },
        {
            dataField: "apiStatementId",
            text: t("Statement ID"),
            sort: true,
        },
        {
            dataField: "adjustment_amount",
            text: t("Adjustment Amount"),
            sort: true,
        },
        {
            dataField: "currency",
            text: t("Currency"),
            sort: true,
        },
        {
            dataField: "settlement_amount",
            text: t("Settlement Amount"),
            sort: true,
        },
        {
            dataField: "shipping_cost_amount",
            text: t("Shipping Cost Amount"),
            sort: true,
        },
        {
            dataField: "fee_amount",
            text: t("Fee Amount"),
            sort: true,
        },
        {
            dataField: "net_sales_amount",
            text: t("Net Sales Amount"),
            sort: true,
        },
        {
            dataField: "revenue_amount",
            text: t("Revenue Amount"),
            sort: true,
        },
        {
            dataField: "payment_status",
            text: t("Payment Status"),
            sort: true,
        },
        {
            dataField: "payment_id",
            text: t("Payment ID"),
            sort: true,
        },
        {
            dataField: "statement_time",
            text: t("Statement_time"),
            sort: true,
            formatter: (cell, row) => formatterCreateAt(cell),
        },
        {
            dataField: "createdBy",
            text: t("Member"),
            sort: true,
            formatter: (cell, row) => staffs?.find((v, i) => v?.value === cell)?.label,
        }
        // , {
        //     dataField: '-',
        //     text: t("Actions"),
        //     formatter: (cell, row, rowIndex, formatExtraData) =>
        //         <Fragment>
        //             <button onClick={() => { }} className="btn btn-icon btn-bg-light btn-active-color-success btn-sm" data-tooltip-id="my-tooltip" data-tooltip-content={t("Approve Return")}>
        //                 <i className="ki-outline ki-check-square fs-2"></i>
        //             </button>
        //             <button onClick={() => { }} className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm" data-tooltip-id="my-tooltip" data-tooltip-content={t("Reject Return")}>
        //                 <i className="ki-outline ki-cross-circle fs-2"></i>
        //             </button>
        //         </Fragment>

        // }
    ];

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setSearchText(searchText);
        } else {
            setPage(page || 1);
            setSizePerPage(sizePerPage);
        }
    };
    const handleEventDate = (start, end) => {
        setPage(1);
        setSizePerPage(50);

        let date_start = new Date(start);
        date_start.setHours(0, 0, 0, 0);

        let date_end = new Date(end);
        date_end.setHours(23, 59, 59, 999);
        setFilter((prev) => ({
            ...prev,
            dateStart: date_start.getTime(),
            dateEnd: date_end.getTime(),
        }));
    };

    const onClicSyncStatement = () => {
        syncStatement();
    };

    const onClickExportStatements = () => {
        exportStatements({ page, sizeperpage: sizePerPage, search: searchText, filter });
    }

    useEffect(() => {
        if (statement_for_export.length > 0) {
            var export_statements_data = [];
            for (let j = 0; j < statement_for_export.length; j++) {
                var export_statements_data_tmp = {
                    shop: `${statement_for_export[j]?.shops?.name} (${statement_for_export[j]?.shops?.note}) - ${statement_for_export[j]?.shops?.region}`,
                    statement_id: statement_for_export[j].apiStatementId,
                    adjustment_amount: statement_for_export[j].adjustment_amount,
                    currency: statement_for_export[j].currency,
                    settlement_amount: statement_for_export[j].settlement_amount,
                    shipping_cost_amount: statement_for_export[j].shipping_cost_amount,
                    fee_amount: statement_for_export[j].fee_amount,
                    net_sales_amount: statement_for_export[j].net_sales_amount,
                    revenue_amount: statement_for_export[j].revenue_amount,
                    payment_status: statement_for_export[j].payment_status,
                    payment_id: statement_for_export[j].payment_id,
                    statement_time: formatterCreateAt(statement_for_export[j].statement_time),
                    member: staffs?.find((v, i) => v?.value === statement_for_export[j]?.createdBy)?.label,
                }
                export_statements_data.push(export_statements_data_tmp)
            }
            setExportStatementsData(export_statements_data);
            setInitiateDownload(true);
        }
    }, [statement_for_export, formatterCreateAt, staffs]);
    useEffect(() => {
        setInitiateDownload(false);
        if (initiateDownload) {
            btnRef.current?.click();
        }
    }, [initiateDownload]);
    const groupButton = () => {
        return (
            <>
                <div className="mb-5 d-flex flex-md-row flex-column justify-content-between w-100">
                    <div className="d-flex gap-2 mb-2">
                        <button onClick={() => { onClicSyncStatement() }} type="button" className="btn btn-primary btn-sm me-2">
                            <i className="ki-outline ki-arrow-circle-right fs-2"></i> {t("Sync Statement")}
                        </button>
                        <SelectBox
                            options={staffs}
                            name="filter_staff"
                            value={filter.staff || ""}
                            onChange={(data) => {
                                setFilter((prev) => ({
                                    ...prev,
                                    staff: data.value || "",
                                }));
                                setPage(1);
                            }}
                        />
                        <SelectBox
                            options={listStatus}
                            name="filter_status"
                            value={filter.status || ""}
                            onChange={(data) => {
                                setFilter((prev) => ({
                                    ...prev,
                                    status: data.value || "",
                                }));
                                setPage(1);
                            }}
                        />
                        <Select
                            isClearable
                            isMulti
                            name="shop"
                            options={listshop}
                            className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-0 al-filter-table"
                            classNamePrefix="select"
                            value={listshop.filter(({ value }) => shops.includes(value))}
                            onChange={(e) => { onChangeShop(e) }}
                            placeholder={t("Select a shop ... ")}
                        />
                        <button onClick={onClickExportStatements} className="btn btn-sm btn-info mr-1"><i className="ki-outline ki-exit-down"></i>Export</button>
                        <CSVLink filename={"statement_" + formatterCreateAt((new Date()).getTime()) + ".csv"} data={export_statements_data}>
                            <span ref={btnRef} />
                        </CSVLink>
                    </div>
                    <div className="d-flex gap-4">
                        <Select
                            name="dateType"
                            options={ListDateType}
                            className="al-select"
                            classNamePrefix="select"
                            value={ListDateType.filter(({ value }) => value === dateType)}
                            onChange={(e) => {
                                setDateType(e?.value || "");
                            }}
                            placeholder="Select a date ... "
                        />
                        {dateType === "custom" && filter?.dateStart && filter?.dateEnd ? (
                            <DateRangePicker
                                onCallback={handleEventDate}
                                initialSettings={{
                                    startDate: formatterCreateAt(filter.dateStart, "dmy"),
                                    endDate: formatterCreateAt(filter.dateEnd, "dmy"),
                                    showDropdowns: true,
                                    locale: { format: "DD/MM/YYYY" },
                                }}
                            >
                                <input type="text" className="form-control al-form-control-daterangepicker mr-1" />
                            </DateRangePicker>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="card min-w-full">
            <Loading isLoading={isLoading} />
            <div className="card-body">
                <div className="al-variants-table">
                    <ReactTable
                        columns={columns}
                        data={list || []}
                        groupButton={groupButton}
                        handleTableChange={handleTableChange}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={totalSize}
                        alClassName="table-layout-inherit"
                        sizePerPageList={sizePerPageList}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ statement }) => ({ statement });
const mapDispatchToProps = {
    getStatements,
    syncStatement,
    formatterCreateAt,
    getStaffs,
    exportStatements,
};

export default connect(mapStateToProps, mapDispatchToProps)(Statements);
