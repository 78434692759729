import { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
    getProductTikToks,
    getCheckProductTikTok,
    deleteProductTikTok,
    editProductTikTok,
} from "actions/productTikTokActions";
import { getStaffs } from "actions/teamActions";
import { ReactTable } from "components/Table";
import { ListDateType } from "helper/constant";
import {
    formatterCreateAt,
    convertTZ,
    subStringText,
    FormatterStatus,
    sleep,
} from "components/Function";
import SelectBox from "components/elements/selectBox";
import Loading from "components/Loading";
import EditProductTikTok from "./EditProductTikTok";
import _ from "lodash";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";

const ProductList = ({
    getProductTikToks,
    getCheckProductTikTok,
    deleteProductTikTok,
    editProductTikTok,
    producttiktok,
    formatterCreateAt,
    getStaffs,
    subStringText,
    FormatterStatus,
}) => {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [dateType, setDateType] = useState("");
    const [products_selected, setProductsSelected] = useState([]);
    const [filter, setFilter] = useState({});
    const [staffs, setStaffs] = useState([]);
    const [shops, setShops] = useState([]);
    const [loadingSync, setLoadingSync] = useState(true);
    const [hiddenPanel, setHiddenPanel] = useState(true);
    const [dataExport, setDataExport] = useState([]);
    const [initiateDownload, setInitiateDownload] = useState(false);
    const btnRef = useRef(null);

    const { t } = useTranslation();
    const { list, totalsize, isLoading, list_shop } = producttiktok;
    const listshop = list_shop?.map((v, i) => {
        return { label: `${v?.name} (${v?.note}) - ${v?.region}`, value: v?._id };
    });

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "20",
            value: 20,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "All",
            value: totalsize || 50,
        },
    ];

    const onChangeShop = (e) => {
        var shops_tmp = e.map((v, i) => {
            return v.value;
        });
        setFilter((prev) => ({
            ...prev,
            shops: shops_tmp || [],
        }));
        setShops(shops_tmp);
    };

    useEffect(() => {
        if (Object.keys(filter).length) {
            getProductTikToks({ page, sizeperpage: sizePerPage, search: searchText, filter });
        }
    }, [getProductTikToks, page, sizePerPage, searchText, filter]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    useEffect(() => {
        getStaffs({ sizeperpage: 100 }).then((data) => {
            setStaffs(data?.data?.map((staff) => ({ value: staff?.user?._id, label: staff.user.email })) || []);
        });
    }, [getStaffs]);

    useEffect(() => {
        setInitiateDownload(false);
        if (initiateDownload) {
            btnRef.current?.click();
        }
    }, [initiateDownload]);

    useEffect(() => {
        setPage(1);
        setSizePerPage(10);

        if (dateType === "") {
            setFilter((prev) => ({
                ...prev,
                dateStart: "",
                dateEnd: "",
            }));
        }
        if (dateType === "custom") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "today") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "yesterday") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate());
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - 7 - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_month") {
            let date = new Date();
            date = convertTZ(date);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_month") {
            let date = new Date();
            date = convertTZ(date);
            date.setMonth(date.getMonth() - 1);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_year") {
            let date = new Date();
            date = convertTZ(date);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_year") {
            let date = new Date();
            date = convertTZ(date);
            date.setFullYear(date.getFullYear() - 1);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        // eslint-disable-next-line
    }, [dateType]);

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "main_images",
            text: t("Main Images"),
            sort: true,
            headerStyle: { width: "100px" },
            formatter: (cell, row) => (
                <>
                    <div className="symbol symbol-50px">
                        <img src={cell?.[0] || require("components/assets/media/misc/image.png")} alt="" />
                    </div>
                </>
            ),
        },
        {
            dataField: "title",
            text: t("Title"),
            sort: true,
            headerStyle: { width: "650px" },
            formatter: (cell, row) => <Link to={"https://shop.tiktok.com/view/product/" + row.apiProductId} target="_blank">{subStringText(cell, 80)}</Link>,
        },
        {
            dataField: "apiStatus",
            text: t("Status"),
            sort: true,
            formatter: (cell, row) => <FormatterStatus status={cell} />,
        },
        {
            dataField: "optionsVariants",
            text: t("Skus"),
            sort: true,
            formatter: (cell, row) => <Fragment>{cell?.length > 0 ? "VARIANTS" : "PRODUCT"}</Fragment>,
        },
        {
            dataField: "shops",
            text: t("Shop"),
            sort: true,
            formatter: (cell, row) => `${cell?.name} (${cell?.note}) - ${cell?.region}`,
        },
        {
            dataField: "shopId",
            text: t("Shop ID"),
            sort: true,
            headerClasses: "d-none",
            classes: "d-none",
            formatter: (cell, row) => `${cell?.name} (${cell?.region})`,
        },
        {
            dataField: "orders",
            text: t("Order"),
            sort: true,
            formatter: (cell, row) => `${cell.length || 0}`,
        },
        {
            dataField: "created_at",
            text: t("Created At"),
            sort: true,
            formatter: (cell, row) => formatterCreateAt(cell),
        },
        {
            dataField: "createdBy",
            text: t("Created By"),
            sort: true,
            formatter: (cell, row) => staffs?.find((v, i) => v?.value === cell)?.label,
        },
        {
            dataField: "-",
            text: t("Actions"),
            formatter: (cell, row, rowIndex, formatExtraData) => (
                <>
                    <Link
                        role="button"
                        to="#"
                        className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={t("Edit")}
                    >
                        <i className="ki-outline ki-notepad-edit fs-2"></i>
                    </Link>
                    {/* <button
                        className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={t("Delete")}
                        onClick={() =>
                            getCheckProductTikTok([row._id]).then((data) => {
                                if (data) getProductTikToks({ page, sizeperpage: sizePerPage, search: searchText, filter });
                            })
                        }
                    >
                        <i className="ki-outline ki-trash fs-2"></i>
                    </button> */}
                </>
            ),
        },
    ];

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(10);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };

    const handleFilter = (e) => {
        setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleEventDate = (start, end) => {
        setPage(1);
        setSizePerPage(10);

        let date_start = new Date(start);
        date_start.setHours(0, 0, 0, 0);

        let date_end = new Date(end);
        date_end.setHours(23, 59, 59, 999);
        setFilter((prev) => ({
            ...prev,
            dateStart: date_start.getTime(),
            dateEnd: date_end.getTime(),
        }));
    };
    const onClickDeleteProduct = () => {
        setLoadingSync(true);
        getCheckProductTikTok(products_selected).then((data) => {
            const groupShop = _.groupBy(data?.filterProduct, "shopId");
            const mapDelete = Object.entries(groupShop);
            Promise.all(
                mapDelete.map(async (data_shop, i) => {
                    await sleep(i * 500);
                    return new Promise(async (resolve2, reject2) => {
                        const key_shop = data_shop?.[0];
                        const key_data = data_shop?.[1];
                        await deleteProductTikTok({ key_shop, key_data }).then(async (a) => {
                            resolve2("ok");
                        });
                    });
                })
            ).then(async (dat) => {
                if (data) getProductTikToks({ page, sizeperpage: sizePerPage, search: searchText, filter });
                Swal.fire(t("Success"), t("Deleted success"), "success");
                setLoadingSync(false);
            });
        });
    };

    const onClickEditProduct = (type) => {
        setLoadingSync(true);
        getCheckProductTikTok(products_selected).then((data) => {
            const groupShop = _.groupBy(data?.filterProduct, "shopId");
            const mapDelete = Object.entries(groupShop);
            Promise.all(
                mapDelete.map(async (data_shop, i) => {
                    return new Promise(async (resolve2, reject2) => {
                        const shopId = data_shop?.[0];
                        const key_data = data_shop?.[1];
                        Promise.all(
                            key_data.map(async (productId, i) => {
                                await sleep(i * 500);
                                return new Promise(async (resolve, reject) => {
                                    await editProductTikTok({
                                        productId,
                                        shopId,
                                        type
                                    }).then(async (a) => {
                                        resolve("ok");
                                    });
                                });
                            })
                        ).then(async (dat) => {
                            resolve2("ok");
                        });
                    });
                })
            ).then(async (dat) => {
                if (data) getProductTikToks({ page, sizeperpage: sizePerPage, search: searchText, filter });
                Swal.fire(t("Success"), t("Edited success"), "success");
                setLoadingSync(false);
            });
        });
    };

    const onClickExportData = async () => {
        setLoadingSync(true);
        let save_data_export = [];
        save_data_export = list?.filter((v, i) => products_selected?.includes(v._id))?.map((v, i) => {
            return { title: v.title, productId: v.apiProductId, image1: v?.main_images?.[0] || "", image2: v?.main_images?.[1] || "", image3: v?.main_images?.[2] || "", image4: v?.main_images?.[3] || "", image5: v?.main_images?.[4] || "", image6: v?.main_images?.[5] || "", image7: v?.main_images?.[6] || "", image8: v?.main_images?.[7] || "", image9: v?.main_images?.[8] || "" }
        })
        setDataExport(save_data_export);
        setInitiateDownload(true);
        await sleep(2500)
        setLoadingSync(false)
    }

    const removeSelect = (arr, value) => {
        var i = 0;
        while (i < arr.length) {
            if (arr[i] === value) {
                arr.splice(i, 1);
            } else {
                ++i;
            }
        }
        return arr;
    };

    const onSelect = (row, isSelect) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp.push(row._id);
        } else {
            products_selected_tmp = removeSelect(products_selected_tmp, row._id);
        }
        var new_arr = JSON.stringify(products_selected_tmp);
        setProductsSelected(JSON.parse(new_arr));
        if (products_selected_tmp.length !== 0) {
            setLoadingSync(false);
        }
        if (products_selected_tmp.length === 0) {
            setLoadingSync(true);
        }
    };

    const onSelectAll = (isSelect, row) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp = row.map((v, i) => v._id);
        } else {
            products_selected_tmp = [];
        }
        setProductsSelected(products_selected_tmp);
        if (products_selected_tmp.length !== 0) {
            setLoadingSync(false);
        }
        if (products_selected_tmp.length === 0) {
            setLoadingSync(true);
        }
    };

    const selectRow = {
        mode: "checkbox",
        clickToSelect: false,
        selected: products_selected,
        onSelect: onSelect,
        onSelectAll: onSelectAll,
    };

    const groupButton = () => {
        return (
            <>
                <div className="mb-5 d-flex flex-md-row flex-column gap-2">
                    <button
                        onClick={() => {
                            onClickDeleteProduct();
                        }}
                        disabled={loadingSync}
                        type="button"
                        className="btn btn-danger btn-sm"
                    >
                        <i className="ki-outline ki-cross-circle fs-2"></i> {t("Delete Product Select")}
                    </button>
                    <button
                        disabled={loadingSync}
                        onClick={() => {
                            onClickEditProduct('CoTik.App');
                        }}
                        type="button"
                        className="btn btn-info btn-sm"
                    >
                        <i className="ki-outline ki-shield-tick fs-2"></i> {t("As Draft To Listing")}
                    </button>
                    <button
                        disabled={loadingSync}
                        onClick={() => {
                            setHiddenPanel(!hiddenPanel)
                        }}
                        type="button"
                        className="btn btn-primary btn-sm"
                    >
                        <i className="ki-outline ki-arrows-circle fs-2"></i> {t("Edit All")}
                    </button>
                    <button onClick={onClickExportData} className="btn btn-sm btn-success mr-1"><i className="ki-outline ki-exit-down"></i>Export</button>
                    <CSVLink filename={"data_tiktok_" + dataExport.length + "_products.csv"} data={dataExport}>
                        <span ref={btnRef} />
                    </CSVLink>
                </div>
                <div className="mb-5 d-flex flex-md-row flex-column justify-content-start w-100 gap-3">
                    <Select
                        isClearable
                        isMulti
                        name="shop"
                        options={listshop}
                        className="cotik-select-virtual"
                        classNamePrefix="select"
                        value={listshop.filter(({ value }) => shops.includes(value))}
                        onChange={(e) => {
                            onChangeShop(e);
                        }}
                        placeholder={t("Select a shop ... ")}
                        theme={(theme) => ({
                            ...theme,
                            spacing: {
                                ...theme.spacing,
                                controlHeight: "100%",
                                controlWidth: "200px",
                            },
                        })}
                    />
                    <select
                        className="form-select basic-multi-select al-custom-select-search-variants al-filter-table"
                        data-control="select2"
                        data-hide-search="true"
                        data-placeholder="currency"
                        name="status"
                        onChange={handleFilter}
                    >
                        <option value="">ALL</option>
                        <option value="DRAFT">DRAFT</option>
                        <option value="PENDING">PENDING</option>
                        <option value="FAILED">FAILED</option>
                        <option value="ACTIVATE">ACTIVATE</option>
                        <option value="SELLER_DEACTIVATED">SELLER_DEACTIVATED</option>
                        <option value="PLATFORM_DEACTIVATED">PLATFORM_DEACTIVATED</option>
                        <option value="FREEZE">FREEZE</option>
                        <option value="DELETED">DELETED</option>
                    </select>
                    <SelectBox
                        className="me-2"
                        options={staffs}
                        name="filter_staff"
                        value={filter.staff || ""}
                        onChange={(data) => {
                            setFilter((prev) => ({
                                ...prev,
                                staff: data.value || "",
                            }));
                            setPage(1);
                        }}
                    />
                    <Select
                        name="dateType"
                        options={ListDateType}
                        className="al-select"
                        classNamePrefix="select"
                        value={ListDateType.filter(({ value }) => value === dateType)}
                        onChange={(e) => {
                            setDateType(e?.value || "");
                        }}
                        placeholder="Select a date ... "
                    />
                    {dateType === "custom" && filter?.dateStart && filter?.dateEnd ? (
                        <DateRangePicker
                            onCallback={handleEventDate}
                            initialSettings={{
                                startDate: formatterCreateAt(filter.dateStart, "dmy"),
                                endDate: formatterCreateAt(filter.dateEnd, "dmy"),
                                showDropdowns: true,
                                locale: { format: "DD/MM/YYYY" },
                            }}
                        >
                            <input type="text" className="form-control al-form-control-daterangepicker mr-1" />
                        </DateRangePicker>
                    ) : (
                        ""
                    )}
                </div>
            </>
        );
    };

    return (
        <>
            <div className="card min-w-full">
                <Loading isLoading={isLoading} />
                <div className="card-body">
                    <div className="al-variants-table">
                        <ReactTable
                            columns={columns}
                            data={list || []}
                            groupButton={groupButton}
                            handleTableChange={handleTableChange}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            alClassName="table-layout-inherit"
                            sizePerPageList={sizePerPageList}
                            isSelectRow={true}
                            selectRow={selectRow}
                        />
                    </div>
                </div>
            </div>
            <div className="card min-w-full mt-5" hidden={hiddenPanel}>
                <div className="card-body">
                    <h3>Edit Multiple Product</h3>
                    <EditProductTikTok products_selected={products_selected} />
                </div>
            </div>
        </>

    );
};

const mapStateToProps = ({ producttiktok }) => ({ producttiktok });
const mapDispatchToProps = {
    getProductTikToks,
    getCheckProductTikTok,
    deleteProductTikTok,
    formatterCreateAt,
    getStaffs,
    subStringText,
    FormatterStatus,
    editProductTikTok,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
