import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import cx from "classnames";
import { getProducts } from "actions/productActions";
import { getStaffs } from "actions/teamActions";
import { getCategoriesProduct } from "actions/categoryProductAction";
import { FormatterUsed, formatterCreateAt, convertTZ } from "components/Function";
import { ReactTable } from "components/Table";
import { ListDateType } from "helper/constant";
import Loading from "components/Loading";
import SelectBox from "components/elements/selectBox";

function ProductList(props) {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [toggleCard, setToggleCard] = useState(false);
    const [filter, setFilter] = useState({});
    const [staffs, setStaffs] = useState([]);
    const [dateType, setDateType] = useState("");
    const [categories, setCategories] = useState([]);
    const { t } = useTranslation();
    var { list, totalsize, isLoading } = props.product;
    var { getProducts, products_selected, setProductsSelected, FormatterUsed, formatterCreateAt, getStaffs, getCategoriesProduct } = props;

    const sizePerPageList = [
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "300",
            value: 300,
        },
        {
            text: "500",
            value: 500,
        },
        {
            text: "700",
            value: 700,
        },
        {
            text: "1000",
            value: 1000,
        },
        {
            text: "1500",
            value: 1500,
        },
        {
            text: "2000",
            value: 2000,
        },
        {
            text: "All",
            value: totalsize || 50,
        },
    ];

    useEffect(() => {
        if (Object.keys(filter).length) {
            getProducts({ page, sizeperpage: sizePerPage, search: searchText, filter });
        }
    }, [getProducts, page, sizePerPage, searchText, filter]);

    useEffect(() => {
        getStaffs({ sizeperpage: 100 }).then((data) => {
            setStaffs(data?.data?.map((staff) => ({ value: staff?.user?._id, label: staff.user.email })) || []);
        });
    }, [getStaffs]);

    useEffect(() => {
        getCategoriesProduct({ sizeperpage: 100 })
            .then((data) => {
                setCategories(data?.categoryProducts?.map((cate) => ({ value: cate?._id, label: cate.title })) || []);
            });
    }, [getCategoriesProduct]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    useEffect(() => {
        setPage(1);
        setSizePerPage(50);

        if (dateType === "") {
            setFilter((prev) => ({
                ...prev,
                dateStart: "",
                dateEnd: "",
            }));
        }
        if (dateType === "custom") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "today") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "yesterday") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate());
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - 7 - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_month") {
            let date = new Date();
            date = convertTZ(date);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_month") {
            let date = new Date();
            date = convertTZ(date);
            date.setMonth(date.getMonth() - 1);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_year") {
            let date = new Date();
            date = convertTZ(date);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_year") {
            let date = new Date();
            date = convertTZ(date);
            date.setFullYear(date.getFullYear() - 1);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        // eslint-disable-next-line
    }, [dateType]);

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "main_images",
            text: t("Main Images"),
            sort: true,
            formatter: (cell, row) => (
                <Fragment>
                    <div className="symbol symbol-50px">
                        <img src={cell?.[0]} alt="" />
                    </div>
                </Fragment>
            ),
        },
        {
            dataField: "title",
            text: t("Title"),
            sort: true,
        },
        {
            dataField: "skus",
            text: t("Skus"),
            sort: true,
            formatter: (cell, row) => <Fragment>{cell?.length > 0 ? "VARIANTS" : "PRODUCT"}</Fragment>,
        },
        {
            dataField: "used",
            text: t("Used"),
            sort: true,
            formatter: (cell, row) => <FormatterUsed status={cell} />,
        },
        {
            dataField: "last_used",
            text: t("Last Used"),
            sort: true,
            formatter: (cell, row) => formatterCreateAt(cell),
        },
        {
            dataField: "msg",
            text: t("Message Upload"),
            sort: true,
        },
        {
            dataField: "categories",
            text: t("Category"),
            sort: true,
            formatter: (cell, row) => categories?.find((v, i) => v?.value === cell)?.label || "",
        },
        {
            dataField: "createdBy",
            text: t("Created By"),
            sort: true,
            formatter: (cell, row) => staffs?.find((v, i) => v?.value === cell)?.label || "me",
        },
    ];

    const groupButton = () => {
        return (
            <>
                <div className="mb-5 w-100" style={{ height: "50px" }}></div>
                <div className="mb-5 d-flex justify-content-between">
                    <div className="d-flex gap-4">
                        <select
                            className="form-select"
                            data-control="select2"
                            data-hide-search="true"
                            data-placeholder="currency"
                            name="used"
                            onChange={handleFilter}
                        >
                            <option value="">Filter Used</option>
                            <option value="not_used">Not Used</option>
                            <option value="used">Used</option>
                        </select>
                        <SelectBox
                            options={staffs}
                            name="filter_staff"
                            value={filter.staff || ""}
                            onChange={(data) => {
                                setFilter((prev) => ({
                                    ...prev,
                                    staff: data.value || "",
                                }));
                                setPage(1);
                            }}
                        />
                        <SelectBox
                            options={categories}
                            name="filter_categories"
                            value={filter.categories || ""}
                            onChange={(data) => {
                                setFilter((prev) => ({
                                    ...prev,
                                    categories: data.value || "",
                                }));
                                setPage(1);
                            }}
                        />
                    </div>
                    <div>
                        <div className="d-flex gap-4">
                            <Select
                                name="dateType"
                                options={ListDateType}
                                className="al-select"
                                classNamePrefix="select"
                                value={ListDateType.filter(({ value }) => value === dateType)}
                                onChange={(e) => {
                                    setDateType(e?.value || "");
                                }}
                                placeholder="Select a date ... "
                            />
                            {dateType === "custom" && filter?.dateStart && filter?.dateEnd ? (
                                <DateRangePicker
                                    onCallback={handleEventDate}
                                    initialSettings={{
                                        startDate: formatterCreateAt(filter.dateStart, "dmy"),
                                        endDate: formatterCreateAt(filter.dateEnd, "dmy"),
                                        showDropdowns: true,
                                        locale: { format: "DD/MM/YYYY" },
                                    }}
                                >
                                    <input type="text" className="form-control al-form-control-daterangepicker mr-1" />
                                </DateRangePicker>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };

    // const removeSelect = (arr, value) => {
    //     var i = 0;
    //     while (i < arr.length) {
    //         if (arr[i] === value) {
    //             arr.splice(i, 1);
    //         } else {
    //             ++i;
    //         }
    //     }
    //     return arr;
    // };

    const onSelect = (row, isSelect) => {
        if (isSelect) {
            setProductsSelected((prev) => [...prev, row._id]);
        } else {
            setProductsSelected((prev) => [...prev].filter((d) => d !== row._id));
        }
    };

    const onSelectAll = (checked, row) => {
        let selects = [];
        if (checked) selects = (row || []).map((s) => s._id);
        setProductsSelected(selects);
    };

    const selectRow = {
        mode: "checkbox",
        clickToSelect: false,
        selected: products_selected,
        onSelect: onSelect,
        onSelectAll: onSelectAll,
    };

    const handleFilter = (e) => {
        setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleEventDate = (start, end) => {
        setPage(1);
        setSizePerPage(50);

        let date_start = new Date(start);
        date_start.setHours(0, 0, 0, 0);

        let date_end = new Date(end);
        date_end.setHours(23, 59, 59, 999);
        setFilter((prev) => ({
            ...prev,
            dateStart: date_start.getTime(),
            dateEnd: date_end.getTime(),
        }));
    };

    return (
        <Fragment>
            <Loading isLoading={isLoading} />
            <div className="card mb-5 mb-xl-10">
                <div
                    className={cx("card-header collapsible cursor-pointer rotate", {
                        active: !toggleCard,
                        collapsed: toggleCard,
                    })}
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_docs_card_collapsible"
                    onClick={() => setToggleCard(!toggleCard)}
                >
                    <h3 className="card-title">{t("List Products")}</h3>
                    <div className="card-toolbar rotate-180">
                        <i className="ki-duotone ki-down fs-1"></i>
                    </div>
                </div>
                <div id="kt_docs_card_collapsible" className={cx("collapse", { show: !toggleCard })}>
                    <div className="card-body p-9">
                        <div className="al-variants-table">
                            <ReactTable
                                columns={columns}
                                data={list}
                                groupButton={groupButton}
                                handleTableChange={handleTableChange}
                                page={page}
                                sizePerPage={sizePerPage}
                                totalSize={totalSize}
                                alClassName="table-layout-inherit"
                                isSelectRow={true}
                                selectRow={selectRow}
                                sizePerPageList={sizePerPageList}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

ProductList.propTypes = {
    FormatterUsed: PropTypes.func.isRequired,
    formatterCreateAt: PropTypes.func.isRequired,
    getStaffs: PropTypes.func.isRequired,
    getCategoriesProduct: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    product: state.product,
});
const mapDispatchToProps = {
    getProducts,
    FormatterUsed,
    formatterCreateAt,
    getStaffs,
    getCategoriesProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
