const initialState = {
	isLoading: false,
	list: [],
	totalsize: 0,
	list_shop: [],
};

function productScheduleReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_PRODUCT_SCHEDULE':
			return {
				...state,
				isLoading: action?.payload
			};
		case 'ADMIN_LIST_PRODUCT_SCHEDULE':
			return {
				...state,
				list: action?.payload?.productSchedules || [],
				totalsize: action?.payload?.totalsize || 0,
				list_shop: action?.payload?.list_shop || [],
			};
		default:
		  return state;
    }
}

export default productScheduleReducer;
