const initialState = {
	isLoading: false,
	list: [],
	totalsize: 0,
	list_shop: [],
	productTikToksFreeze: [],
	totalsizeFreeze: 0,
};

function productReducer(state = initialState, action) {
	switch (action.type) {
		case 'LOADING_PRODUCT_TIKTOK':
			return {
				...state,
				isLoading: action?.payload
			};
		case 'ADMIN_LIST_PRODUCT_TIKTOK':
			return {
				...state,
				list: action?.payload?.productTikToks || [],
				totalsize: action?.payload?.totalsize || 0,
				list_shop: action?.payload?.list_shop || [],
			};
		case 'ADMIN_LIST_PRODUCT_FREEZE':
			return {
				...state,
				productTikToksFreeze: action?.payload?.productTikToksFreeze || [],
				totalsizeFreeze: action?.payload?.totalsizeFreeze || 0,
			};
		default:
			return state;
	}
}

export default productReducer;
