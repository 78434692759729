import { useMemo } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

const ChartOrder = ({ data, dates }) => {
    const dataFormat = useMemo(() => {
        return Object.keys(data).reduce((r, key) => {
            r[key] = data[key].reduce((_r, arr) => {
                _r[arr._id] = arr;
                return _r;
            }, {});
            return r;
        }, {});
    }, [data]);

    return (
        <div className="card mb-5 mb-xl-10">
            <div className="card-body">
                <Line
                    data={{
                        labels: dates,
                        datasets: [
                            {
                                data: dates.map((date) => {
                                    return dataFormat?.awaitingCollection?.[date]?.count || 0;
                                }),
                                label: "Awaiting Collection",
                                borderColor: "#f5dd61",
                                fill: false,
                                tension: 0.5,
                            },
                            {
                                data: dates.map((date) => {
                                    return dataFormat?.awaitingShipment?.[date]?.count || 0;
                                }),
                                label: "Awaiting Shipment",
                                borderColor: "#8e5ea2",
                                fill: false,
                                tension: 0.5,
                            },
                            {
                                data: dates.map((date) => {
                                    return dataFormat?.inTransit?.[date]?.count || 0;
                                }),
                                label: "In Transit",
                                borderColor: "#3e95cd",
                                fill: false,
                                tension: 0.5,
                            },
                            {
                                data: dates.map((date) => {
                                    return dataFormat?.cancel?.[date]?.count || 0;
                                }),
                                label: "Cancel",
                                borderColor: "#c45850",
                                fill: false,
                                tension: 0.5,
                            },
                            {
                                data: dates.map((date) => {
                                    return dataFormat?.delivered?.[date]?.count || 0;
                                }),
                                label: "Delivered",
                                borderColor: "#e8c3b9",
                                fill: false,
                                tension: 0.5,
                            },
                            {
                                data: dates.map((date) => {
                                    return dataFormat?.complete?.[date]?.count || 0;
                                }),
                                label: "Complete",
                                borderColor: "#3cba9f",
                                fill: false,
                                tension: 0.5,
                            },
                        ],
                    }}
                    options={{
                        responsive: true,
                        plugins: {
                            title: {
                                display: true,
                                text: "Order Information",
                            },
                            legend: {
                                display: true,
                                position: "bottom",
                            },
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default ChartOrder;
