const initialState = {
	isLoading: false,
	token: localStorage.getItem("token"),
	isAuthenticated: localStorage.getItem("isAuthenticated"),
	user: checkObject(localStorage.getItem("user")),
	listusers: [],
	totalsize: 0
};

function checkObject(obj) {
    try {
        return JSON.parse(obj);
    } catch (e) {
        return {};
    }
}

function userReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_USER':
			return {
				...state,
				isLoading: action.payload
			};
		case 'USER_LOADED':
			localStorage.setItem("user", JSON.stringify(action.payload.user));
			return {
				...state,
				isAuthenticated: true,
				user: action.payload.user
			};
		case 'LOGIN_SUCCESS':
		case 'REGISTER_SUCCESS':
			localStorage.setItem("token", action.payload.token);
			localStorage.setItem("isAuthenticated", true);
			localStorage.setItem("user", JSON.stringify(action.payload.user));
			return {
				...state,
				isLoading: false,
				token: action.payload.token,
				isAuthenticated: true,
				user: action.payload.user,
			};
		case 'AUTH_ERROR':
		case 'LOGIN_FAIL':
		case 'LOGOUT_SUCCESS':
		case 'REGISTER_FAIL':
			localStorage.removeItem("token");
			localStorage.removeItem("isAuthenticated");
			localStorage.removeItem("user");
			return {
				...state,
				isLoading: false,
				token: null,
				user: null,
				isAuthenticated: false,
			};
		case 'ADMIN_LIST_USER':
			return {
				...state,
				listusers: action.payload.listusers,
				totalsize: action.payload.totalsize
			};
		default:
		  return state;
    }
}

export default userReducer;
