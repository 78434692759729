import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";

const getDesigns =
    ({ page = 0, sizeperpage = 0, search = "", filter = {} } = {}) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_DESIGN", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`
            axios
                .get(`/api/design?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}`, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_DESIGN", payload: false });

                    if (res.data.status === 200) {
                        dispatch({
                            type: "ADMIN_LIST_DESIGN",
                            payload: res.data.data,
                        });
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_DESIGN", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        };

const addDesign = (data, closemodal) => (dispatch, getState) => {
    Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to add design?"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel")
    }).then((result) => {
        if (result.value) {
            dispatch({ type: 'LOADING_DESIGN', payload: true });
            axios.post('/api/design/add', data, tokenConfig(getState)).then(res => {
                dispatch({ type: 'LOADING_DESIGN', payload: false });
                if (res.data.status === 200) {
                    closemodal();
                    dispatch(getDesigns());
                    Swal.fire(t("Success"), t(res.data.message), 'success');
                } else {
                    Swal.fire(t("Error"), t(res.data.message), 'error');
                }
            }).catch(err => {
                dispatch({ type: 'LOADING_DESIGN', payload: false });
                Swal.fire(t("Error"), t("An error occurred!"), 'error');
            });
        }
    })
};

const updateDesign =
    (data) =>
        (dispatch, getState) => {
            Swal.fire({
                title: t("Are you sure?"),
                text: t("Are you sure to edit design?"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t("Sure"),
                cancelButtonText: t("Cancel")
            }).then((result) => {
                if (result.value) {
                    dispatch({ type: 'LOADING_DESIGN', payload: true });
                    axios
                        .post("/api/design/update", data, tokenConfig(getState))
                        .then((res) => {
                            dispatch({ type: "LOADING_DESIGN", payload: false });
                            if (res.data.status === 200) {
                                Swal.fire("Success", res.data.message, "success");
                                dispatch(getDesigns({ page: data?.page, sizeperpage: data?.sizeperpage, search: data?.search, filter: data?.filter }));
                            } else {
                                Swal.fire("Error", res.data.message, "error");
                            }
                        })
                        .catch((err) => {
                            dispatch({ type: "LOADING_DESIGN", payload: false });
                            Swal.fire("Error", "An error occurred!", "error");
                        });
                }
            })
        };

const deleteDesign = (data) => (dispatch, getState) => {
    var body = {
        _id: data._id
    };
    Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to delete design?"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel")
    }).then((result) => {
        if (result.value) {
            dispatch({ type: 'LOADING_DESIGN', payload: true });
            axios.post('/api/design/delete', body, tokenConfig(getState)).then(res => {
                dispatch({ type: 'LOADING_DESIGN', payload: false });
                if (res.data.status === 200) {
                    dispatch(getDesigns({ page: data.page, sizeperpage: data.sizeperpage, search: data.search, filter: data.filter }));
                    Swal.fire(t("Success"), t(res.data.message), 'success');
                } else {
                    Swal.fire(t("Error"), t(res.data.message), 'error');
                }
            }).catch(err => {
                dispatch({ type: 'LOADING_DESIGN', payload: false });
                Swal.fire(t("Error"), t("An error occurred!"), 'error');
            });
        }
    })
};

export {
    getDesigns,
    addDesign,
    updateDesign,
    deleteDesign,
}
