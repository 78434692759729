import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { TeamRole } from "helper/constant";

const CreateStaff = ({ onSubmit }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState({ role: TeamRole.Staff });
    const { t } = useTranslation();

    useEffect(() => {
        if (!isOpen) setFormData({ role: TeamRole.Staff });
    }, [isOpen]);

    const handleSubmit = async () => {
        const result = await onSubmit(formData);

        if (result) setIsOpen(false);
    };

    const handleChangeForm = (e) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    return (
        <>
            <span onClick={() => setIsOpen(true)} className="btn btn-primary" role="button">
                Create Staff
            </span>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    setIsOpen(false);
                }}
                isOpen={isOpen}
            >
                {/* <Loading isLoading={isLoading} /> */}
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Create staff")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Username")}</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData?.username || ""}
                            placeholder={t("Username")}
                            name="username"
                            onChange={handleChangeForm}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Email")}</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData?.email || ""}
                            placeholder={t("Email")}
                            name="email"
                            onChange={handleChangeForm}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Password")}</span>
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            value={formData?.password || ""}
                            placeholder={t("Password")}
                            name="password"
                            onChange={handleChangeForm}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Role")}</span>
                        </label>
                        <select
                            className="form-select"
                            data-control="select2"
                            data-hide-search="true"
                            data-placeholder="Select a role"
                            name="role"
                            onChange={handleChangeForm}
                        >
                            {Object.keys(TeamRole).map((r, k) => (
                                <option value={TeamRole[r]} key={k}>
                                    {r}
                                </option>
                            ))}
                        </select>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateStaff);
