const ImageBox = ({ src, onRemove }) => {
    return (
        <div className="al-thumb position-relative">
            <div className="al-thumbInner">
                <button onClick={onRemove} type="button" className="btn-delete-image">
                    <i className="fa fa-trash" />
                </button>
                <img
                    src={src}
                    alt=""
                    className="al-product-img al-cursor-move w-100 h-100"
                    draggable="false"
                    style={{ objectFit: "cover" }}
                />
            </div>
        </div>
    );
};

export default ImageBox;
