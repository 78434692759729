import { FormatterMoney } from "components/Function";

const Total = ({ data }) => {
    return (
        <div className="nav nav-pills d-flex justify-content-between nav-pills-custom mb-6 mt-6 row" role="tablist">
            <div className="nav-item mb-3 me-0 col-md-3" role="presentation">
                <span
                    className="nav-link nav-link-border-solid btn btn-outline btn-flex btn-active-color-primary flex-column flex-stack pt-9 pb-7 page-bg show w-100"
                    role="button"
                    style={{ height: 180 }}
                >
                    <div className="nav-icon">
                        <span className="symbol-label">
                            <i className="ki-outline ki-purchase fs-4qx text-black"></i>
                        </span>
                    </div>
                    <div className="">
                        <span className="text-gray-800 fw-bold d-block">{data?.orderCount || 0}</span>
                        <span className="text-gray-500 fw-semibold fs-7">Total Order</span>
                    </div>
                </span>
            </div>
            <div className="nav-item mb-3 me-0 col-md-3" role="presentation">
                <span
                    className="nav-link nav-link-border-solid btn btn-outline btn-flex btn-active-color-primary flex-column flex-stack pt-9 pb-7 page-bg show w-100"
                    role="button"
                    style={{ height: 180 }}
                >
                    <div className="nav-icon">
                        <span className="symbol-label">
                            <i className="ki-outline ki-abstract-26 fs-4qx text-black"></i>
                        </span>
                    </div>
                    <div className="">
                        <span className="text-gray-800 fw-bold d-block">{data?.totalItems || 0}</span>
                        <span className="text-gray-500 fw-semibold fs-7">Total Items</span>
                    </div>
                </span>
            </div>
            <div className="nav-item mb-3 me-0 col-md-3" role="presentation">
                <span
                    className="nav-link nav-link-border-solid btn btn-outline btn-flex btn-active-color-primary flex-column flex-stack pt-9 pb-7 page-bg show w-100"
                    role="button"
                    style={{ height: 180 }}
                >
                    <div className="nav-icon">
                        <span className="symbol-label">
                            <i className="ki-outline ki-ranking fs-4qx text-black"></i>
                        </span>
                    </div>
                    <div className="">
                        <span className="text-gray-800 fw-bold d-block">
                            <FormatterMoney money={data?.revenue || 0} />
                        </span>
                        <span className="text-gray-500 fw-semibold fs-7">Revenue</span>
                    </div>
                </span>
            </div>
            <div className="nav-item mb-3 me-0 col-md-3" role="presentation">
                <span
                    className="nav-link nav-link-border-solid btn btn-outline btn-flex btn-active-color-primary flex-column flex-stack pt-9 pb-7 page-bg show w-100"
                    role="button"
                    style={{ height: 180 }}
                >
                    <div className="nav-icon">
                        <span className="symbol-label">
                            <i className="ki-outline ki-abstract-24 fs-4qx text-black"></i>
                        </span>
                    </div>
                    <div className="">
                        <span className="text-gray-800 fw-bold d-block">Fullfil Fee</span>
                        <span className="text-gray-500 fw-semibold fs-7">
                            <FormatterMoney money={0} />
                        </span>
                    </div>
                </span>
            </div>
        </div>
    );
};

export default Total;
