const initialState = {
	isLoading: false,
	list: [],
	totalsize: 0,
};

function categoryProductReducer(state = initialState, action) {
	switch (action.type) {
		case 'LOADING_CATEGORY_PRODUCT':
			return {
				...state,
				isLoading: action?.payload
			};
		case 'ADMIN_CATEGORY_PRODUCT':
			return {
				...state,
				list: action?.payload?.categoryProducts || [],
				totalsize: action?.payload?.totalsize || 0
			};
		default:
			return state;
	}
}

export default categoryProductReducer;
