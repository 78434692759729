import { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";

const ModalImageSelect = ({ images = [], isOpen, onClose, onSubmit, onClearImage }) => {
    const [imageSelect, setImageSelect] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        if (!isOpen) setImageSelect("");
    }, [isOpen]);

    const handleSubmit = async () => {
        onSubmit?.(imageSelect);
        onClose?.();
    };

    const handleClear = async () => {
        onClearImage?.();
        onClose?.();
    };

    return (
        <Fragment>
            <Modal centered={true} size="xl" toggle={onClose} isOpen={isOpen}>
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Choose Image")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="container">
                        <div className="row text-center text-lg-start">
                            {images.map((image, key) => (
                                <div className="col-lg-2 col-md-4 col-6" key={key}>
                                    <span
                                        role="button"
                                        onClick={() => setImageSelect(image)}
                                        className="d-block mb-4 h-100"
                                    >
                                        <img
                                            style={{ width: "150px", height: "150px", objectFit: "contain" }}
                                            className={`img-fluid img-thumbnail ${
                                                imageSelect === image ? "border-danger" : ""
                                            }`}
                                            src={image}
                                            alt="list"
                                        />
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-danger"
                        onClick={handleClear}
                    >
                        {t("Clear Image")}
                    </button>
                    <button
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(ModalImageSelect);
