import { Fragment } from "react";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function Footer(props) {

	return (
		<Fragment>
			<div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
				<div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
					<div className="text-gray-900 order-2 order-md-1">
						<span className="text-muted fw-semibold me-1">2024©</span>
						<Link to="/" target="_blank" className="text-gray-800 text-hover-primary">CoTik</Link>
					</div>
					<ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
						<li className="menu-item">
							<Link to="/about" target="_blank" className="menu-link px-2">About</Link>
						</li>
						<li className="menu-item">
							<Link to="/support" target="_blank" className="menu-link px-2">Support</Link>
						</li>
						<li className="menu-item">
							<Link to="/pricing" target="_blank" className="menu-link px-2">Pricing</Link>
						</li>
					</ul>
				</div>
			</div>
		</Fragment>
	);
}

// Footer.propTypes = {
// };
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
