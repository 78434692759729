import { Fragment, useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { logoutUser } from "actions/userActions";
import { FormatterMoney, formatterCreateAt } from "components/Function";

function Header(props) {
    const [toggleMenuUser, setToggleMenuUser] = useState(false);
    const [toggleMenuLang, setToggleMenuLang] = useState(false);
    const { t, i18n } = useTranslation();
    const { name, email, balance, package: user_package, expired_at } = props.user.user;
    var { logoutUser, toggleSidebar, setToggleSidebar, formatterCreateAt } = props;

    const onClickChangeLanguage = async (l) => {
        i18n.changeLanguage(l);
    }

    return (
        <Fragment>
            <div id="kt_header" className="header align-items-stretch">
                <div className="container-fluid d-flex align-items-stretch justify-content-between">
                    <div className="d-flex align-items-center d-lg-none ms-n4 me-1" title="Show aside menu" onClick={() => setToggleSidebar(!toggleSidebar)}>
                        <div className="btn btn-icon btn-active-color-white" id="kt_aside_mobile_toggle">
                            <i className="ki-outline ki-burger-menu fs-1"></i>
                        </div>
                    </div>
                    <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                        <Link to="/admin" className="d-lg-none">
                            <img alt="Logo" src={require("components/assets/media/custom/logo.png")} className="h-40px" />
                        </Link>
                    </div>
                    <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                        <div className="d-flex align-items-stretch" id="kt_header_nav">
                        </div>
                        <div className="topbar d-flex align-items-stretch flex-shrink-0">
                            <div className="d-flex align-items-center">
                                <span className={cx("badge", { "badge-danger": expired_at - Date.now() < 0, "badge-warning": (expired_at - Date.now() >= 0 && expired_at - Date.now() <= 3*24*60*60*1000), "badge-success": expired_at - Date.now() > 3*24*60*60*1000 })}>{t("Package")}: {user_package?.name||""} - {t("Expired at")}: {formatterCreateAt(expired_at)}</span>
                            </div>
                            <div className="d-flex align-items-stretch" id="kt_header_user_menu_toggle">
                                <div className={cx("topbar-item cursor-pointer symbol px-3 px-lg-5 me-n3 me-lg-n5 symbol-30px symbol-md-35px", { show: toggleMenuUser, "menu-dropdown": toggleMenuUser })} data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end" data-kt-menu-flip="bottom" onClick={() => { setToggleMenuLang(false); setToggleMenuUser(!toggleMenuUser); }}>
                                    <img src={require("components/assets/media/avatars/300-1.jpg")} alt="metronic" />
                                </div>
                                <div className={cx("menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px", { show: toggleMenuUser })} data-kt-menu="true" data-popper-placement="bottom-end" style={{ "zIndex": "107", "position": "fixed", "inset": "0px 0px auto auto", "margin": "0px", "transform": "translate(-30px, 65px)" }}>
                                    <div className="menu-item px-3">
                                        <div className="menu-content d-flex align-items-center px-3">
                                            <div className="symbol symbol-50px me-5">
                                                <img alt="Logo" src={require("components/assets/media/avatars/300-1.jpg")} />
                                            </div>
                                            <div className="d-flex flex-column">
                                                <div className="fw-bold d-flex align-items-center fs-5">
                                                    {name}
                                                    <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">{t("Pro")}</span>
                                                </div>
                                                <Link to={`mailto:${email}`} className="fw-semibold text-muted text-hover-primary fs-7">{email}</Link>
                                                <div className="menu-link">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-wallet fs-2"></i>
                                                    </span>
                                                    <span className="menu-title me-2">
                                                        <FormatterMoney money={balance} />
                                                    </span>
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-plus-circle fs-2"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="separator my-2"></div>
                                    <div className="menu-item px-5">
                                        <a href="account/overview.html" className="menu-link px-5">{t("My Profile")}</a>
                                    </div> */}
                                    <div className="separator my-2"></div>
                                    <div className={cx("menu-item px-5", { show: toggleMenuLang, "menu-dropdown": toggleMenuLang })} data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="left-start" data-kt-menu-offset="-15px, 0" onClick={() => setToggleMenuLang(!toggleMenuLang)}>
                                        <Link to="#" className="menu-link px-5">
                                            <span className="menu-title position-relative">
                                                {t("Language")}
                                                <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
                                                    {
                                                        i18n.language === "en" ?
                                                            <Fragment>
                                                                English
                                                                <img className="w-15px h-15px rounded-1 ms-2" src={require("components/assets/media/flags/united-states.svg").default} alt="" />
                                                            </Fragment>
                                                        : i18n.language === "vi" ?
                                                            <Fragment>
                                                                Vietnam
                                                                <img className="w-15px h-15px rounded-1 ms-2" src={require("components/assets/media/flags/vietnam.svg").default} alt="" />
                                                            </Fragment>
                                                        : ""
                                                    }
                                                </span>
                                            </span>
                                        </Link>
                                        <div className={cx("menu-sub menu-sub-dropdown py-4 al-menu-lang", { show: toggleMenuLang })}>
                                            <div className="menu-item px-3" onClick={() => { onClickChangeLanguage("en") }}>
                                                <Link to="#" className={cx("menu-link d-flex px-5", { active: i18n.language === "en" })}>
                                                    <span className="symbol symbol-20px me-4">
                                                        <img className="rounded-1" src={require("components/assets/media/flags/united-states.svg").default} alt="" />
                                                    </span>
                                                    English
                                                </Link>
                                            </div>
                                            <div className="menu-item px-3" onClick={() => { onClickChangeLanguage("vi") }}>
                                                <Link to="#" className={cx("menu-link d-flex px-5", { active: i18n.language === "vi" })}>
                                                    <span className="symbol symbol-20px me-4">
                                                        <img className="rounded-1" src={require("components/assets/media/flags/vietnam.svg").default} alt="" />
                                                    </span>
                                                    Vietnam
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="separator my-2"></div>
                                    <div className="menu-item px-5 my-1">
                                        <Link to="/admin/accounts" className="menu-link px-5">{t("Account Settings")}</Link>
                                    </div>
                                    <div className="menu-item px-5">
                                        <Link to="#" className="menu-link px-5" onClick={() => logoutUser()}>{t("Log out")}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

Header.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    formatterCreateAt: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    logoutUser,
    formatterCreateAt,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
