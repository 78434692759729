import { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { getListApps, deleteApp } from "actions/appActions";
import { formatterCreateAt, FormatterStatus } from "components/Function";
import { ReactTable } from "components/Table";
import { ModalAddApp, ModalEditApp } from "./modal";

function Listapps(props) {
    const [isOpenModalAddApp, setIsOpenModalAddApp] = useState(false);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [appSelect, setAppSelect] = useState(null);
    const { t } = useTranslation();
    var { listapps, totalsize } = props.app;
    var { getListApps, formatterCreateAt, deleteApp, FormatterStatus } = props;

    useEffect(() => {
        getListApps(page, sizePerPage, searchText);
    }, [getListApps, page, sizePerPage, searchText]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    const onClickOpenModalAddApp = () => {
        setIsOpenModalAddApp(!isOpenModalAddApp);
    };

    const onClickDeleteApp = (row = [], formatExtraData) => {
        if (typeof formatExtraData !== "undefined") {
            row = {
                ...row,
                page: formatExtraData.page,
                sizePerPage: formatExtraData.sizePerPage,
                searchText: formatExtraData.searchText
            }
        }
        deleteApp(row);
    };

    const columns = [{
        dataField: '_id',
        text: "",
        sort: true,
        formatter: (cell, row, rowIndex) => rowIndex + 1
    }, {
        dataField: 'name',
        text: t("Name"),
        sort: true,
    }, {
        dataField: 'app_id',
        text: t("App ID"),
        sort: true,
    }, {
        dataField: 'app_key',
        text: t("App Key"),
        sort: true,
    }, {
        dataField: 'target',
        text: t("Target"),
        sort: true,
    }, {
        dataField: 'totalShop',
        text: t("total shop"),
        sort: true,
    }, {
        dataField: 'status',
        text: t("Status"),
        sort: true,
        formatter: (cell, row) => <FormatterStatus status={cell} />,
    }, {
        dataField: 'created_at',
        text: t("Created At"),
        sort: true,
        formatter: (cell, row) => formatterCreateAt(cell)
    }, {
        dataField: 'updated_at',
        text: t("Update At"),
        sort: true,
        formatter: (cell, row) => formatterCreateAt(cell)
    }, {
        dataField: '-',
        text: t("Actions"),
        formatter: (cell, row, rowIndex, formatExtraData) =>
            <Fragment>
                <button onClick={() => { setAppSelect(row) }} className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2" data-tooltip-id="my-tooltip" data-tooltip-content={t("Edit")}>
                    <i className="ki-outline ki-notepad-edit fs-2"></i>
                </button>
                <button onClick={() => { onClickDeleteApp(row, formatExtraData) }} className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm" data-tooltip-id="my-tooltip" data-tooltip-content={t("Delete")}>
                    <i className="ki-outline ki-trash fs-2"></i>
                </button>
            </Fragment>

    }];

    const groupButton = () => {
        return (
            <div className="mb-5">
                <button onClick={() => { onClickOpenModalAddApp() }} type="button" className="btn btn-primary btn-sm mr-1">
                    <i className="ki-outline ki-plus fs-2"></i> {t("Add App")}
                </button>
            </div>
        );
    }

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setSearchText(searchText);
        } else {
            setPage(page||1);
            setSizePerPage(sizePerPage);
        }
    }

    const sizePerPageList = [
        {
            text: '10', value: 10
        },
        {
            text: '50', value: 50
        },
        {
            text: '100', value: 100
        },
        {
            text: '150', value: 150
        },
        {
            text: '200', value: 200
        },
        {
            text: 'All', value: totalsize||50
        }
    ];

    return (
        <Fragment>
            <div className="card mb-5 mb-xl-10">
                <div className="card-body p-9">
                    <div className="al-variants-table">
                        <ReactTable columns={columns} data={listapps} groupButton={groupButton} handleTableChange={handleTableChange} page={page} sizePerPage={sizePerPage} totalSize={totalSize} alClassName="table-layout-inherit" sizePerPageList={sizePerPageList} />
                    </div>
                    {isOpenModalAddApp ? <ModalAddApp openModal={onClickOpenModalAddApp} isOpenModalAddApp={isOpenModalAddApp} /> : ''}
                    <ModalEditApp
                        data={appSelect}
                        onClose={() => setAppSelect(null)}
                        onSubmit={() => {
                            getListApps(page, sizePerPage, searchText);
                        }}
                    />
                </div>
            </div>
        </Fragment>
    );
}

Listapps.propTypes = {
    getListApps: PropTypes.func.isRequired,
    formatterCreateAt: PropTypes.func.isRequired,
    deleteApp: PropTypes.func.isRequired,
    FormatterStatus: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    app: state.app
});
const mapDispatchToProps = {
    getListApps,
    formatterCreateAt,
    deleteApp,
    FormatterStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(Listapps);