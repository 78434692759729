import { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { getStaffs } from "actions/teamActions";

const UpdateMember = ({ isOpen, data, onClose, onSubmit, getStaffs, team }) => {
    const [formData, setFormData] = useState({ ...data });
    const { t } = useTranslation();

    useEffect(() => {
        if (data?._id) {
            setFormData({ ...data, members: data?.memberData?.map((member) => member._id) || [] });
        }
    }, [data]);

    useEffect(() => {
        if (isOpen && !team?.list?.length) getStaffs({ sizeperpage: 100 });
    }, [getStaffs, isOpen, team?.list]);

    const handleChange = (e) => {
        if (e.target.checked) {
            setFormData((prev) => ({ ...prev, members: [e.target.value] }));
        } else {
            setFormData((prev) => ({ ...prev, members: [] }));
        }
    };

    const handleSubmit = async () => {
        let d = formData;
        await onSubmit?.(d);
        onClose?.();
    };

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={onClose} isOpen={!!isOpen}>
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Edit Shop Member")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th />
                                <th>Email</th>
                                <th>Role</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(team?.list || []).map((staff, key) => (
                                <tr key={key}>
                                    <td className="d-flex">
                                        <input
                                            checked={formData?.members?.includes(staff.user._id)}
                                            onChange={handleChange}
                                            type="checkbox"
                                            value={staff.user._id}
                                        />
                                    </td>
                                    <td>{staff.user?.name}</td>
                                    <td>{staff.role}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({ shop: state.shop, team: state.team });

export default connect(mapStateToProps, { getStaffs })(UpdateMember);
