import { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { updateCategoriesProduct } from "actions/categoryProductAction";

const ModalEditCategoriesProduct = ({ data, onClose, onSubmit, updateCategoriesProduct }) => {
    const [formData, setFormData] = useState({ ...data });
    const { t } = useTranslation();
    useEffect(() => {
        setFormData(data);
    }, [data]);

    const handleChange = (e) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async () => {
        let d = formData;
        await updateCategoriesProduct(d);
        onSubmit?.();
        onClose?.();
    };

    return (
        <Fragment>
            <Modal centered={true} size="xl" toggle={onClose} isOpen={!!data}>
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Edit App")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onClose(); }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Title")}</span>
                        </label>
                        <input type="text" className="form-control" value={formData?.title || ""} placeholder={t("Title")} name="title" onChange={handleChange} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={handleSubmit}  >{t("Save")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({ categoryProduct: state.categoryProduct });

export default connect(mapStateToProps, { updateCategoriesProduct })(ModalEditCategoriesProduct);
