import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";

const getStatements =
    ({ page = 0, sizeperpage = 0, search = "", filter = {} } = {}) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_STATEMENT", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`
            axios
                .get(`/api/statements?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}`, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_STATEMENT", payload: false });

                    if (res.data.status === 200) {
                        dispatch({
                            type: "ADMIN_LIST_STATEMENT",
                            payload: res.data.data,
                        });
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_STATEMENT", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        };
const syncStatement = () => (dispatch, getState) => {
    Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to sync statement?"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel")
    }).then(async (result) => {
        if (result.value) {
            dispatch({ type: 'LOADING_STATEMENT', payload: true });
            axios.get('/api/statements/sync', tokenConfig(getState)).then(async res => {
                dispatch({ type: "LOADING_STATEMENT", payload: false });
                if (res.data.status === 200) {
                    Swal.fire("Success", res.data.message, 'success');
                    dispatch(getStatements());
                } else {
                    Swal.fire(t("Error"), t(res.data.message), "error");
                }
            }).catch(err => {
                dispatch({ type: "LOADING_STATEMENT", payload: false });
                Swal.fire(t("Error"), t("An error occurred!"), "error");
            });
        }
    })
};

const exportStatements =
    ({ search = "", filter = {} } = {}) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_STATEMENT", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`
            axios
                .get(`/api/statements/exports?${param_search}${filter}`, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_STATEMENT", payload: false });

                    if (res.data.status === 200) {
                        dispatch({
                            type: "EXPORT_STATEMENT",
                            payload: res.data.data,
                        });
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_STATEMENT", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        };

export {
    getStatements,
    syncStatement,
    exportStatements,
}
