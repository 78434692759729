const initialState = {
	isLoading: false,
	list: [],
	totalsize: 0,
	listproductsbyshops: [],
	totalsize_listproductsbyshops: 0
};

function productReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_PRODUCT':
			return {
				...state,
				isLoading: action?.payload
			};
		case 'ADMIN_LIST_PRODUCT':
			return {
				...state,
				list: action?.payload?.products || [],
				totalsize: action?.payload?.totalsize || 0
			};
		case 'LIST_PRODUCTS_BY_SHOPS':
			return {
				...state,
				listproductsbyshops: action?.payload?.products || [],
				totalsize_listproductsbyshops: action?.payload?.totalsize || 0
			};
		default:
		  return state;
    }
}

export default productReducer;
