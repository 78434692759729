import { Fragment, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import PropTypes from "prop-types";
import { getListPromotions, deletePromotion } from "actions/promotionActions";
import { formatterCreateAt } from "components/Function";
import { ReactTable } from "components/Table";
import { Modaladdpromotion, Modaleditpromotion } from "./modal";

function Listpromotions(props) {
	const [isOpenModalAddPromotion, setIsOpenModalAddPromotion] = useState(false);
	const [isOpenModalEditPromotion, setIsOpenModalEditPromotion] = useState(false);
	const [page, setPage] = useState(1);
	const [sizePerPage, setSizePerPage] = useState(50);
	const [searchText, setSearchText] = useState("");
	const [totalSize, setTotalSize] = useState(0);
    const [toggleCard, setToggleCard] = useState(false);
	const [editpromotion, setEditPromotion] = useState([]);
	const { t } = useTranslation();
    var { listpromotions, totalsize } = props.promotion;
    var { getListPromotions, deletePromotion, setPromotionSelected } = props;

	useEffect(() => {
		getListPromotions(page, sizePerPage, searchText);
	}, [getListPromotions, page, sizePerPage, searchText]);

	useEffect(() => {
		setTotalSize(totalsize);
	}, [totalsize]);

	const onClickOpenModalAddPromotion = () => {
		setIsOpenModalAddPromotion(!isOpenModalAddPromotion);
	};

	const onClickOpenModalEditPromotion = (row = [], formatExtraData) => {
		if (typeof formatExtraData !== "undefined") {
			row = {
				...row,
				page: formatExtraData.page,
				sizePerPage: formatExtraData.sizePerPage,
				searchText: formatExtraData.searchText
			}
		}
		setIsOpenModalEditPromotion(!isOpenModalEditPromotion);
		setEditPromotion(row);
	}
	
	const onClickDeletePromotion = (row = [], formatExtraData) => {
		if (typeof formatExtraData !== "undefined") {
			row = {
				...row,
				page: formatExtraData.page,
				sizePerPage: formatExtraData.sizePerPage,
				searchText: formatExtraData.searchText
			}
		}
		deletePromotion(row);
	};

	const columns = [{
		dataField: '_id',
		text: "",
		sort: true,
		formatter: (cell, row, rowIndex) => rowIndex + 1
	}, {
		dataField: 'title',
		text: t("Title"),
		sort: true,
	}, {
		dataField: 'product_level',
		text: t("Product Level"),
		sort: true,
	}, {
		dataField: 'activity_type',
		text: t("Activity Type"),
		sort: true,
	}, {
		dataField: 'activity_price_amount',
		text: t("Activity Price Amount"),
		sort: true,
		formatter: (cell, row) => cell+"%"
	}, {
		dataField: 'discount_period',
		text: t("Discount Period"),
		sort: true,
		formatter: (cell, row) => cell + " " + t("hours")
	// }, {
	// 	dataField: 'begin_time',
	// 	text: t("Begin Time"),
	// 	sort: true,
	// 	formatter: (cell, row) => formatterCreateAt(cell)
	// }, {
	// 	dataField: 'end_time',
	// 	text: t("End Time"),
	// 	sort: true,
	// 	formatter: (cell, row) => formatterCreateAt(cell)
	}, {
		dataField: '-',
		text: t("Actions"),
		formatter: (cell, row, rowIndex, formatExtraData) =>
			<Fragment>
				<button onClick={() => { onClickOpenModalEditPromotion(row, formatExtraData) }} className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2" data-tooltip-id="my-tooltip" data-tooltip-content={t("Edit")}>
					<i className="ki-outline ki-notepad-edit fs-2"></i>
				</button>
				<button onClick={() => { onClickDeletePromotion(row, formatExtraData) }} className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm" data-tooltip-id="my-tooltip" data-tooltip-content={t("Delete")}>
					<i className="ki-outline ki-trash fs-2"></i>
				</button>
			</Fragment>

	}];

	const groupButton = () => {
		return (
			<div className="mb-5">
				<button onClick={() => { onClickOpenModalAddPromotion() }} type="button" className="btn btn-primary btn-sm mr-1">
					<i className="ki-outline ki-plus fs-2"></i> {t("Add Promotion")}
				</button>
			</div>
		);
	}

	const handleTableChange = (type, { page, sizePerPage, searchText }) => {
		if (type === "search") {
			setPage(1);
			setSizePerPage(50);
			setSearchText(searchText);
		} else {
			setPage(page||1);
			setSizePerPage(sizePerPage);
		}
	}

	const onSelect = (row, isSelect) => {
		setPromotionSelected(row._id);
	};

	const selectRow = {
		mode: 'radio',
		clickToSelect: false,
		onSelect: onSelect
	};

    const sizePerPageList = [
        {
            text: '10', value: 10
        },
        {
            text: '50', value: 50
        },
        {
            text: '100', value: 100
        },
        {
            text: '150', value: 150
        },
        {
            text: '200', value: 200
        },
        {
            text: 'All', value: totalsize||50
        }
    ];

	return (
		<Fragment>
            <div className="card mb-5 mb-xl-10">
				<div className={cx("card-header collapsible cursor-pointer rotate", { active: !toggleCard, collapsed: toggleCard })} data-bs-toggle="collapse" data-bs-target="#kt_docs_card_collapsible" onClick={() => setToggleCard(!toggleCard)}>
					<h3 className="card-title">{t("List Promotions")}</h3>
					<div className="card-toolbar rotate-180">
						<i className="ki-duotone ki-down fs-1"></i>
					</div>
				</div>
				<div id="kt_docs_card_collapsible" className={cx("collapse", { show: !toggleCard })}>
					<div className="card-body p-9">
						<div className="al-variants-table">
							<ReactTable columns={columns} data={listpromotions} groupButton={groupButton} handleTableChange={handleTableChange} page={page} sizePerPage={sizePerPage} totalSize={totalSize} alClassName="table-layout-inherit" isSelectRow={true} selectRow={selectRow} sizePerPageList={sizePerPageList} />
						</div>
						{isOpenModalAddPromotion ? <Modaladdpromotion openModal={onClickOpenModalAddPromotion} isOpenModalAddPromotion={isOpenModalAddPromotion} /> : ''}
						{isOpenModalEditPromotion ? <Modaleditpromotion openModal={onClickOpenModalEditPromotion} isOpenModalEditPromotion={isOpenModalEditPromotion} editpromotion={editpromotion} /> : ''}
					</div>
				</div>
            </div>
		</Fragment>
	);
}

Listpromotions.propTypes = {
    getListPromotions: PropTypes.func.isRequired,
    formatterCreateAt: PropTypes.func.isRequired,
	deletePromotion: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    promotion: state.promotion
});
const mapDispatchToProps = {
    getListPromotions,
    formatterCreateAt,
    deletePromotion
}

export default connect(mapStateToProps, mapDispatchToProps)(Listpromotions);