import { Fragment, useState } from "react";
import { connect } from "react-redux";
// import { useTranslation } from "react-i18next";
import Listpromotions from "./listpromotions/Listpromotions";
import Listshops from "./listshops/Listshops";
import Listproducts from "./listproducts/Listproducts";
import Uppromotionlogs from "./Uppromotionlogs";
import Loading from "components/Loading";

function Main(props) {
	const [promotion_selected, setPromotionSelected] = useState("");
	const [shops_selected, setShopsSelected] = useState([]);
	const [products_selected, setProductsSelected] = useState([]);
    const [check_all_products, setCheckAllProducts] = useState(false);
    const [searchText, setSearchText] = useState("");
    var isLoading = props.promotion.isLoading || props.shop.isLoading || props.product.isLoading;

    return (
        <Fragment>
            <Loading isLoading={isLoading} />
            <div className="row">
                <div className="col-md-12">
                    <Listpromotions setPromotionSelected={setPromotionSelected} />
                </div>
                <div className="col-md-12">
                    <Listshops promotion_selected={promotion_selected} shops_selected={shops_selected} setShopsSelected={setShopsSelected} />
                </div>
                <div className="col-md-12">
                    <Listproducts promotion_selected={promotion_selected} shops_selected={shops_selected} products_selected={products_selected} setProductsSelected={setProductsSelected} searchText={searchText} setSearchText={setSearchText} check_all_products={check_all_products} setCheckAllProducts={setCheckAllProducts} />
                </div>
                <div className="col-md-12">
                    <Uppromotionlogs promotion_selected={promotion_selected} shops_selected={shops_selected} products_selected={products_selected} setProductsSelected={setProductsSelected} searchText={searchText} check_all_products={check_all_products} />
                </div>
            </div>
        </Fragment>
    );
}

Main.propTypes = {
};
const mapStateToProps = state => ({
	promotion: state.promotion,
    shop: state.shop,
    product: state.product
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);