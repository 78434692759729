import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { addCategoriesProduct } from "actions/categoryProductAction";
import Loading from "components/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";

function ModalAddCategoriesProduct(props) {
    const [title, setTitle] = useState("");
    var { isLoading } = props.categoryProduct;
    var { isOpenModalAddCategoriesProduct, openModal, addCategoriesProduct } = props;
    const { t } = useTranslation();

    const onClickSaveAddCategoriesProduct = () => {
        addCategoriesProduct({ title }, openModal);
    };

    const onChange = (e) => {
        if (e.target.name === "title") {
            setTitle(e.target.value)
        }
    }

    const onOpenModal = () => {
        openModal();
    };

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => { onOpenModal() }} isOpen={isOpenModalAddCategoriesProduct}>
                <Loading isLoading={isLoading} />
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Add CategoriesProduct")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onOpenModal() }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Title")}</span>
                        </label>
                        <input type="text" className="form-control" value={title} placeholder={t("Title")} name="title" onChange={(e) => { onChange(e) }} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="reset" id="kt_modal_users_search_reset" data-bs-dismiss="modal" className="btn btn-active-light me-3" onClick={() => { onOpenModal() }} >{t("Cancel")}</button>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveAddCategoriesProduct() }}  >{t("Save")}</button>
                </ModalFooter>
            </Modal>


        </Fragment>
    );
}

ModalAddCategoriesProduct.propTypes = {
    addCategoriesProduct: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    categoryProduct: state.categoryProduct
});
const mapDispatchToProps = {
    addCategoriesProduct
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddCategoriesProduct);