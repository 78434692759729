import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { addPackage } from "actions/packageActions";
import Loading from "components/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";

function ModalAddPackage(props) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [time, setTime] = useState("");
    const [price, setPrice] = useState("");
    const [type, setType] = useState("user");
    const [limit_shop, setLimitShop] = useState("");
    const [limit_member, setLimitMember] = useState("");
    const [limit_staff, setLimitStaff] = useState("");
    const [limit_manage_staff, setLimitManageStaff] = useState("");
    const [manage_product, setManageProduct] = useState(false);
    const [manage_fulfillment, setManageFulfillment] = useState(false);
    const [manage_promotion, setManagePromotion] = useState(false);
    const [manage_refund_return, setManageRefundReturn] = useState(false);
    const [manage_finance, setManageFinance] = useState(false);

    var { isLoading } = props.package;
    var { isOpenModalAddPackage, openModal, addPackage } = props;
    const { t } = useTranslation();

    const onClickSaveAddApp = () => {
        addPackage({ name, description, time, price, type, limit_shop, limit_member, limit_staff, limit_manage_staff, manage_product, manage_fulfillment, manage_promotion, manage_refund_return, manage_finance }, openModal);
    };

    const onChange = (e) => {
        if (e.target.name === "name") {
            setName(e.target.value)
        }
        if (e.target.name === "description") {
            setDescription(e.target.value);
        }
        if (e.target.name === "time") {
            setTime(e.target.value);
        }
        if (e.target.name === "price") {
            setPrice(e.target.value);
        }
        if (e.target.name === "type") {
            setType(e.target.value);
        }
        if (e.target.name === "limit_shop") {
            setLimitShop(e.target.value);
        }
        if (e.target.name === "limit_member") {
            setLimitMember(e.target.value);
        }
        if (e.target.name === "limit_staff") {
            setLimitStaff(e.target.value);
        }
        if (e.target.name === "limit_manage_staff") {
            setLimitManageStaff(e.target.value)
        }
        if (e.target.name === "manage_product") {
            setManageProduct(e.target.checked)
        }
        if (e.target.name === "manage_fulfillment") {
            setManageFulfillment(e.target.checked)
        }
        if (e.target.name === "manage_promotion") {
            setManagePromotion(e.target.checked)
        }
        if (e.target.name === "manage_refund_return") {
            setManageRefundReturn(e.target.checked)
        }
        if (e.target.name === "manage_finance") {
            setManageFinance(e.target.checked)
        }
    }

    const onOpenModal = () => {
        openModal();
    };

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => { onOpenModal() }} isOpen={isOpenModalAddPackage} data-bs-theme="light">
                <Loading isLoading={isLoading} />
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Add Package")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onOpenModal() }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Name")}</span>
                        </label>
                        <input type="text" className="form-control" value={name} placeholder={t("Name")} name="name" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Description")}</span>
                        </label>
                        <input type="text" className="form-control" value={description} placeholder={t("Description")} name="description" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">{t("Time")}</span>
                                </label>
                                <div className="input-group border">
                                    <input type="number" className="form-control" value={time} placeholder="0" name="time" onChange={(e) => { onChange(e) }} />
                                    <span className="btn btn-input">
                                        days
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">{t("Price")}</span>
                                </label>
                                <div className="input-group border">
                                    <input type="number" className="form-control" value={price} placeholder="0" name="price" onChange={(e) => { onChange(e) }} />
                                    <span className="btn btn-input">
                                        $
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">{t("Type")}</span>
                                </label>
                                <select className="form-select" data-control="select2" data-hide-search="true" data-placeholder={t("Type")} value={type} name="type" onChange={(e) => { onChange(e) }} >
                                    <option value="user">User</option>
                                    <option value="manage">Manage</option>
                                </select>
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">{t("Limit Shop")}</span>
                                </label>
                                <input type="text" className="form-control" value={limit_shop} placeholder="0" name="limit_shop" onChange={(e) => { onChange(e) }} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span>{t("Limit Member")}</span>
                                </label>
                                <input type="text" className="form-control" value={limit_member} placeholder="0" name="limit_member" onChange={(e) => { onChange(e) }} />
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">{t("Limit Staff")}</span>
                                </label>
                                <input type="text" className="form-control" value={limit_staff} placeholder="0" name="limit_staff" onChange={(e) => { onChange(e) }} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">{t("Limit Manage Staff")}</span>
                                </label>
                                <input type="text" className="form-control" value={limit_manage_staff} placeholder="0" name="limit_manage_staff" onChange={(e) => { onChange(e) }} />
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">{t("Manage Product")}</span>
                                </label>
                                <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                    <input className="form-check-input w-45px h-30px" type="checkbox" id="manage_product" checked={manage_product} name="manage_product" onChange={(e) => { onChange(e) }} />
                                    <label className="form-check-label" for="manage_product"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">{t("Manage Fulfillment")}</span>
                                </label>
                                <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                    <input className="form-check-input w-45px h-30px" type="checkbox" id="manage_fulfillment" checked={manage_fulfillment} name="manage_fulfillment" onChange={(e) => { onChange(e) }} />
                                    <label className="form-check-label" for="manage_fulfillment"></label>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">{t("Manage Promotion")}</span>
                                </label>
                                <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                    <input className="form-check-input w-45px h-30px" type="checkbox" id="manage_promotion" checked={manage_promotion} name="manage_promotion" onChange={(e) => { onChange(e) }} />
                                    <label className="form-check-label" for="manage_promotion"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">

                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">{t("Manage Refund Return")}</span>
                                </label>
                                <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                    <input className="form-check-input w-45px h-30px" type="checkbox" id="manage_refund_return" checked={manage_refund_return} name="manage_refund_return" onChange={(e) => { onChange(e) }} />
                                    <label className="form-check-label" for="manage_refund_return"></label>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">{t("Manage Finance")}</span>
                                </label>
                                <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                    <input className="form-check-input w-45px h-30px" type="checkbox" id="manage_finance" checked={manage_finance} name="manage_finance" onChange={(e) => { onChange(e) }} />
                                    <label className="form-check-label" for="manage_finance"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="reset" id="kt_modal_users_search_reset" data-bs-dismiss="modal" className="btn btn-active-light me-3" onClick={() => { onOpenModal() }} >{t("Cancel")}</button>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveAddApp() }}  >{t("Save")}</button>
                </ModalFooter>
            </Modal>


        </Fragment>
    );
}

ModalAddPackage.propTypes = {
    addPackage: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    package: state.package
});
const mapDispatchToProps = {
    addPackage
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddPackage);