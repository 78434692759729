import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { saveEditUser } from "actions/userActions";
import Loading from "components/Loading";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

function Modaledituser(props) {
    const [_id] = useState(props.edituser._id);
    const [email] = useState(props.edituser.email);
    const [block, setBlock] = useState(props.edituser.block);
    const [formData, setFormData] = useState({});
    var { isLoading } = props.user;
    var { isOpenModalEditUser, openModal, saveEditUser } = props;
    const { t } = useTranslation();

    useEffect(() => {
        if (props.edituser) {
            setFormData(props.edituser);
        }
    }, [props.edituser]);

    const onClickSaveEditUser = () => {
        saveEditUser(
            {
                _id,
                block,
                limit_shop: +formData?.limit_shop || 1,
                limit_staff: +formData?.limit_staff || 0,
                page: props.edituser.page,
                sizePerPage: props.edituser.sizePerPage,
                searchText: props.edituser.searchText,
            },
            openModal
        );
    };

    const onChange = (e) => {
        if (e.target.name === "block") {
            setBlock(e.target.value);
        }
    };

    const handleChangeFormData = (e) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const onOpenModal = () => {
        openModal();
    };

    return (
        <Fragment>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    onOpenModal();
                }}
                isOpen={isOpenModalEditUser}
            >
                <Loading isLoading={isLoading} />
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Edit User")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onOpenModal();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Email")}</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={email}
                            placeholder={t("Email")}
                            name="email"
                            disabled
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Status")}</span>
                        </label>
                        <select
                            className="form-select"
                            data-control="select2"
                            data-hide-search="true"
                            data-placeholder="Select a Status"
                            value={block}
                            name="block"
                            onChange={(e) => {
                                onChange(e);
                            }}
                        >
                            <option value="0">Active</option>
                            <option value="1">Disable</option>
                        </select>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Limit Shop")}</span>
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            value={formData?.limit_shop || 1}
                            placeholder={t("Limit Shop")}
                            name="limit_shop"
                            onChange={handleChangeFormData}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Limit Staff")}</span>
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            value={formData?.limit_staff || 1}
                            placeholder={t("Limit Staff")}
                            name="limit_staff"
                            onChange={handleChangeFormData}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            onOpenModal();
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                        onClick={() => {
                            onClickSaveEditUser();
                        }}
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modaledituser.propTypes = {
    saveEditUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    user: state.user,
});
const mapDispatchToProps = {
    saveEditUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modaledituser);
