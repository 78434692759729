import axios from "axios";
import Swal from "sweetalert2";
import { t } from "i18next";
import { tokenConfig } from "./websiteActions";

const getStaffs =
    ({ page = 1, sizeperpage = 10, search = "" } = {}) =>
    (dispatch, getState) => {
        dispatch({ type: "LOADING_TEAM", payload: true });
        var param_search = "";
        if (search !== "") {
            param_search = `&search=${search}`;
        }
        return axios
            .get(`/api/team?page=${page}&sizeperpage=${sizeperpage}${param_search}`, tokenConfig(getState))
            .then((res) => {
                dispatch({ type: "LOADING_TEAM", payload: false });

                if (res.data.status === 200) {
                    dispatch({
                        type: "ADMIN_LIST_TEAM",
                        payload: res.data.data,
                    });
                    return res.data.data;
                } else {
                    Swal.fire(t("Error"), t(res.data.message), "error");
                    return [];
                }
            })
            .catch((err) => {
                dispatch({ type: "LOADING_TEAM", payload: false });
                Swal.fire(t("Error"), t("An error occurred!"), "error");
                return [];
            });
    };

const createStaff = (body) => (dispatch, getState) => {
    return axios
        .post("/api/team", body, tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                Swal.fire("Success", t(res.data.message), "success");
                return true;
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            Swal.fire(t("Error"), t("Account registration failed!"), "error");
        });
};


const updateStaff = (body) => (dispatch, getState) => {
    return axios
        .put("/api/team", body, tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                Swal.fire("Success", t(res.data.message), "success");
                return true;
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            Swal.fire(t("Error"), t("Account registration failed!"), "error");
        });
};

const deleteStaff =
    ({ id }) =>
    (dispatch, getState) => {
        return Swal.fire({
            title: t("Are you sure?"),
            text: t("Are you sure to delete staff?"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Sure"),
            cancelButtonText: t("Cancel"),
        }).then(async (result) => {
            if (result.value) {
                return axios
                    .delete(`/api/team/${id}`, tokenConfig(getState))
                    .then((res) => {
                        if (res.data.status === 200) {
                            Swal.fire(t("Success"), t(res.data.message), "success");
                            return true;
                        } else {
                            Swal.fire(t("Error"), t(res.data.message), "error");
                        }
                    })
                    .catch((err) => {
                        dispatch({ type: "LOADING_APP", payload: false });
                        Swal.fire(t("Error"), t("An error occurred!"), "error");
                    });
            }
        });
    };

export { getStaffs, createStaff, deleteStaff, updateStaff };
