import { Fragment, useEffect } from 'react';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import PropTypes from "prop-types";
import { upPromotion } from "actions/promotionActions";
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import store from "store";

function Uppromotionlogs(props) {
	const { t } = useTranslation();
    var { successfully, failed, logs_arr, percent, listpromotions } = props.promotion;
    var { list } = props.shop;
    var { socket_id } = props.website;
    var { upPromotion, promotion_selected, shops_selected, products_selected, searchText, setProductsSelected, check_all_products } = props;

	useEffect(() => {
		store.dispatch({
			type: 'UP_PROMOTION_LOGS',
			payload: { successfully: 0, failed: 0, logs_arr: [] }
		});
		store.dispatch({
			type: 'UP_PROMOTION_PERCENT',
			payload: { percent: 0 }
		});
	}, []);

    const onClickUpPromotion = () => {
		store.dispatch({
			type: 'UP_PROMOTION_LOGS',
			payload: { successfully: 0, failed: 0, logs_arr: [] }
		});
		store.dispatch({
			type: 'UP_PROMOTION_PERCENT',
			payload: { percent: 0 }
		});
		
		var params = { socket_id, promotion_selected, shops_selected, searchText, setProductsSelected };
		if (!check_all_products) {
			params = {
				...params,
				products_selected
			};
		}
        upPromotion(params);
    };

	return (
		<Fragment>
            <div className="card mb-5 mb-xl-10">
				<div className="card-body p-9">
					<div className={cx("align-items-center justify-content-center", { "d-flex": (percent === 0 || percent === 100), "d-none": !(percent === 0 || percent === 100), "mb-5": !(percent === 0 || percent === 100), "mb-xl-10": !(percent === 0 || percent === 100) })}>
						<button type="submit" className="btn btn-primary" onClick={() => { onClickUpPromotion() }} >{t("Start up promotion")}</button>
					</div>
					<Progress
						className={cx({ "d-none": (percent === 0 || percent === 100) })}
						status="default"
						percent={percent}
						theme={{
							error: {
								symbol: percent + '%',
								trailColor: 'pink',
								color: 'red'
							},
							default: {
								symbol: percent + '%',
								trailColor: 'lightblue',
								color: 'blue'
							},
							active: {
								symbol: percent + '%',
								trailColor: 'yellow',
								color: 'orange'
							},
							success: {
								symbol: percent + '%',
								trailColor: 'lime',
								color: 'green'
							}
						}}
					/>
            	</div>
            </div>
			{(!!successfully || !!failed) && (
				<div className="card mb-5 mb-xl-10">
					<div className="card-header p-9">
						<h3 className="card-title">
							<span className="me-1 ">{t("Logs")}</span>
							<span className="me-1">- (Successfully: <span className="text-success">{successfully}</span></span>
							<span className="me-1">/ Failed: <span className="text-danger">{failed}</span>)</span>
						</h3>
					</div>
					<div className="card-body p-9 mh-500px overflow-auto">
						{
							logs_arr.map((v, i) => (
								<Fragment key={i}>
									{v.promotion_id ? `Promotion (${listpromotions.find((v_find) => v_find._id === v.promotion_id)?.title||v.promotion_id})` : ""} - {v.shop_id ? `Shop (${list.find((v_find) => v_find._id === v.shop_id)?.name||v.shop_id})` : ""} - {v.product_name ? `Product (${v.product_name})` : ""}: <span className={cx({ "text-success": v.status === 200, "text-danger": v.status === 400 })}>{v.message}</span><br />
								</Fragment>
							))
						}
					</div>
				</div>
			)}
		</Fragment>
	);
}

Uppromotionlogs.propTypes = {
    upPromotion: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
	promotion: state.promotion,
	shop: state.shop,
	website: state.website
});
const mapDispatchToProps = {
	upPromotion
}

export default connect(mapStateToProps, mapDispatchToProps)(Uppromotionlogs);