import { Fragment } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";

function Main(props) {
    // const { t } = useTranslation();

    return (
        <Fragment>
            <div id="kt_body" data-bs-spy="scroll" data-bs-target="#kt_landing_menu" className="bg-body position-relative">
                <div className="d-flex flex-column flex-root">
                    <div className="mb-0" id="home">
                        <div className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg">
                            <div className="landing-header" data-kt-sticky="true" data-kt-sticky-name="landing-header" data-kt-sticky-offset="{default: '200px', lg: '300px'}">
                                <div className="container">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center flex-equal">
                                            <button className="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none" id="kt_landing_menu_toggle">
                                                <i className="ki-outline ki-abstract-14 fs-2hx"></i>
                                            </button>
                                            <a href="landing.html">
                                                <img alt="Logo" src={require("components/assets/media/custom/logo.png")} className="logo-default h-60px h-lg-60px" />
                                                <img alt="Logo" src={require("components/assets/media/custom/logo.png")} className="logo-sticky h-20px h-lg-25px" />
                                            </a>
                                        </div>
                                        <div className="d-lg-block" id="kt_header_nav_wrapper">
                                            <div className="d-lg-block p-5 p-lg-0" data-kt-drawer="true" data-kt-drawer-name="landing-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="200px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_landing_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}">
                                                <div className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-600 menu-state-title-primary nav nav-flush fs-5 fw-semibold" id="kt_landing_menu">
                                                    <div className="menu-item">
                                                        <a className="menu-link nav-link active py-3 px-4 px-xxl-6" href="#kt_body" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Home</a>
                                                    </div>
                                                    <div className="menu-item">
                                                        <a className="menu-link nav-link py-3 px-4 px-xxl-6" href="#how-it-works" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">How it Works</a>
                                                    </div>
                                                    <div className="menu-item">
                                                        <a className="menu-link nav-link py-3 px-4 px-xxl-6" href="#achievements" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Achievements</a>
                                                    </div>
                                                    <div className="menu-item">
                                                        <a className="menu-link nav-link py-3 px-4 px-xxl-6" href="#pricing" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Pricing</a>
                                                    </div>
                                                    <div className="menu-item">
                                                        <a className="menu-link nav-link py-3 px-4 px-xxl-6" href="#about-us" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">About Us</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-equal text-end ms-1">
                                            <a href="/admin" className="btn btn-success">Sign In</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="landing-curve landing-dark-color mb-10 mb-lg-20">
                            <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z" fill="currentColor"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
