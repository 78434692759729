import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";


const getListPayments = (page = 0, sizeperpage = 0, search = "") => (dispatch, getState) => {
    dispatch({ type: 'LOADING_PAYMENT', payload: true });
    var param_search = "";
    if (search !== "") {
        param_search = `&search=${search}`;
    }
    axios.get(`/api/payment/list?page=${page}&sizeperpage=${sizeperpage}${param_search}`, tokenConfig(getState)).then(res => {
        dispatch({ type: 'LOADING_PAYMENT', payload: false });

        if (res.data.status === 200) {
            dispatch({
                type: 'ADMIN_LIST_PAYMENT',
                payload: res.data.data
            });
        } else {
            Swal.fire(t("Error"), t(res.data.message), 'error');
        }
    }).catch(err => {
        dispatch({ type: 'LOADING_PAYMENT', payload: false });
        Swal.fire(t("Error"), t("An error occurred!"), 'error');
    });
};


const addPayment = (data, closemodal) => (dispatch, getState) => {
    var body = {
        name: data.name,
        clientId: data.clientId,
        secret: data.secret,
        address: data.address,
        merchant_id: data.merchant_id,
    };
    Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to add payment?"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel")
    }).then((result) => {
        if (result.value) {
            dispatch({ type: 'LOADING_PAYMENT', payload: true });
            axios.post('/api/payment/add', body, tokenConfig(getState)).then(res => {
                dispatch({ type: 'LOADING_PAYMENT', payload: false });
                if (res.data.status === 200) {
                    closemodal();
                    dispatch(getListPayments(1, 10));
                    Swal.fire(t("Success"), t(res.data.message), 'success');
                } else {
                    Swal.fire(t("Error"), t(res.data.message), 'error');
                }
            }).catch(err => {
                dispatch({ type: 'LOADING_PAYMENT', payload: false });
                Swal.fire(t("Error"), t("An error occurred!"), 'error');
            });
        }
    })
};

const updatePayment =
    (data) =>
        (dispatch, getState) => {
            const body = {
                ...data,
            };
            Swal.fire({
                title: t("Are you sure?"),
                text: t("Are you sure to edit payment?"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t("Sure"),
                cancelButtonText: t("Cancel")
            }).then((result) => {
                if (result.value) {
                    dispatch({ type: 'LOADING_PAYMENT', payload: true });
                    axios
                        .post("/api/payment/update", body, tokenConfig(getState))
                        .then((res) => {
                            dispatch({ type: "LOADING_PAYMENT", payload: false });
                            if (res.data.status === 200) {
                                dispatch(getListPayments(1, 10));
                                Swal.fire("Success", res.data.message, "success");
                            } else {
                                Swal.fire("Error", res.data.message, "error");
                            }
                        })
                        .catch((err) => {
                            dispatch({ type: "LOADING_PAYMENT", payload: false });
                            Swal.fire("Error", "An error occurred!", "error");
                        });
                }
            })
        };

export {
    getListPayments,
    addPayment,
    updatePayment
};
