import { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { shopAuthorize } from "actions/shopActions";

const Authorize = ({ shopAuthorize }) => {
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const code = query.get("code");
        const appKey = query.get("app_key");
        if (code)
            shopAuthorize(code, appKey).then((res) => {
                if (res.data.status === 200) {
                    window.location.href = "/admin/shops";
                } else {
                    Swal.fire(t("Error"), t(res.data.message), "error");
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search, shopAuthorize]);

    return (
        <>
            <p className="text-center">{t("Loading")}</p>
        </>
    );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { shopAuthorize };

export default connect(mapStateToProps, mapDispatchToProps)(Authorize);
